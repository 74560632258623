<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-box2 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Commande
                <div class="page-title-subheading">Détail du commande.
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isLoading; else elseAllProviders">
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-body text-center">
                    <div class="spinner-container blue-spinner">
                        <mat-progress-spinner mode="indeterminate" [diameter]="40"
                            [strokeWidth]="5"></mat-progress-spinner>
                    </div>
                </div>
                <!-- <div style="height: 70px;" [nbSpinner]="loading" nbSpinnerStatus="warning" nbSpinnerSize="giant">
            </div> -->
            </div>
        </div>
    </div>
</div>
<ng-template #elseAllProviders>

    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="tab-content" id="tab-content">
                    <div class="main-card mb-12 card">
                        <div class="card-body">
                            <div id="commande" #commande>
                                <div class="row">
                                    <div class="col-md-7">
                                        <div class="text space">
                                            <h4><strong>Commande</strong></h4>
                                        </div>
                                    </div>
                                    <div class="col-md-5 text-md-right">
                                        <h4><strong>#{{cmd.ref_facture_cmd}}</strong></h4>
                                        <!-- <qrcode print [width]="150" usesvg="true" [elementType]="'svg'" [qrdata]="cmd._id"
                            [errorCorrectionLevel]="'M'"></qrcode> -->
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-7">
                                        <div class="text space">
                                            <qrcode print [width]="150" usesvg="true" [elementType]="'svg'"
                                                [qrdata]="cmd._id" [errorCorrectionLevel]="'M'">
                                            </qrcode>
                                        </div>
                                    </div>
                                    <div class="col-md-5 text-md-left">
                                        <strong>Fournisseur:</strong><br /><br />
                                        <div class="widget-content p-0">
                                            <div class="widget-content-wrapper">
                                                <div class="widget-content-left mr-3">
                                                    <div class="widget-content-left">
                                                        <!-- <img width="40" class="rounded-circle" [src]="cmd?.society?.logo"
                                                        alt="Logo" style="border: 1px solid #000;"> -->
                                                        <img width="50" height="50" class="rounded-circle"
                                                            src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/profile.jpg'}}"
                                                            alt="">
                                                    </div>
                                                </div>
                                                <div class="widget-content-left flex2">
                                                    <div class="widget-heading">
                                                        {{cmd?.client_cmd?.providerName}}
                                                    </div>
                                                    <div class="widget-subheading opacity-7">
                                                        {{cmd?.client_cmd?.prenom_client + " " +
                                                        cmd?.client_cmd?.nom_client}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div><br />
                                        <b>Email: </b>{{cmd?.client_cmd?.contactUsername}}<br />
                                        <b>Site web: </b>{{cmd?.client_cmd?.siteWeb}}<br />
                                        <b>Tél: </b>{{cmd?.client_cmd?.contactNumTel}}<br />
                                        <b>Adresse: </b>{{cmd?.client_cmd?.shippingAdress?.label_target}}<br /><br />

                                        <strong>Date de commande:</strong><br />
                                        {{cmd.date_creation_cmd | date:'dd/MM/yyyy'}}<br />
                                        <div class="badge badge-{{statusCmds[cmd.etat_cmd]?.color}}">
                                            {{statusCmds[cmd.etat_cmd]?.label}}
                                        </div>
                                    </div>
                                </div>

                                <div class="clearfix"></div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <!-- <div class="main-card mb-3 card"> -->
                                        <div class="section-title" *ngIf="cmd?.boxes?.length > 0">
                                            <!-- Détails de la commande -->
                                            Boxes
                                        </div>

                                        <div class="table-responsive">
                                            <!-- <div class="budget-price justify-content-center"
                                            *ngIf="cmd?.boxes?.length === 0">
                                            <h2 class="text-center" style="color: red;">
                                                Aucun box affecté dans la commande
                                            </h2>
                                        </div> -->
                                            <table
                                                class="align-middle mb-0 table table-borderless table-striped table-hover"
                                                *ngIf="cmd?.boxes?.length > 0">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">#</th>
                                                        <th class="text-left">Nom du produit</th>
                                                        <th class="text-left">Désignation du produit</th>
                                                        <th class="text-center">Volume (m³)</th>
                                                        <th class="text-center">Poids (Kg)</th>
                                                        <!-- <th class="text-center">Prix unitaire</th> -->
                                                        <th class="text-center">Quantite</th>
                                                        <!-- <th class="text-center">Montant</th> -->
                                                        <th class="text-center">Volume total (m³)</th>
                                                        <th class="text-center">Poids total (Kg)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let prd of cmd?.boxes, let index = index">
                                                        <td class="text-center text-muted">#{{index+1}}</td>
                                                        <td class="text">{{prd.nom_prod}}<br>
                                                            <!-- <b *ngIf="cmd.isExchange==true">Echange ({{prd.quantite}}
                                                            article(s))</b> -->
                                                        </td>
                                                        <td class="text">{{prd.des_prod}}</td>
                                                        <td class="text-center">{{prd.volume | number:'0.1'}}</td>
                                                        <td class="text-center">{{prd.weight | number:'0.1'}}</td>
                                                        <!-- <td class="text-center">{{prd.prix_unitaire}} DT
                                                    </td> -->
                                                        <td class="text-center">{{prd.quantite}}</td>
                                                        <td class="text-center">{{prd.quantite * prd?.volume |
                                                            number:'0.1'}}</td>
                                                        <td class="text-center">{{prd.quantite * prd?.weight |
                                                            number:'0.1'}}</td>
                                                        <!-- <td class="text-center">
                                                        {{prd.quantite*prd.prix_unitaire}} DT</td> -->
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <!-- </div> -->
                                    </div>

                                    <div class="col-md-12" *ngIf="cmd?.genPallets?.length > 0">
                                        <div class="container" style="margin-top: 15px;">
                                            <div class="row">
                                                <div class="col-12 col-md-4" *ngFor="let pallet of cmd?.genPallets">
                                                    <div class="pallet" style="margin: 5px;">
                                                        <div class="pallet-info">
                                                            <h3>Pallet {{pallet?.palletNumber}}:</h3>
                                                            <p>Pallet Ratio (W x D x H): {{pallet?.dimensions?.width |
                                                                number:'0.1'}} X
                                                                {{pallet?.dimensions?.depth | number:'0.1'}} X
                                                                {{pallet?.dimensions?.height |
                                                                number:'0.1'}} m</p>
                                                            <p>Total Boxes: {{pallet?.countBoxes}}</p>
                                                            <div>
                                                                <button class="expand-btn">▼</button>
                                                                <p>Total Layer: {{pallet?.layers?.length}}</p>
                                                                <ul class="product-id-list">
                                                                    <li
                                                                        *ngFor="let layer of pallet?.layers; let i = index">
                                                                        Layer {{layer?.layerNumber}}: (H)
                                                                        {{layer?.layerHeight}}
                                                                        <div class="row">
                                                                            <div class="col-12 col-md-6"
                                                                                *ngFor="let item of layer?.boxes">
                                                                                {{ '(' + item?.count + ') - ' +
                                                                                item?.box?.nom_prod }}</div>
                                                                        </div>
                                                                    </li>
                                                                    <!-- <li>Layer 1: (11, 12, 13, 14)</li> -->
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="clearfix"></div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <!-- <div class="main-card mb-3 card"> -->
                                        <div class="section-title" *ngIf="cmd?.pallets?.length > 0">
                                            Palettes
                                        </div>

                                        <div class="table-responsive">
                                            <!-- <div class="budget-price justify-content-center"
                                            *ngIf="cmd?.pallets?.length === 0">
                                            <h5 class="text-center">Aucune palette affecté dans la
                                                commande
                                            </h5>
                                        </div> -->
                                            <table
                                                class="align-middle mb-0 table table-borderless table-striped table-hover"
                                                *ngIf="cmd?.pallets?.length > 0">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">#</th>
                                                        <th class="text-left">Nom de la palette</th>
                                                        <th class="text-center">Volume (m³)</th>
                                                        <th class="text-center">Poids (Kg)</th>
                                                        <!-- <th class="text-center">Prix unitaire</th> -->
                                                        <th class="text-center">Quantite</th>
                                                        <!-- <th class="text-center">Montant</th> -->
                                                        <th class="text-center">Volume total (m³)</th>
                                                        <th class="text-center">Poids total (Kg)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container
                                                        *ngFor="let pallet of cmd?.pallets, let index = index">
                                                        <tr>
                                                            <td class="text-center text-muted">#{{index+1}}</td>
                                                            <td class="text">{{pallet?.name_pallet}}<br>
                                                                <!-- <b *ngIf="cmd.isExchange==true">Echange ({{pallet?.quantite}}
                                                                article(s))</b> -->
                                                            </td>
                                                            <td class="text-center">{{pallet?.volume | number:'0.1'}}
                                                            </td>
                                                            <td class="text-center">{{pallet?.weight | number:'0.1'}}
                                                            </td>
                                                            <!-- <td class="text-center">{{pallet?.prix_unitaire}} DT
                                                        </td> -->
                                                            <td class="text-center">{{pallet?.quantity}}</td>
                                                            <!-- <td class="text-center">
                                                            {{pallet?.quantity*pallet?.prix_unitaire}} DT</td> -->
                                                            <td class="text-center">{{pallet.quantity * pallet?.volume |
                                                                number:'0.1'}}
                                                            </td>
                                                            <td class="text-center">{{pallet.quantity * pallet?.weight |
                                                                number:'0.1'}}
                                                            </td>
                                                        </tr>
                                                        <ng-container *ngIf="pallet?.boxes?.length > 0">
                                                            <tr style="background-color: #ecebeb;"
                                                                *ngFor="let box of pallet?.boxes, let index = index">
                                                                <td colspan="1"></td>
                                                                <td class="text">
                                                                    <div class="widget-heading">
                                                                        {{box?.nom_prod}}
                                                                    </div>
                                                                    <div class="widget-subheading opacity-7">
                                                                        {{box?.des_prod}}
                                                                    </div>
                                                                </td>
                                                                <td class="text-center">{{box?.volume | number:'0.1'}}
                                                                </td>
                                                                <td class="text-center">{{box?.weight | number:'0.1'}}
                                                                </td>
                                                                <!-- <td class="text-center">{{box?.prix_unitaire}} DT
                                                            </td> -->
                                                                <td class="text-center">{{box?.quantite}}</td>
                                                                <td class="text-center">{{box.quantite * box?.volume |
                                                                    number:'0.1'}}</td>
                                                                <td class="text-center">{{box.quantite * box?.weight |
                                                                    number:'0.1'}}</td>
                                                                <!-- <td class="text-center">
                                                                {{box?.quantite*box?.prix_unitaire}} DT</td> -->
                                                            </tr>
                                                        </ng-container>
                                                    </ng-container>
                                                </tbody>
                                            </table>
                                        </div>
                                        <!-- </div> -->
                                    </div>
                                </div>

                                <div class="clearfix"></div>

                                <div class="row mt-4">
                                    <div class="col-lg-8">
                                        <div class="section-title">Mode de livraison</div>
                                        <p class="section-lead">
                                            À domicile
                                            <br />→ {{cmd.destination_cmd}}
                                        </p>
                                    </div>
                                    <div class="col-lg-4 text-right">
                                        <div class="invoice-detail-item">
                                            <!-- <div class="invoice-detail-name">
                                            Prix des article(s):
                                            {{cmd.total_ca_cmd |
                                            number:'0.3'}} TND</div>
                                        <div class="invoice-detail-name">Frais de livraison:
                                            {{cmd.frais_livraison | number:'0.3'}} TND
                                        </div>  -->

                                            <div class="invoice-detail-value">Volume total:
                                                {{cmd?.totalPalletVolume | number: '0.1'}} m³
                                            </div>
                                            <div class="invoice-detail-value">Poids total:
                                                {{cmd?.totalPoids | number: '0.1'}} Kg
                                            </div>
                                            <div class="invoice-detail-value">Montant total:
                                                {{cmd.total_ca_cmd | number:'0.3'}} €
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-block text-center card-footer">
                            <!-- <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                                class="pe-7s-trash btn-icon-wrapper"> </i></button> -->

                            <!-- <button class="btn-wide btn btn-success">Save</button> -->
                            <button class="btn btn-danger float-left" [routerLink]="[ '/all-commandes']"><i
                                    class="metismenu-icon pe-7s-back"></i> Retour</button>

                            <button class="btn btn-warning float-right text-white" [routerLink]="['/facture-cmd']"
                                [queryParams]="{ cmd: cmd._id }"><i class="metismenu-icon pe-7s-print text-white"></i>
                                Imprimer</button>
                            <!-- <button class="btn btn-warning float-right" (click)="generatePDF()"><i
                                class="metismenu-icon pe-7s-print"></i> Imprimer</button> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="cmd?.uploadDocs?.length > 0">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-header-tab card-header">
                    <div class="card-header-title">
                        <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
                        Les fichiers téléversés
                    </div>
                    <div class="btn-actions-pane-right">
                    </div>
                </div>
                <div class="card-body">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-md-3 text-center" *ngFor="let file of cmd?.uploadDocs">
                                <a [href]="env?.uploadedFilesAssetsBaseURL + file" download target="_blank">{{ file }}</a>

                                <!-- <a (click)="downloadFile(file)" download>{{ file }}</a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="cmd?.comments?.length > 0">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-header">Commentaires</div>
                <div class="card-body">
                    <div class="chat-container">
                        <ul class="chat-box chatContainerScroll">
                            <div *ngFor="let comment of cmd.comments, let index = index">

                                <li class="chat-right" *ngIf="comment.type_user == 'client'">
                                    <div class="chat-hour">{{comment.date_ajout | date:'d MMM y HH:mm'}} <span
                                            class="fa fa-check-circle"></span></div>
                                    <div class="chat-text">{{comment.content}}</div>
                                    <div class="chat-avatar">
                                        <!-- <img src="https://www.bootdey.com/img/Content/avatar/avatar4.png"
                                        alt="Retail Admin"> -->
                                        <ngx-avatar width="42" class="rounded-circle" name="{{comment?.name_user}}">
                                        </ngx-avatar>
                                        <div class="chat-name">{{comment.name_user}}</div>
                                    </div>
                                </li>

                                <li class="chat-left" *ngIf="comment.type_user !== 'client'">
                                    <div class="chat-avatar">
                                        <ngx-avatar width="42" class="rounded-circle" name="{{comment?.name_user}} ">
                                        </ngx-avatar>
                                        <div class="chat-name">{{comment.name_user}}</div>
                                    </div>
                                    <div class="chat-text">{{comment.content}}</div>
                                    <div class="chat-hour">{{comment.date_ajout | date:'d MMM y HH:mm'}} <span
                                            class="fa fa-check-circle"></span></div>
                                </li>

                            </div>

                        </ul>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <div class="main-card mb-3 card">
                <div class="card-header">Détail de la commande "<strong>{{cmd.ref_facture_cmd}}</strong>"
                    <div class="btn-actions-pane-right">
                        <div role="group" class="btn-group-sm btn-group">
                            <button type="button" id="PopoverCustomT-1" class="btn btn-danger btn-sm">Details <i
                                    class="metismenu-icon pe-7s-angle-right"></i></button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Description</th>
                                    <th>Date de l'action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of cmd?.actions_cmd, let index = index">
                                    <td>
                                        <div class="badge badge-{{statusCmds[item?.label].color}}">
                                            {{statusCmds[item?.label]?.label}}</div>
                                    </td>
                                    <td class="font-weight-600">Par
                                        {{item?.user}}
                                    </td>
                                    <td>{{item?.action_date | date:'MMM d, y h:mm:ss a'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="row" *ngIf="cmd?.appel_client?.length>0">
        <div class="col-md-8">
            <div class="main-card mb-3 card">
                <div class="card-header">Détail des appels de la commande
                    "<strong>{{cmd.ref_facture_cmd}}</strong>"
                    <div class="btn-actions-pane-right">
                        <div role="group" class="btn-group-sm btn-group">
                            <button type="button" id="PopoverCustomT-1"
                                class="btn btn-danger btn-sm">{{cmd.appel_client.length}}</button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>Indice</th>
                                    <th>Transporteur</th>
                                    <th>Date de l'appel</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let appel of cmd.appel_client, let index = index">
                                    <td>
                                        {{index+1}}
                                    </td>
                                    <td class="font-weight-600">Par
                                        {{appel.transporter_id?.nom_prenom_tracking}}</td>
                                    <td>{{appel.date_accept | date:'MMM d, y h:mm:ss a'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    </div>
</ng-template>