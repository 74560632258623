import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-facture-cmd',
  templateUrl: './facture-cmd.component.html',
  styleUrls: ['./facture-cmd.component.css']
})
export class FactureCmdComponent implements OnInit {
  
  cmd: any = {}
  cmdID: string = '';

  constructor(private route: ActivatedRoute,
    private cmdsService: CommandesService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.cmdID = params.cmd;
      this._fetchCmd();
    });
  }

  _fetchCmd() {
    // console.log(cid);
    this.cmdsService.getOneCMD(this.cmdID).then((data: any) => {
      // console.log(data);
      if (data) {
        this.cmd = data;
      } else {
        alert('Aucune commande valide');
        // location.href = '/commandes';
      }
    });
  }

}
