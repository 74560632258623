<!-- <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
    <agm-marker *ngFor="let m of markers; let i = index" [latitude]="m.latitude" [longitude]="m.longitude">

        <agm-info-window>
            <img width="40" height="40" class="rounded-circle"
                src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + m?.user_transportor?.profile_user}}" alt=""><br>
            <strong>{{m?.user_transportor?.prenom_user + " " + m?.user_transportor?.nom_user}}</strong><br>
            <strong>{{m?.user_transportor?.tel_user}}</strong>
        </agm-info-window>

    </agm-marker>
</agm-map> -->

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Filtre
                <div class="btn-actions-pane-right">
                    <!-- <div role="group" class="btn-group-sm btn-group">
                                        <div class="badge badge-warning">{{statusCmds}}</div>
                                    </div> -->
                </div>
            </div>
            <div class="tab-content">
                <div class="tab-pane tabs-animation fade show active" id="tab-content-0" role="tabpanel">
                    <div class="card-body">
                        <form class="" action="" method="post" onsubmit="return false;"
                            (onsubmit)="filterTransportor();">
                            <div class="form-row">
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <legend for="name" class="">Transporteur</legend>
                                        <input name="name" id="name" type="text" class="form-control" [(ngModel)]="name">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="position-relative form-group">
                                        <legend for="numero" class="">Numéro de téléphone
                                        </legend>
                                        <input name="numero" id="numero" type="number" class="form-control" [(ngModel)]="numero">
                                    </div>
                                </div>
                                <!-- <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="stat" class="">Status</legend>
                                        <select class="form-control selectric" (change)="selectChangeStatus($event)">
                                            <option [value]="stat" *ngFor="let stat of status">
                                                {{stat}}</option>
                                        </select>
                                    </div>
                                </div> -->
                                <div class="col-md-3">
                                    <div class="position-relative form-group">
                                        <legend for="ville" class="">Véhicule</legend>
                                        <select class="form-control selectric select-size"
                                            (change)="selectCamion($event)">
                                            <option value="null">
                                                Tous les véhicules</option>
                                            <option [value]="veh.name" *ngFor="let veh of vehList">
                                                {{veh.name}}
                                                <img src="{{veh.image}}">
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <!-- <div class="col-md-1">
                                    <div class="position-relative form-group">
                                        <legend for="isPlanified" class="">Planifié</legend>
                                        <input id="isPlanified" type="checkbox" class="form-control" name="isPlanified" tabindex="1"
                                            #isPlanified ngModel>
                                    </div>
                                </div> -->
                                <button type="submit" (click)="filterTransportor()"
                                    class="btn btn-primary btn-lg btn-icon icon-righ btn-search" id="search"
                                    tabindex="4">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="my-google-map">
    <google-map [options]="mapOptions" width="100%" [height]="500">


        <!-- <map-marker *ngFor="let m of markers; let i = index" [position]="{ lat: m.latitude, lng: m.longitude }"
            [label]="label" [title]="title" (mapClick)="openInfo(markerElem)">

        </map-marker> -->


        <map-marker #marker="mapMarker" *ngFor="let m of markers; let i = index"
            [position]="{ lat: m.latitude, lng: m.longitude }"
            [options]="{icon: (m.onLine) ? 'http://maps.google.com/mapfiles/ms/icons/green-dot.png' : 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'}"
            (mapClick)="openInfoWindow(marker, i)">

            <map-info-window>
                <img width="40" height="40" class="rounded-circle"
                    src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + m?.user_transportor?.profile_user}}"
                    alt=""><br>
                <strong>{{m?.user_transportor?.prenom_user + " " + m?.user_transportor?.nom_user}}</strong><br>
                <strong>{{m?.user_transportor?.tel_user}}</strong><br>
                <strong>{{m?.info_vehicule?.type_camion}}</strong>
            </map-info-window>
        </map-marker>
    </google-map>
</div>