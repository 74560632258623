import { Component, OnInit } from '@angular/core';
import { TransportorsService } from 'src/app/services/transportors/transportors.service';

@Component({
  selector: 'app-clients-wassali',
  templateUrl: './clients-wassali.component.html',
  styleUrls: ['./clients-wassali.component.css']
})
export class ClientsWassaliComponent implements OnInit {

  allClients: any = [];

  currentPage: number = 0
  totalPages: number = 1
  totalClients: number = 0
  pages: Array<number> = [];
  pagesDisplaied: Array<number> = [];

  society: any = "all";

  constructor(private transServices: TransportorsService) { }

  ngOnInit(): void {
    this.listClientsTrans()
  }

  async filterClients(name, numero, datein, datefin) {
    this.pages = []
    this.pagesDisplaied = []
    this.currentPage = 0
    this.listClientsTrans(name.value, numero.value, datein.value, datefin.value);
  }

  async listClientsTrans(name?, numero?, datein?, datefin?) {
    await this.transServices.clientsWassali(this.currentPage, 
          name, numero, this.society, datein, datefin).then((res: any) => {
      if (res) {
        // console.log("resssssssss : ")
        // console.log(res)
        this.allClients = res.items;
        this.totalPages = res.totalPages
        this.currentPage = res.currentPage
        this.totalClients = res.totalUser
        this.pages = Array.from(Array(res.totalPages)).map((x, i) => i )
        this.splicePages()
      }
    });
  }

  filterDemande(demandes, status) {
    var array = demandes.filter(demande => status.includes(demande.etat_demande));
    return array.length
  }

  splicePages() {
    // console.log("currentPage : ")
    //     console.log(this.currentPage)
    if(this.pages.length > 10) {
      // if(this.currentPage < this.pages.length-10) {
      //   this.pagesDisplaied = this.pages.splice(this.currentPage, 10);
      //   console.log("if 1 pagesDisplaied : ")
      //   console.log(this.pagesDisplaied)
      // } else 
      if(this.currentPage < 5) {
        this.pagesDisplaied = this.pages.splice(0, 10);
        // console.log("if 1 pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      } else if(this.currentPage >= 5 && this.currentPage < this.pages.length-5) {
        this.pagesDisplaied = this.pages.splice(this.currentPage-4, 10);
        // console.log("if 2 pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      } else {
        this.pagesDisplaied = this.pages.splice(this.pages.length-10, 10);
        // console.log("else pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      }
    } else {
      this.pagesDisplaied = this.pages
    }
  }

  nextPage(name, numero, datein, datefin) {
    this.currentPage++
    this.listClientsTrans(name.value, numero.value, datein.value, datefin.value);
  }

  previousPage(name, numero, datein, datefin) {
    this.currentPage--
    this.listClientsTrans(name.value, numero.value, datein.value, datefin.value);
  }

  selectPage(page, name, numero, datein, datefin) {
    this.currentPage = page
    this.listClientsTrans(name.value, numero.value, datein.value, datefin.value);
  }

}
