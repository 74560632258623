<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-add-user icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Utilisateur
                <!-- <div class="page-title-subheading">Ajouter un nouveau utilisateur (TRANSPORTEUR /
                    PREPARATEUR / LOUAGISTE).
                </div> -->
                <div class="page-title-subheading">Modifier un utilisateur.
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <!-- <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                class="btn-shadow mr-3 btn btn-dark">
                <i class="fa fa-star"></i>
            </button> -->
        </div>
    </div>
</div>
<div class="tab-content">
    <div class="main-card mb-12 card">
        <div class="card-body">
            <form class="" action="" method="post">
                <div class="row">
                    <div class="col-12 col-md-4 form-group">
                        <legend for="nom_user" class="">Nom *</legend>
                        <input name="nom_user" type="text" class="form-control" [(ngModel)]="user.nom_user"
                            [class]="(!isSaved && user.nom_user=='') ? 'input-border-danger' : ''">
                    </div>
                    <div class="col-12 col-md-4 form-group">
                        <legend for="prenom_user" class="">Prénom *</legend>
                        <input name="prenom_user" type="text" class="form-control" [(ngModel)]="user.prenom_user"
                            [class]="(!isSaved && user.prenom_user=='') ? 'input-border-danger' : ''">
                    </div>
                    <div class="col-12 col-md-4 form-group">
                        <div class="position-relative form-group">
                            <legend for="tel_user" class="">Numéro téléphone *</legend>
                            <input name="tel_user" type="number" class="form-control" [(ngModel)]="user.tel_user"
                                [class]="(!isSaved && user.tel_user?.toString()=='') ? 'input-border-danger' : ''">

                        </div>
                    </div>


                </div>
                <div class="row">
                    <div class="col-12 col-md-4 form-group">
                        <legend for="email_user" class="">Email *</legend>
                        <input name="email_user" type="text" class="form-control" [(ngModel)]="user.email_user"
                            [class]="(!isSaved && user.email_user=='') ? 'input-border-danger' : ''" ng-required="true"
                            ng-pattern=" ^[a-z]+[a-z0-9._-]+@[a-z]+\.[a-z.]{2,5}$">
                    </div>
                    <div class="col-12 col-md-4 form-group">
                        <legend for="mdp_user" class="">Mot de passe *</legend>
                        <input name="mdp_user" type="password" class="form-control" [(ngModel)]="user.mdp_user"
                            [class]="(!isSaved && (user.mdp_user=='' )) ? 'input-border-danger' : ''">
                    </div>

                    <!-- <div class="col-12 col-md-4 form-group">
                        <legend for="confirmPassword" class="">Confirmer mot de passe *</legend>
                        <input name="confirmPassword" type="password" class="form-control"
                            [(ngModel)]="user.confirmPassword">
                    </div> -->

                </div>
                <div class="form-row">
                    <div class="col-md-4">
                        <div class="position-relative form-group">
                            <legend for="profil" class="">Profil *</legend>
                            <select class="form-control" multiple [(ngModel)]="user.profiles" name="profiles"
                                [class]="(!isSaved && (!user.profiles)) ? 'input-border-danger' : ''">
                                <option [value]="profil" *ngFor="let profil of profiles">
                                    {{profil}}</option>
                            </select>
                        </div>
                    </div>
                    <!-- 
                    <div class="col-4" *ngIf="society!=null">
                        <div class="position-relative form-group">
                            <legend for="society" class="">Société *</legend>
                            <select class="form-control" [(ngModel)]="user.society" name="society"
                                [class]="(!isSaved && (!user.society)) ? 'input-border-danger' : ''">
                                <option [value]="null">Sélectionnez la société</option>
                                <option [value]="soc?._id" *ngFor="let soc of societies">
                                    {{soc?.name}}
                                </option>
                            </select>
                        </div>
                    </div> -->


                </div>

                <div class="position-relative row form-check">
                    <div class="col-sm-10 offset-sm-10">
                        <button type="submit" (click)="editTeam()" class="btn btn-primary">Modifier utilisateur</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>