import { Component, OnInit } from '@angular/core';
import { SacsService } from 'src/app/services/sacs/sacs.service';

@Component({
  selector: 'app-villes',
  templateUrl: './villes.component.html',
  styleUrls: ['./villes.component.css']
})
export class VillesComponent implements OnInit {

  villes = ["ALL",
    'Ariana',
    'Béja',
    'Ben Arous',
    'Bizerte',
    'Gabès',
    'Gafsa',
    'Jendouba',
    'Kairouan',
    'Kasserine',
    'Kébili',
    'Kef',
    'Mahdia',
    'Manouba',
    'Médenine',
    'Monastir',
    'Nabeul',
    'Sfax',
    'Sidi Bouzid',
    'Siliana',
    'Sousse',
    'Tataouine',
    'Tozeur',
    'Tunis',
    'Zaghouan'];
  selectedVille: string = 'ALL';
  sacsListByVille: any = [];

  constructor(private SacsServices: SacsService) { }

  ngOnInit(): void {
    this.listSacs();
  }

  selectChangeVille(event) {
    this.selectedVille = event.target.value
  }
  async listSacs() {
    await this.SacsServices.allSacs("ALL").then((res: any) => {
      if (res) {
        // console.log("resssssssss : ")
        // console.log(res)
        this.sacsListByVille = res;
      }
    });
  }
  searchSacByVille() {
    this.listSacs()
  }

  getCmdsBySacs(sacs) {
    let numberCmds = 0
    sacs.forEach(sac => {
      numberCmds += sac.commandes.length
    });
    return numberCmds
  }

}
