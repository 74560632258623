import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { interval, Subscription } from 'rxjs';
import { TransportorsService } from 'src/app/services/transportors/transportors.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-positions-transportors',
  templateUrl: './positions-transportors.component.html',
  styleUrls: ['./positions-transportors.component.css']
})
export class PositionsTransportorsComponent implements OnInit {

  // subscription: Subscription;

  @ViewChildren(MapInfoWindow) infoWindowsView: QueryList<MapInfoWindow>;

  mapOptions: google.maps.MapOptions = {
    center: { lat: 36.841177, lng: 10.211049 },
    zoom: 10
  }
  // marker = {
  //   position: { lat: 36.841177, lng: 10.211049 },
  // }
  markers: any = []
  vehList: any = []
  vehicule: string = null
  name: string = null
  numero: string = null

  constructor(private transServices: TransportorsService) { }

  ngOnInit(): void {
    this.listVehicule()
    this.filterTransportor()

    // const source = interval(5000);
    // this.subscription = source.subscribe(val => { 
    //   this.filterTransportor()
    // });
  }

  selectCamion(event: any) {
    this.vehicule = event.target.value;
    // console.log("this.vehicule")
    // console.log(this.vehicule)
  }

  async listVehicule() {
    await this.transServices.allTypeCamion().then((res: any) => {
      // console.log("listVehicule : ")
      // console.log(res)
      if (res) {
        this.vehList = res;
      }
    });
  }


  async filterTransportor() {
    await this.transServices.getMarkerTransportors(this.name, this.numero, this.vehicule).then((res: any) => {
      if (res) {
        // console.log("resssssssss : ")
        // console.log(res)
        this.markers = res;
      }
    });
  }

  openInfoWindow(marker: MapMarker, windowIndex: number) {
    /// stores the current index in forEach
    let curIdx = 0;
    this.infoWindowsView.forEach((window: MapInfoWindow) => {
      if (windowIndex === curIdx) {
        window.open(marker);
        curIdx++;
      } else {
        curIdx++;
      }
    });
  }

}
