import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { TeamsService } from 'src/app/services/teams-cofat/teams.service';
import { environment } from 'src/environments/environment';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-edit-provider',
  templateUrl: './edit-provider.component.html',
  styleUrls: ['./edit-provider.component.css']
})
export class EditProviderComponent implements OnInit {

  idSociety: string;

  timer: any[] = [
    { "value": "06:00" },
    { "value": "06:30" },
    { "value": "07:00" },
    { "value": "07:30" },
    { "value": "08:00" },
    { "value": "08:30" },
    { "value": "09:00" },
    { "value": "09:30" },
    { "value": "10:00" },
    { "value": "10:30" },
    { "value": "11:00" },
    { "value": "11:30" },
    { "value": "12:00" },
    { "value": "12:30" },
    { "value": "13:00" },
    { "value": "13:30" },
    { "value": "14:00" },
    { "value": "14:30" },
    { "value": "15:00" },
    { "value": "15:30" },
    { "value": "16:00" },
    { "value": "16:30" },
    { "value": "15:00" },
    { "value": "15:30" },
    { "value": "16:00" },
    { "value": "16:30" },
    { "value": "17:00" },
    { "value": "17:30" },
    { "value": "18:00" },
    { "value": "18:30" },
    { "value": "19:00" },
    { "value": "19:30" },
    { "value": "20:00" },
    { "value": "20:30" },
    { "value": "21:00" },
    { "value": "21:30" },
    { "value": "22:00" },
    { "value": "22:30" },
    { "value": "23:00" },
    { "value": "23:30" },
    { "value": "00:00" },
    { "value": "00:30" },
    { "value": "01:00" },
    { "value": "01:30" },
    { "value": "02:00" },
    { "value": "02:30" },
    { "value": "03:00" },
    { "value": "03:30" },
    { "value": "04:00" },
    { "value": "04:30" },
    { "value": "05:00" },
    { "value": "05:30" },
  ]
  incoterms: any[] = [
    "EXW",
    "FCA usine",
    "FCA plateforme transporteur"
  ]
  posts: any[] = [
    "Responsable Logistique",
    "Financière",
    "Exploitation",
    "Autre"
  ]
  countries: any[] = [
    "Allemagne", "Angleterre", "Autriche", "Belgique",
    "Bulgarie", "Chypre", "Croatie",
    "Danemark", "Espagne", "Estonie",
    "Finlande", "France", "Grèce",
    "Hongrie", "Irlande", "Italie",
    "Lettonie", "Lituanie", "Luxembourg",
    "Malte", "Pays-Bas", "Pologne",
    "Portugal", "République tchèque",
    "Roumanie", "Slovaquie", "Slovénie",
    "Suisse", "Suède"
  ]
  providerCode: any

  places: any[] = [];
  @ViewChild('autoInput') input: any;
  @ViewChild('autoInput2') input2: any;

  isLoading: boolean = true
  env: any;
  isSaved: Boolean = true

  idProvider: any
  provider: any

  formContact!: FormGroup;

  constructor(private route: ActivatedRoute,
    private fb: FormBuilder,
    private teamsService: TeamsService,
    private clientsService: ClientsService,) {
    this.idSociety = this.teamsService.getIdSociety()
    this.env = environment
    this.formContact = this.fb.group({
      contacts: this.fb.array([])
    })
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      this.idProvider = params.provider;
      this._fetchProvider();
    });
  }

  get contacts(): FormArray {
    return this.formContact.get("contacts") as FormArray;
  }

  async _fetchProvider() {
    await this.clientsService.getClient(this.idProvider).then((res: any) => {
      console.log(res)
      if (res) {
        this.isLoading = false
        this.provider = res;
        this.providerCode = this.provider.societies.find((soc) => soc.society._id === this.idSociety)?.providerCode
        this.mappingContactGroup(res?.contacts)
        console.log("this.providerCode")
        console.log(this.providerCode)
      }
    });
  }

  mappingContactGroup(data: any) {

    this.contacts.clear()

    data.forEach((item: any) => {
      this.contacts.push(this.fb.group({
        firstName: item?.firstName,
        lastName: item?.lastName,
        labelPost: item?.labelPost,
        post: item?.post,
        // postDescription: item?.postDescription,
        email: item?.email,
        numTel: item?.numTel,
        numTel2: item?.numTel2
      }))
    });
  }
  addNewContactGroup() {
    this.contacts.push(this.fb.group({
      firstName: [],
      lastName: [],
      labelPost: [],
      post: [],
      email: [],
      numTel: [],
      numTel2: []
    }))
  }
  deleteContactGroup(index: number) {
    if (this.formContact.value.contacts.length > 1) {
      this.contacts.removeAt(index);
    }
  }
  isOther(item: any) {
    return item == "Autre"
  }
  selectPost(event: any, index: any) {
    var valuePost = event.target.value

    const add = this.formContact.get('contacts') as FormArray;
    add.at(index).patchValue({
      post: (valuePost != "Autre") ? valuePost : null
    });
  }

  handleFileInput(event: any) {
    const element = event.currentTarget as HTMLInputElement;
    const filelist: FileList | null = element.files;
    if (filelist) {
      const fileToUpload = filelist[0];
      var reader = new FileReader();
      reader.onload = (event: any) => {
        // this.imageUrl = event.target.result;
      }
      reader.readAsDataURL(fileToUpload);

      this.uploadPhoto(fileToUpload)
    }
  }

  async uploadPhoto(fileToUpload: any) {
    const formData: any = new FormData();

    if (fileToUpload != null) {
      formData.append("photo", fileToUpload, fileToUpload['name']);
    }

    var profile: any = {
      photo_name: "logo-",
      folder_name: "providers/logo/"
    }

    for (var key in profile) {
      formData.append(key, profile[key]);
    }

    await this.teamsService.uploadPhoto(formData)
      .then((res: any) => {
        // console.log("resssssssss : ")
        // console.log(res)
        if (res) {
          if (res.response)
            this.provider.logoSociety = res.data
          else
            alert(res?.msg)
        } else
          alert("Probléme occured")
      });
  }

  checkPost() {
    if (this.isOther(this.provider.labelPost)) {
      if (this.provider.post != null && this.provider.post != "")
        return true
      else
        return false
    } else
      return true
  }
  checkField() {
    if (this.provider.providerName != null && this.provider.providerName != "" &&
      this.providerCode != null && this.providerCode != "" &&
      this.provider.emailSociety != null && this.provider.emailSociety != "" &&
      this.provider.numTelSociety != null && this.provider.numTelSociety != "" &&
      this.provider.incoterm != null && this.provider.incoterm != "" &&
      this.provider.adressSociety != null &&
      this.provider.fullAddress != null && this.provider.fullAddress != "" &&
      this.provider.country != null && this.provider.country != "" &&
      this.provider.stackable != null &&
      this.provider.prenom_client != null && this.provider.prenom_client != "" &&
      this.provider.nom_client != null && this.provider.nom_client != "" &&
      this.provider.labelPost != null && this.provider.labelPost != "" && this.checkPost() &&
      this.provider.contactUsername != null && this.provider.contactUsername != "" &&
      this.provider.contactNumTel != null && this.provider.contactNumTel != "" &&
      (this.provider?.contactMdp || (this.provider.password != null && this.provider.password != "")))
      return true
    else
      return false
  }
  async editProvider() {
    console.log("this.providerCode")
    console.log(this.providerCode)
    console.log(this.provider.societies)

    this.providerCode = this.provider.societies.find(item => item.society._id === this.idSociety)?.providerCode
    console.log(this.provider)
    this.provider.contacts = this.formContact.value.contacts
    var isValid = this.checkField()
    if (!isValid) {
      this.isSaved = isValid
      alert("Merci de remplir les champs obligatoire !!!")
    } else {
      await this.clientsService.editClient(this.idProvider, this.provider)
        .then((res: any) => {
          console.log("resssssssss : ")
          console.log(res)
          if (res) {
            if (res?.response) {
              location.href = '/providers';
            } else
              alert(res.msg)
            //this.sacsList = res;
          } else
            alert("Probléme reproduit !!!")
        });
    }
  }

  async onChange(input: string) {
    // console.log("sssssssssss")
    if (input === "adressSociety")
      this.places = await this._fetchAllPlace(this.input.nativeElement.value)
    else
      this.places = await this._fetchAllPlace(this.input2.nativeElement.value)

    // this.getFilteredOptions(this.input.nativeElement.value);
  }
  async onSelectionChange(event: any, input: string) {
    // console.log(event.option.value)
    const selectedValue = event.option.value;
    this.places = [] //this.getFilteredOptions($event);
    var detailsPlaceFrom = await this._fetchDetailsPlace(selectedValue.place_id)
    // console.log(detailsPlaceFrom)

    const country = this.getCountry(detailsPlaceFrom)
    var adress = {
      lat: detailsPlaceFrom.geometry.location.lat,
      lng: detailsPlaceFrom.geometry.location.lng,
      label_target: selectedValue.description,
      country: country
    }
    // console.log("adresssssssssss :::::::::::: ")
    // console.log(adress)

    if (input === "adressSociety") {
      this.input.nativeElement.value = selectedValue.description
      this.provider.adressSociety = adress

      this.provider.fullAddress = selectedValue.description
      if (this.countries.includes(country))
        this.provider.country = country
      else
        this.provider.country = null
    } else {
      this.input2.nativeElement.value = selectedValue.description
      this.provider.shippingAdress = adress
    }

    // console.log(this.provider.adressSociety)
    // this.positionChanged.emit(new PositionModel(
    //   detailsPlaceFrom.geometry.location.lat,
    //   detailsPlaceFrom.geometry.location.lng,
    //   $event.description
    // ));
  }
  getCountry(detailsPlaceFrom: any) {
    if (detailsPlaceFrom && detailsPlaceFrom?.address_components
      && detailsPlaceFrom?.address_components?.length > 0) {
      const countryComponent = detailsPlaceFrom?.address_components.find((component: any) => {
        return component.types.includes("country");
      });

      return countryComponent ? countryComponent.long_name : null;
    }
    return null
  }

  /** BEGIN SERVICE GOOGLE API */
  _fetchAllPlace(place: string) {
    let promise = new Promise<any>(async (resolve, reject) => {
      await this.clientsService.searchPlace(place).then((res: any) => {
        if (res) {
          // console.log("resssssssss : ")
          // console.log(res)
          // this.listPlacesFrom = 
          resolve(res.predictions)
        }
      }).catch((e: any) => {
        resolve([])
      });
    });
    return promise
  }
  _fetchDetailsPlace(placeId: string) {
    let promise = new Promise<any>(async (resolve, reject) => {
      await this.clientsService.detailsPlace(placeId).then((res: any) => {
        if (res) {
          // console.log("resssssssss : ")
          // console.log(res)
          // this.listPlacesFrom = 
          resolve(res)
        }
      }).catch((e: any) => {
        resolve(null)
      });
    });
    return promise
  }
  /** END SERVICE GOOGLE API */

  selectContactPost(event: any) {
    var valuePost = event.target.value

    if (valuePost != "Autre") {
      this.provider.post = null
    }
  }
}