import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-details-virement',
  templateUrl: './details-virement.component.html',
  styleUrls: ['./details-virement.component.css']
})
export class DetailsVirementComponent implements OnInit {

  statusCmds: any = []
  
  constructor(public dialogRef: MatDialogRef<DetailsVirementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cmdsService: CommandesService,) { }

  ngOnInit(): void {
    this.cmdsService.getLabeledStatus().then(
      (lst) => {
        this.statusCmds = lst
      }
    )
  }

}
