import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { interval, Subscription } from 'rxjs';
import { AddCommentsComponent } from 'src/app/components/shared/dialogs/add-comments/add-comments.component';
import { TransportorsService } from 'src/app/services/transportors/transportors.service';

@Component({
  selector: 'app-demandes-transportors',
  templateUrl: './demandes-transportors.component.html',
  styleUrls: ['./demandes-transportors.component.css']
})
export class DemandesTransportorsComponent implements OnInit {

  // subscription: Subscription;

  allDemandes: any = [];

  statusDemandes: any = [];
  status = ["Selectionnez status", "created", "accepted", "started", "arrived", "paied", "finish", "canceled"];
  selectedStatus: string = null

  currentPage: number = 0
  totalPages: number = 1
  pages: Array<number> = [];
  pagesDisplaied: Array<number> = [];

  serviceList: any = []
  service: string = null

  vehList: any = []
  vehicule: string = null

  planified: any = "all";
  dateinPlan: any = null
  datefinPlan: any = null

  selectedIndex: number = -1

  constructor(private transServices: TransportorsService,
    private dialog: MatDialog,) { }

  detailsProposition(index) {
    this.selectedIndex = index
  }

  ngOnInit(): void {
    this.transServices.getLabeledStatusDemande().then(
      (lst) => {
        this.statusDemandes = lst
      }
    )
    this.listVehicule()
    this.listServices()
    this.listDemandes()

    // const source = interval(10000);
    // this.subscription = source.subscribe(val => {
    //   this.listDemandes()
    // });
  }

  selectCamion(event: any) {
    this.vehicule = event.target.value;
    // console.log("this.vehicule")
    // console.log(this.vehicule)
  }

  async listVehicule() {
    await this.transServices.allTypeCamion().then((res: any) => {
      // console.log("listVehicule : ")
      // console.log(res)
      if (res) {
        this.vehList = res;
      }
    });
  }

  selectService(event: any) {
    this.service = event.target.value;
  }

  async listServices() {
    await this.transServices.allServices().then((res: any) => {
      // console.log("listVehicule : ")
      // console.log(res)
      if (res) {
        this.serviceList = res;
      }
    });
  }

  splicePages() {
    // console.log("currentPage : ")
    //     console.log(this.currentPage)
    if (this.pages.length > 10) {
      // if(this.currentPage < this.pages.length-10) {
      //   this.pagesDisplaied = this.pages.splice(this.currentPage, 10);
      //   console.log("if 1 pagesDisplaied : ")
      //   console.log(this.pagesDisplaied)
      // } else 
      if (this.currentPage < 5) {
        this.pagesDisplaied = this.pages.splice(0, 10);
        // console.log("if 1 pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      } else if (this.currentPage >= 5 && this.currentPage < this.pages.length - 5) {
        this.pagesDisplaied = this.pages.splice(this.currentPage - 4, 10);
        // console.log("if 2 pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      } else {
        this.pagesDisplaied = this.pages.splice(this.pages.length - 10, 10);
        // console.log("else pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      }
    } else {
      this.pagesDisplaied = this.pages
    }
  }

  async addCommentsDemande(demande) {
    const confirmDialog = this.dialog.open(AddCommentsComponent, {
      data: {
        demande: demande
      }
    });
  }
  addAppel(demande) {
    if (demande.appel_by == null)
      this.transServices.addAppel(demande._id).then((data: any) => {
        // console.log("dddddddddddddd")
        // console.log(data)

        if (data) {
          this.listDemandes()
        }
      });
  }
  async listDemandes(name?, numero?, datein?, datefin?) {
    await this.transServices.demandesTransportors(this.currentPage, this.selectedStatus,
      name, numero, datein, datefin, this.service, this.vehicule, this.planified, this.dateinPlan, this.datefinPlan).then((res: any) => {
        if (res) {
          // console.log("resssssssss : ")
          // console.log(res)
          this.allDemandes = res.items;
          this.totalPages = res.totalPages
          this.currentPage = res.currentPage
          this.pages = Array.from(Array(res.totalPages)).map((x, i) => i)
          this.splicePages()
        }
      });
  }

  goToNewTab(idDriver, route) {
    this.transServices.goToNewTab(idDriver, route)
  }

  goToNewTabDemande(idDemande, route) {
    this.transServices.goToNewTabDemande(idDemande, route)
  }

  selectChangeStatus(event: any) {
    if (event.target.value == "Selectionnez status") {
      this.selectedStatus = null
    } else {
      this.selectedStatus = event.target.value;
    }
  }

  async filterDemandes(name, numero, datein, datefin) {
    this.pages = []
    this.pagesDisplaied = []
    this.currentPage = 0
    this.listDemandes(name.value, numero.value, datein.value, datefin.value);
  }

  nextPage(name, numero, datein, datefin) {
    this.currentPage++
    this.listDemandes(name.value, numero.value, datein.value, datefin.value);
  }

  previousPage(name, numero, datein, datefin) {
    this.currentPage--
    this.listDemandes(name.value, numero.value, datein.value, datefin.value);
  }

  selectPage(page, name, numero, datein, datefin) {
    this.currentPage = page
    this.listDemandes(name.value, numero.value, datein.value, datefin.value);
  }
}
