import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommandesService } from 'src/app/services/commandes/commandes.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-detail-commande-client',
  templateUrl: './detail-commande-client.component.html',
  styleUrls: ['./detail-commande-client.component.css']
})
export class DetailCommandeClientComponent implements OnInit {

  isLoading: Boolean = true;
  env: any

  cmd: any = {}
  cmdID: string = '';
  statusCmds: any = []

  constructor(private route: ActivatedRoute,
    private cmdsService: CommandesService) {
    this.env = environment
  }

  ngOnInit(): void {
    this.cmdsService.getLabeledStatus().then(
      (lst) => {
        this.statusCmds = lst
      }
    )

    this.route.queryParams.subscribe((params) => {
      this.cmdID = params.cmd;
      this._fetchCmd();
      // console.log(this.cmd);
    });
  }

  _fetchCmd() {
    // console.log(cid);
    this.cmdsService.getOneCMD(this.cmdID).then((d) => {
      // console.log("dddddddddddddd")
      // console.log(d)

      if (d) {
        this.isLoading = false
        // console.log("dddddddddddddd")
        // console.log(d)
        this.cmd = d;
      } else {
        alert('Aucune commande valide');
        location.href = '/commandes';
      }
    });
  }

  goToNewTab(idCmd, route) {
    this.cmdsService.goToNewTab(idCmd, route)
  }

}
