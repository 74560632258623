<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-car icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Recherche
                <div class="page-title-subheading"><strong>Recherche par :</strong> Nom de destinataire
                    / E-mail / Numéro de téléphone / Adresse du client / Destination des commande.
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <div class="d-inline-block dropdown">
                <div class="search-wrapper active">
                    <div class="input-holder">
                        <!-- <form class="needs-validation" action="" method="post" onsubmit="return false;"
                            (onsubmit)="listCmdsClient(keyword);"> -->
                        <input type="text" id="keyword" name="keyword" class="search-input" placeholder="Type to search"
                            #keyword ngModel>

                        <button type="button" (click)="listCmdsClient(keyword)"
                            class="search-icon"><span></span></button>
                        <!-- </form> -->
                    </div>
                    <!-- <button class="close"></button> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Commandes
                <div class="btn-actions-pane-right">
                    <!-- <div role="group" class="btn-group-sm btn-group">
                        <div class="badge badge-warning">{{statusCmds}}</div>
                    </div> -->
                </div>
            </div>
            <div class="table-responsive">
                <div class="budget-price justify-content-center" *ngIf="listCmds?.length === 0">
                    <h2 class="text-center text-danger">Aucune commande </h2>
                </div>
                <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                    *ngIf="listCmds?.length > 0">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th>Fournisseur</th>
                            <th>Référence</th>
                            <th>Date Création</th>
                            <!-- <th class="text-center">Nom Client & Téléphone</th> -->
                            <!-- <th class="text-center">Ville</th> -->
                            <th class="text-center">Produits<br>
                                Quantité - Nom produit</th>
                            <th class="text-center">Volume<br>boxes<br>(m³)</th>
                            <th class="text-center">Volume<br>palettes<br>(m³)</th>
                            <th class="text-center">Poids<br>total (Kg)</th>
                            <th class="text-center">Status</th>
                            <th class="text-center">Total<br>Commande</th>
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let cmd of listCmds, let index = index">
                            <td class="text-center text-muted">#{{index+1}}</td>
                            <td>
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left mr-3">
                                            <div class="widget-content-left">
                                                <ngx-avatar class="rounded-circle" name="{{cmd?.client_cmd?.prenom_client}}
                                                {{cmd?.client_cmd?.nom_client}}">
                                                </ngx-avatar>
                                            </div>
                                        </div>
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading">
                                                {{cmd?.client_cmd?.providerName}}
                                            </div>
                                            <div class="widget-subheading opacity-7">
                                                {{cmd?.client_cmd?.shippingAdress?.label_target}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>{{cmd.ref_facture_cmd}}</td>
                            <td>{{cmd.date_creation_cmd | date:'d MMM y'}}<br>
                                <b>{{cmd.date_creation_cmd | date:'HH:mm'}}</b>
                            </td>
                            <td>
                                <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush"
                                *ngIf="cmd?.totalBoxes > 0">
                                    <li class="list-group-item">
                                        <div class="widget-content p-0">
                                            <div class="widget-content-wrapper">
                                                <div class="widget-content-left mr-3 widget-pallet">
                                                    <img width="100%"
                                                        src="https://assets.marketbey.tn/WTM/products/box.png" alt="">
                                                </div>
                                                <div class="widget-content-right">
                                                    <div class="font-size-lg text-muted">
                                                        <span>
                                                            {{ cmd?.totalBoxes }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush"
                                *ngIf="cmd?.totalPallets > 0">
                                    <li class="list-group-item">
                                        <div class="widget-content p-0">
                                            <div class="widget-content-wrapper">
                                                <div class="widget-content-left mr-3 widget-pallet">
                                                    <img width="100%"
                                                        src="https://assets.marketbey.tn/WTM/products/pallet.png"
                                                        alt="">
                                                </div>
                                                <div class="widget-content-right">
                                                    <div class="font-size-lg text-muted">
                                                        <span>
                                                            {{ cmd?.totalPallets }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </td>
                            <td class="text-center">
                                {{ cmd?.totalVolume | number:'0.1' }}
                            </td>
                            <td class="text-center">
                                {{ cmd?.totalPalletVolume | number:'0.1' }}
                            </td>
                            <td class="text-center">
                                {{ cmd?.totalPoids | number:'0.1' }}
                            </td>
                            <td class="text-center">
                                <div class="badge badge-{{statusCmds[cmd.etat_cmd].color}}">
                                    {{statusCmds[cmd.etat_cmd].label}}</div>
                            </td>
                            <td class="text-center">
                                {{cmd.total_ca_cmd |
                                number:'0.3'}} EUR</td>
                            <td class="text-center">
                                <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                    <button class="btn-wide btn btn-info">Actions</button>
                                    <button type="button" ngbDropdownToggle
                                        class="dropdown-toggle-split dropdown-toggle btn btn-info"><span
                                            class="sr-only">Actions</span></button>
                                    <div ngbDropdownMenu>
                                        <button type="button" (click)="goToNewTab(cmd._id, '/detail-cmd')" tabindex="0"
                                            class="dropdown-item">
                                            <i class="metismenu-icon pe-7s-news-paper"
                                                style="margin-right: 10px;"></i>Details
                                        </button>
                                        <button type="button" tabindex="0" (click)="goToNewTab(cmd._id, '/facture-cmd')"
                                            class="dropdown-item"><i class="metismenu-icon pe-7s-print"
                                                style="margin-right: 10px;"></i>Facture</button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div class="d-block text-center card-footer" *ngIf="listCmds?.length > 0">
                <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                        class="pe-7s-trash btn-icon-wrapper"> </i></button>
                <button class="btn-wide btn btn-success">Save</button>
            </div> -->
        </div>
    </div>
</div>