<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-user icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Fournisseur
                <div class="page-title-subheading">Modifier un fournisseur.
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isLoading; else elseGetProvider">
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-body text-center">
                    <div class="spinner-container blue-spinner">
                        <mat-progress-spinner mode="indeterminate" [diameter]="40"
                            [strokeWidth]="5"></mat-progress-spinner>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #elseGetProvider>

    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="tab-content" id="tab-content">
                    <div class="main-card mb-12 card">
                        <div class="card-header">Informations Générales du Fournisseur
                            <!-- <div class="btn-actions-pane-right">
                            <div role="group" class="btn-group-sm btn-group">
                                <div class="position-relative form-group">
                                    <input id="selectDate" type="date" class="form-control" name="selectDate"
                                        tabindex="1" [(ngModel)]="selectDate"
                                        (ngModelChange)="selectRoomsDay($event)">
                                </div>
                            </div>
                        </div> -->
                        </div>
                        <div class="card-body">
                            <div class="row form-group">
                                <div class="col-4">
                                    <div class="form-group">
                                        <div class="Neon Neon-theme-dragdropbox">
                                            <input style="z-index: 999; opacity: 0; width: 320px; height: 200px; position: absolute; right: 0px; 
                                left: 0px; margin-right: auto; margin-left: auto;" name="file" id="file" #file
                                                accept="image/*" (change)="handleFileInput($event)" type="file">
                                            <div class="Neon-input-dragDrop">
                                                <div class="Neon-input-inner">
                                                    <div class="Neon-input-icon"
                                                        *ngIf="provider?.logoSociety=='' || provider?.logoSociety == null">
                                                        <i class="fa fa-file-image-o"></i>
                                                    </div>
                                                    <div class="Neon-input-icon" *ngIf="provider?.logoSociety != null">
                                                        <img [src]="env.logoSocietyAssetsBaseURL + provider?.logoSociety"
                                                            style="width:100px">
                                                    </div>
                                                    <div class="Neon-input-text">
                                                        <h3>Drag&amp;Drop files here</h3> <span
                                                            style="display:inline-block; margin: 5px 0">or</span>
                                                    </div><a class="Neon-input-choose-btn blue">Browse
                                                        Files</a>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <mat-error style="color: red;font-weight: bold;" *ngIf="imageUrl==''">Ajouter
                                                    la photo de profile
                                                </mat-error> -->
                                    </div>
                                </div>
                                <div class="col-8">
                                    <div class="row">
                                        <div class="col-12 col-md-6 form-group">
                                            <legend for="providerName" class="">Nom de la société *</legend>
                                            <input name="providerName" id="providerName" type="text"
                                                class="form-control" [(ngModel)]="provider.providerName"
                                                [class]="(!isSaved && (provider.providerName=='' || provider?.providerName==null)) ? 'input-border-danger' : ''">
                                        </div>
                                        <div class="col-12 col-md-6 form-group">
                                            <legend for="providerDescription" class="">Description de la société
                                            </legend>
                                            <input name="providerDescription" id="providerDescription" type="text"
                                                class="form-control" [(ngModel)]="provider.providerDescription">
                                        </div>
                                        <div class="col-12 col-md-6 form-group">
                                            <legend for="providerCode" class="">Code fournisseur *</legend>
                                            <input name="providerCode" id="providerCode" type="text"
                                                class="form-control" [(ngModel)]="providerCode"
                                                [class]="(!isSaved && (providerCode=='' || providerCode==null)) ? 'input-border-danger' : ''">
                                        </div>
                                        <div class="col-12 col-md-6 form-group">
                                            <legend for="numberSiret" class="">Numéro de SIRET/SIREN</legend>
                                            <input name="numberSiret" id="numberSiret" type="text" class="form-control"
                                                [(ngModel)]="provider.numberSiret">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="tab-content" id="tab-content">
                    <div class="main-card mb-12 card">
                        <div class="card-header">Coordonnées du Fournisseur
                        </div>
                        <div class="card-body">
                            <div class="row form-group">
                                <div class="col-md-3 form-group">
                                    <div class="position-relative form-group">
                                        <legend for="emailSociety" class="">Adresse Email *</legend>
                                        <input name="emailSociety" id="emailSociety" type="text" class="form-control"
                                            [(ngModel)]="provider.emailSociety"
                                            [class]="(!isSaved && (provider.emailSociety=='' || provider.emailSociety==null)) ? 'input-border-danger' : ''">
                                    </div>
                                </div>
                                <div class="col-md-3 form-group">
                                    <div class="position-relative form-group">
                                        <legend for="siteWeb" class="">Site Web</legend>
                                        <input name="siteWeb" id="siteWeb" type="text" class="form-control"
                                            [(ngModel)]="provider.siteWeb">
                                    </div>
                                </div>
                                <div class="col-md-3 form-group">
                                    <div class="position-relative form-group">
                                        <legend for="numTelSociety" class="">Numéro de Téléphone *</legend>
                                        <input name="numTelSociety" id="numTelSociety" type="text" class="form-control"
                                            [(ngModel)]="provider.numTelSociety"
                                            [class]="(!isSaved && (provider.numTelSociety=='' || provider.numTelSociety==null)) ? 'input-border-danger' : ''">
                                    </div>
                                </div>
                                <div class="col-md-3 form-group">
                                    <div class="position-relative form-group">
                                        <legend for="incoTerm" class="">Incoterm *</legend>
                                        <select class="form-control" [(ngModel)]="provider.incoterm" name="incoTerm"
                                            id="incoTerm"
                                            [class]="(!isSaved && (!provider.incoterm)) ? 'input-border-danger' : ''">
                                            <option>Sélectionnez l'incoterm</option>
                                            <option [value]="item" *ngFor="let item of incoterms">
                                                {{item}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row form-group">
                                <div class="col-md-3 form-group">
                                    <legend for="adress" class="">Adresse Principale *</legend>
                                    <!-- <input name="adress2" id="adress2" type="text" class="form-control"
                                            [(ngModel)]="provider.adress2"> -->

                                    <input type="text" name="adress" id="adress" class="form-control" #autoInput
                                        matInput [matAutocomplete]="auto" (input)="onChange('adressSociety')"
                                        [value]="provider?.adressSociety?.label_target"
                                        [class]="(!isSaved && !provider.adressSociety) ? 'input-border-danger' : ''">

                                    <mat-autocomplete #auto="matAutocomplete"
                                        (optionSelected)="onSelectionChange($event, 'adressSociety')">
                                        <mat-option *ngFor="let place of places" [value]="place">
                                            {{ place.description }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                                <div class="col-md-5 form-group">
                                    <div class="position-relative form-group">
                                        <legend for="fullAddress" class="">Adresse complete *</legend>
                                        <input name="fullAddress" id="fullAddress" type="text" class="form-control"
                                            [(ngModel)]="provider.fullAddress"
                                            [class]="(!isSaved && (provider.fullAddress=='' || provider.fullAddress==null)) ? 'input-border-danger' : ''">
                                    </div>
                                </div>
                                <div class="col-md-2 form-group">
                                    <div class="position-relative form-group">
                                        <legend for="incoTerm" class="">Pays *</legend>
                                        <select class="form-control" [(ngModel)]="provider.country"
                                            placeholder="Sélectionnez le pays"
                                            [class]="(!isSaved && (provider.country=='' || provider.country==null)) ? 'input-border-danger' : ''">
                                            <option [value]="item" *ngFor="let item of countries">
                                                {{item}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="gerbable"
                                            [class]="(!isSaved && (provider.stackable == null)) ? 'input-border-danger' : ''">
                                            Gerbable *</legend>
                                        <div class="position-relative form-group">
                                            <div class="row col-12">
                                                <input name="responsable" type="radio" checked [value]="true"
                                                    class="form-check-input" [(ngModel)]="provider.stackable">
                                                Oui
                                            </div>
                                            <div class="row col-12">
                                                <input name="responsable" type="radio" [value]="false"
                                                    class="form-check-input" [(ngModel)]="provider.stackable">
                                                Non
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="tab-content" id="tab-content">
                    <div class="main-card mb-12 card">
                        <div class="card-header">Contact Principal
                        </div>
                        <div class="card-body">
                            <div class="row form-group">
                                <div class="col-md-3">
                                    <div class="position-relative form-group">
                                        <legend for="prenom_client" class="">Prénom du contact *</legend>
                                        <input name="prenom_client" id="prenom_client" type="text" class="form-control"
                                            [(ngModel)]="provider.prenom_client"
                                            [class]="(!isSaved && (provider.prenom_client=='' || provider.prenom_client==null)) ? 'input-border-danger' : ''">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="position-relative form-group">
                                        <legend for="nom_client" class="">Nom du contact *</legend>
                                        <input name="nom_client" id="nom_client" type="text" class="form-control"
                                            [(ngModel)]="provider.nom_client"
                                            [class]="(!isSaved && (provider.nom_client=='' || provider.nom_client==null)) ? 'input-border-danger' : ''">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-12"
                                            [class]="!isOther(provider.labelPost) ? 'col-md-12' : 'col-md-6'">

                                            <legend for="labelPost" class="">Titre/Poste *</legend>
                                            <select class="form-control" [(ngModel)]="provider.labelPost"
                                                name="labelPost" id="labelPost" (change)="selectContactPost($event)"
                                                [class]="(!isSaved && !provider.labelPost) ? 'input-border-danger' : ''">
                                                <option>Sélectionnez la poste du contact</option>
                                                <option [value]="item" *ngFor="let item of posts">
                                                    {{item}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-12 col-md-6" *ngIf="isOther(provider.labelPost)"
                                            style="margin-top: auto;">
                                            <input name="otherPost" id="otherPost" type="text" class="form-control"
                                                [(ngModel)]="provider.post"
                                                [class]="(!isSaved && isOther(provider.labelPost) && !provider.post) ? 'input-border-danger' : ''">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <legend for="contactUsername" class="">Email du contact *</legend>
                                        <input name="contactUsername" id="contactUsername" type="text"
                                            class="form-control" [(ngModel)]="provider.contactUsername"
                                            [class]="(!isSaved && (provider.contactUsername=='' || provider.contactUsername==null)) ? 'input-border-danger' : ''">
                                    </div>
                                </div>
                                <div class="col-md-4 form-group">
                                    <legend for="contactNumTel" class="">Numéro de téléphone du contact *
                                    </legend>
                                    <input name="contactNumTel" id="contactNumTel" type="number" class="form-control"
                                        [(ngModel)]="provider.contactNumTel"
                                        [class]="(!isSaved && (provider.contactNumTel?.toString()=='' || provider.contactNumTel==null)) ? 'input-border-danger' : ''">
                                </div>

                                <div class="col-md-4 form-group">
                                    <legend for="contactSecondNumTel" class="">Numéro de téléphone secondaire
                                    </legend>
                                    <input name="contactSecondNumTel" id="contactSecondNumTel" type="number"
                                        class="form-control" [(ngModel)]="provider.contactSecondNumTel">
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-md-6">
                                    <div class="position-relative form-group">
                                        <legend for="password" class="">Password *</legend>
                                        <input name="password" id="password" type="password" class="form-control"
                                            [(ngModel)]="provider.password"
                                            [class]="(!isSaved && (provider.password=='' 
                                                || (provider.password!='' && provider.password!=provider.confirmPassword))) ? 'input-border-danger' : ''">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="position-relative form-group">
                                        <legend for="confirmPassword" class="">Confirmer password *</legend>
                                        <input name="confirmPassword" id="confirmPassword" type="password"
                                            class="form-control" [(ngModel)]="provider.confirmPassword">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-header-tab card-header">
                    <div class="card-header-title">
                        <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
                        Contacts
                    </div>
                    <div class="btn-actions-pane-right">
                        <button type="button" (click)="addNewContactGroup()" class="mt-1 btn btn-warning">
                            <i class="pe-7s-plus"></i> Ajouter
                        </button>
                    </div>
                </div>
                <div class="card-body">
                    <div [formGroup]="formContact">
                        <div formArrayName="contacts">
                            <div *ngFor="let contact of contacts.controls; let i = index">
                                <div class="position-relative row form-group" [formGroupName]="i"
                                    style="margin-top: 10px;">
                                    <div class="col-12 div-contact">
                                        <div class="row">
                                            <div class="col-12 col-md-11">
                                                <div class="row form-group">
                                                    <div class="col-md-3">
                                                        <div class="position-relative form-group">
                                                            <legend for="firstName" class="">Prénom du contact
                                                            </legend>
                                                            <input name="firstName" id="firstName" type="text"
                                                                class="form-control" formControlName="firstName">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="position-relative form-group">
                                                            <legend for="lastName" class="">Nom du contact
                                                            </legend>
                                                            <input name="lastName" id="lastName" type="text"
                                                                class="form-control" formControlName="lastName">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="row">
                                                            <div class="col-12"
                                                                [class]="!isOther(contact.value.post) ? 'col-md-12' : 'col-md-6'">
                                                                <legend for="post" class="">Titre/Poste</legend>
                                                                <select class="form-control" formControlName="post"
                                                                    name="post" id="post"
                                                                    (change)="selectPost($event, i)">
                                                                    <option>Sélectionnez la poste du contact
                                                                    </option>
                                                                    <option [value]="item" *ngFor="let item of posts">
                                                                        {{item}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            <div class="col-12 col-md-6"
                                                                *ngIf="isOther(contact.value.post)"
                                                                style="margin-top: auto;">
                                                                <input name="otherPost" id="otherPost" type="text"
                                                                    class="form-control"
                                                                    formControlName="postDescription">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <div class="col-md-4">
                                                        <div class="position-relative form-group">
                                                            <legend for="username" class="">Email du contact
                                                            </legend>
                                                            <input name="username" id="username" type="text"
                                                                class="form-control" formControlName="email">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 form-group">
                                                        <legend for="numTel" class="">Numéro de téléphone du
                                                            contact
                                                        </legend>
                                                        <input name="numTel" id="numTel" type="number"
                                                            class="form-control" formControlName="numTel">
                                                    </div>

                                                    <div class="col-md-4 form-group">
                                                        <legend for="numTel2" class="">Numéro de téléphone
                                                            secondaire
                                                        </legend>
                                                        <input name="numTel2" id="numTel2" type="number"
                                                            class="form-control" formControlName="numTel2">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-1 div-center">
                                                <button type="button" (click)="deleteContactGroup(i)"
                                                    class="mr-2 btn-icon btn-icon-only btn btn-danger float-right">
                                                    <i class="pe-7s-trash btn-icon-wrapper"></i>
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="tab-content" id="tab-content">
                    <div class="main-card mb-12 card">
                        <div class="card-header">Détails Logistiques
                        </div>
                        <div class="card-body">
                            <div class="row form-group">
                                <div class="col-md-4 form-group">
                                    <legend for="adress2" class="">Adresse d'Expédition <br>(Si différente de
                                        l'adresse
                                        principale)</legend>

                                    <input type="text" name="adress2" id="adress2" class="form-control" #autoInput2
                                        matInput [matAutocomplete]="auto2" (input)="onChange('shippingAdress')"
                                        [value]="provider?.shippingAdress?.label_target">

                                    <mat-autocomplete #auto2="matAutocomplete"
                                        (optionSelected)="onSelectionChange($event, 'shippingAdress')">
                                        <mat-option *ngFor="let place of places" [value]="place">
                                            {{ place.description }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                                <div class="col-md-4 form-group">
                                    <div class="position-relative form-group">
                                        <legend for="numTel_society" class="">Horaires de Chargement</legend>
                                        <div class="row">
                                            <div class="col-12 col-md-4">
                                                <select class="form-control" [(ngModel)]="provider.startTravel"
                                                    name="startTravel" id="startTravel">
                                                    <!-- <option [value]="null">Sélectionnez la poste du contact</option> -->
                                                    <option [value]="item.value" *ngFor="let item of timer">
                                                        {{item.value}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <select class="form-control" [(ngModel)]="provider.endTravel"
                                                    name="endTravel" id="endTravel">
                                                    <!-- <option [value]="null">Sélectionnez la poste du contact</option> -->
                                                    <option [value]="item.value" *ngFor="let item of timer">
                                                        {{item.value}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 form-group">
                                    <div class="position-relative form-group">
                                        <legend for="instractionPart" class="">Instructions Particulières
                                        </legend>
                                        <input name="instractionPart" id="instractionPart" type="text"
                                            class="form-control" [(ngModel)]="provider.instractionPart">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="tab-content" id="tab-content">
                    <div class="main-card mb-12 card">
                        <div class="card-header">Informations sur les Marchandises
                        </div>
                        <div class="card-body">
                            <div class="row form-group">
                                <div class="col-md-6 form-group">
                                    <div class="position-relative form-group">
                                        <legend for="numTel_society" class="">Types de Marchandises Fournies
                                        </legend>
                                        <input name="type" id="type" type="text" class="form-control"
                                            [(ngModel)]="provider.typeGoods">

                                        <!-- <select class="form-control" [(ngModel)]="provider.typeGoods" name="type"
                                            id="type">
                                            <option>Sélectionnez le type de marchandise</option>
                                            <option [value]="item" *ngFor="let item of typesMarch">
                                                {{item}}
                                            </option>
                                        </select> -->
                                    </div>
                                </div>
                                <div class="col-md-6 form-group">
                                    <div class="position-relative form-group">
                                        <legend for="numTel_society" class="">Conditionnements Habituellement
                                            Utilisés
                                        </legend>
                                        <input name="subType" id="subType" type="text" class="form-control"
                                            [(ngModel)]="provider.subType">

                                        <!-- <select class="form-control" [(ngModel)]="provider.subType" name="subType"
                                            id="subType">
                                            <option [value]="null">Sélectionnez le type de marchandise</option>
                                            <option [value]="item" *ngFor="let item of typesMarch">
                                                {{item}}
                                            </option>
                                        </select> -->
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row form-group">
                                <div class="col-md-6 form-group">
                                    <legend for="price_kilo" class="">Prix au kilograme </legend>
                                    <input name="price_kilo" id="price_kilo" type="number" class="form-control"
                                        [(ngModel)]="provider.price_kilo">
                                </div>
                                <div class="col-md-6 form-group">
                                    <legend for="price_volume" class="">Prix en volume ³</legend>
                                    <input name="price_volume" id="price_volume" type="number" class="form-control"
                                        [(ngModel)]="provider.price_volume">
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="tab-content" id="tab-content">
                    <div class="main-card mb-12 card">
                        <div class="card-header">Autres Informations
                        </div>
                        <div class="card-body">
                            <div class="row form-group">
                                <div class="col-md-12">
                                    <legend for="firstName" class="">Notes et Commentaires</legend>
                                    <textarea name="text" id="exampleText" class="form-control" style="height: 132px;"
                                        [(ngModel)]="provider.comment"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="tab-content" id="tab-content">
                    <div class="main-card mb-12 card">
                        <div class="d-block text-center card-footer">
                            <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger">
                                Annuler
                            </button>
                            <button class="btn-wide btn btn-primary" (click)="editProvider()">
                                Modifier
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>