import { Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { TeamsService } from 'src/app/services/teams-cofat/teams.service';

@Component({
  selector: 'app-add-box',
  templateUrl: './add-box.component.html',
  styleUrls: ['./add-box.component.css']
})
export class AddBoxComponent implements OnInit, AfterViewInit {

  isSaved: boolean = true

  @Output() result = new EventEmitter<any>();
  @Input() box: any;

  @ViewChild('autoInput') input: any;
  providers: any[] = []
  provider: any
  idSociety: string;

  constructor(private activeModal: NgbActiveModal,
    private serviceClient: ClientsService,
    private cmdService: CommandesService,
    private service: SettingsService,
    private teamsServices: TeamsService) {
    this.idSociety = this.teamsServices.getIdSociety();
  }

  ngOnInit(): void {
    // console.log("this.box")
    // console.log(this.box)
    if (this.box?.provider) {
      this.provider = this.box?.provider
    }
  }

  ngAfterViewInit() {
    if (this.provider)
      this.input.nativeElement.value = this.provider?.providerCode + ' - '
        + this.provider?.providerName
  }

  async onChange() {
    // console.log(this.input.nativeElement.value)
    if (this.input.nativeElement.value !== "") {
      this.providers = await this._searchProviders(this.input.nativeElement.value)
    }
  }

  onSelectionChange(event: any) {
    this.provider = event.option.value;
    // console.log(this.provider)

    this.input.nativeElement.value = this.provider.providerCode
      + " - " + this.provider.providerName

    this.box.provider = this.provider?._id

    this.providers = []
  }

  saveBox() {
    var isValidate = this.checkField()
    if (!isValidate) {
      this.isSaved = isValidate
      alert("Merci de remplir les champs obligatoire !!!")
    } else {
      if (this.idSociety != null) {
        this.box.society = this.idSociety
      }
      this.box.volume = this.cmdService.formatNumber(
        this.box.t_wdth * this.box.t_dpth * this.box.t_hght, 3)
      this.service.saveBoxProduct(this.box)
        .then((res: any) => {
          if (res) {
            // console.log("resssssssss : ")
            // console.log(res)
            if (res?.response) {
              this.result.emit(true);
              this.activeModal.dismiss();
            }
          }
        });
    }
  }

  checkField() {
    if (this.box.ref != null && this.box.ref != "" &&
      this.box.t_seak != null && this.box.t_seak != "" &&
      this.box.t_hght != null && this.box.t_hght.toString() != "" &&
      this.box.t_wdth != null && this.box.t_wdth.toString() != "" &&
      this.box.t_dpth != null && this.box.t_dpth.toString() != "" &&
      this.box.ucs != null && this.box.ucs.toString() != "" &&
      this.box.provider != null && this.box.provider != "")
      return true
    else
      return false
  }

  closeModal() {
    this.result.emit(null);
    this.activeModal.dismiss();
  }

  _searchProviders(keyword: string) {
    let promise = new Promise<any>(async (resolve, reject) => {
      await this.serviceClient.filterClient(keyword).then((res: any) => {
        // console.log(res)
        if (res) {
          resolve(res)
        }
      }).catch((e: any) => {
        resolve([])
      });
    });
    return promise
  }

}
