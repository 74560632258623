import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { ListCommentsComponent } from 'src/app/components/shared/dialogs/list-comments/list-comments.component';
import { CommandesService } from 'src/app/services/commandes/commandes.service';
import { UsersTrackingService } from 'src/app/services/users-tracking/users-tracking.service';

@Component({
  selector: 'app-commandes-tracking-user',
  templateUrl: './commandes-tracking-user.component.html',
  styleUrls: ['./commandes-tracking-user.component.css']
})
export class CommandesTrackingUserComponent implements OnInit {

  idUser: string = '';
  userTracking: any = null;

  statusCmds: any = [];
  tagCmds: string = "LIVREE";
  listCmds: any = []

  cmdsLivred: any = []
  cmdsReturned: any = []
  cmdsInProgress: any = []


  totalCmdsReturned: any = 0
  totalCmdsLivred: any = 0;
  fraisLivred: any = 0
  fraisReturned: any = 0


  currentDate = new Date();

  constructor(private route: ActivatedRoute,
    private router: Router,
    private trakingServices: UsersTrackingService,
    private cmdsServices: CommandesService,
    private notif: NotifierService,
    private dialog: MatDialog) { }

  async ngOnInit() {
    this.cmdsServices.getLabeledStatus().then(
      (lst) => {
        this.statusCmds = lst
      }
    )

    await this.route.queryParams.subscribe(async (params) => {
      this.idUser = params.idUser;
      this.getUserTracking()

      // this.cmdsDelivered = await this.trakingServices.cmdsLivredByTransporter(this.idUser)
      // this.fraisLivred = await this.cmdsDelivered.length * 5
      // this.totalCmdsLivred = await this.totalCmdsByStatus(this.cmdsDelivered)

      // this.cmdsReturned = await this.trakingServices.cmdsReturnedByTransporter(this.idUser)
      // this.fraisReturned = await this.cmdsReturned.length * 5
      // this.totalCmdsReturned = await this.totalCmdsByStatus(this.cmdsReturned)

      // this.cmdsEnCours = await this.listCmdsUser(["transporting", "transported", "arrived"])

      ///// this.getPriceLivred()
      ///// this.getPriceAffected()

      // this.fetchCmdsAffected();
      await this.detailsCmdsTrackingNotPaiedAndNotReturnedAndInProgress()

    });
    // this.listCmdsLivredByTransporter("LIVREE")
  }

  detailsCmdsTrackingNotPaiedAndNotReturnedAndInProgress() {
    this.trakingServices.allCmdsTrackingLivredReturnedAndInProgress(this.idUser).then(async (res: any) => {
      // console.log("allCmdsTrackingLivredReturnedAndInProgress .ts")
      // console.log(res)
      if (res) {
        this.cmdsLivred = res.cmdsLivred
        this.listCmds = this.cmdsLivred
        this.fraisLivred = this.cmdsLivred.length * this.userTracking.frais_livraison
        this.cmdsReturned = res.cmdsReturned
        this.cmdsInProgress = res.cmdsInProgress
        this.totalCmdsLivred = await this.totalCmdsByStatus(this.cmdsLivred)
      }
    });
  }
  async listCmdsLivredByTransporter(tagCmds) {
    this.tagCmds = tagCmds
    this.listCmds = this.cmdsLivred

    this.fraisLivred = this.cmdsLivred.length * this.userTracking.frais_livraison
    this.totalCmdsLivred = await this.totalCmdsByStatus(this.cmdsLivred)
  }
  async listCmdsReturnedByTransporter(tagCmds) {
    this.tagCmds = tagCmds
    this.listCmds = this.cmdsReturned
  }
  async listCmdsInProgressByTransporter(tagCmds) {
    this.tagCmds = tagCmds
    this.listCmds = this.cmdsInProgress
  }


  async totalCmdsByStatus(array) {
    let total = 0
    array.forEach(element => {
      total += element.total_ca_cmd + element.frais_livraison
    });

    return total
  }

  // async listCmdsUser(status) {
  // //   let cmds: any = []
  // //   var prices = 0.0
  //   return await this.cmdsServices.allCmdsUserByStatus(this.idUser, status)
  //   // cmds.map(async (cmd) => {
  //   //   prices = await prices + cmd.total_ca_cmd
  //   // })

  //   // return cmds.length
  // }

  getUserTracking() {
    this.trakingServices.getOneUserTracking(this.idUser).then((user) => {
      if (user) {
        // console.log("userrrrrrr : ")
        // console.log(user)
        this.userTracking = user;
      }
    });
  }

  async validateReturnedCmd() {
    if (confirm('Etes vous sûre?'))
      await this.listCmds.forEach(async (cmd) => {
        await this.cmdsServices.validateReturnedCmdsByTransporter(cmd.qr_code, "returned_exp")
      });
    await this.detailsCmdsTrackingNotPaiedAndNotReturnedAndInProgress()
    // window.location.reload();
    // await this.listCmdsReturnedByTransporter('RETOURNEE')
    // location.href = '/commandes-tracking-user?idUser=' + this.idUser;
    // this.router.navigateByUrl('/commandes-tracking-user?idUser=600f604b80af1f5fd615ba30', { skipLocationChange: true }).then(() => {
    //   this.router.navigate(['/commandes-tracking-user']);
    // });
    // this.cmdsReturned = []
    // this.listCmds = this.cmdsReturned
  }
  async validateLivredCmds() {
    if (confirm('Etes vous sûre?'))
      await this.listCmds.forEach(async (cmd) => {
        await this.cmdsServices.paiedCmdByTransporter(cmd.qr_code)
      })
    await this.detailsCmdsTrackingNotPaiedAndNotReturnedAndInProgress()
    // window.location.reload();
    // location.href = '/commandes-tracking-user?idUser=' + this.idUser;
    // this.cmdsLivred = []
    // this.listCmds = this.cmdsLivred
    // this.fraisLivred = this.cmdsLivred.length * 5
    // this.totalCmdsLivred = await this.totalCmdsByStatus(this.cmdsLivred)
  }

  listComments(cmd) {
    // window.scrollTo(0, 0);
    if (cmd.comments.length > 0) {
      const confirmDialog = this.dialog.open(ListCommentsComponent, {
        autoFocus: false,
        // panelClass: 'trend-dialog',
        data: {
          cmd: cmd,
        }
      });
    }
  }

  goToNewTab(route) {
    this.trakingServices.goToNewTab(this.idUser, route)
  }

}
