import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { TeamsService } from 'src/app/services/teams-cofat/teams.service';
import { TransportorSocietyService } from 'src/app/services/transportor-society/transportor-society.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-transportor-society',
  templateUrl: './edit-transportor-society.component.html',
  styleUrls: ['./edit-transportor-society.component.css']
})
export class EditTransportorSocietyComponent implements OnInit {

  isLoading: boolean = true
  env: any;
  isSaved: Boolean = true

  places: any[] = [];

  idTrans: any
  trans_society: any = {}

  posts: any[] = [
    "Responsable Logistique",
    "Financière",
    "Exploitation",
    "Autre"
  ]

  form!: FormGroup;
  formContact!: FormGroup;

  constructor(private route: ActivatedRoute,
    private teamsService: TeamsService,
    private fb: FormBuilder,
    private clientsService: ClientsService,
    private service: TransportorSocietyService,) {
    this.env = environment

    this.form = this.fb.group({
      locations: this.fb.array([])
    })
    this.formContact = this.fb.group({
      contacts: this.fb.array([])
    })
  }

  get locations(): FormArray {
    return this.form.get("locations") as FormArray;
  }
  get contacts(): FormArray {
    return this.formContact.get("contacts") as FormArray;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      this.idTrans = params.society;
      this._fetchTrans();
    });
  }

  async updateSociety() {
    this.trans_society.contacts = this.formContact.value.contacts
    this.trans_society.adresses = this.form.value.locations

    // console.log(this.trans_society)

    var isValid = this.checkField()
    if (!isValid) {
      this.isSaved = isValid
      alert("Merci de remplir les champs obligatoire !!!")
    } else {
      await this.service.editSociety(this.idTrans, this.trans_society)
        .then((res: any) => {
          // console.log("resssssssss : ")
          // console.log(res)
          if (res) {
            if (res?.response) {
              location.href = '/society-transportors';
            } else
              alert(res.msg)
            //this.sacsList = res;
          } else
            alert("Probléme reproduit !!!")
        });
    }
  }


  async _fetchTrans() {
    await this.service.getTransSociety(this.idTrans).then((res: any) => {
      // console.log(res)
      if (res) {
        this.isLoading = false
        this.trans_society = res.data;
        this.mappingContactGroup(this.trans_society?.contacts)
        this.mappingLocationsGroup(this.trans_society?.adresses)
      }
    });
  }

  isOther(item: any) {
    return item === "Autre"
  }
  selectPost(event: any, index: any) {
    var valuePost = event.target.value

    const add = this.formContact.get('contacts') as FormArray;
    add.at(index).patchValue({
      post: (valuePost != "Autre") ? valuePost : null
    });
  }
  selectContactPost(event: any) {
    var valuePost = event.target.value

    if (valuePost != "Autre") {
      this.trans_society.post = null
    }
  }
  checkValue(item: any) {
    if (item == null || (item != null && item == ""))
      return true
    else
      return false
  }

  /** BEGIN HANDLE AND UPLOAD PHOTO */
  handleFileInput(event: any) {
    const element = event.currentTarget as HTMLInputElement;
    const filelist: FileList | null = element.files;
    if (filelist) {
      const fileToUpload = filelist[0];
      var reader = new FileReader();
      reader.onload = (event: any) => {
        // this.imageUrl = event.target.result;
      }
      reader.readAsDataURL(fileToUpload);

      this.uploadPhoto(fileToUpload)
    }
  }
  async uploadPhoto(fileToUpload: any) {
    const formData: any = new FormData();

    if (fileToUpload != null) {
      formData.append("photo", fileToUpload, fileToUpload['name']);
    }

    var profile: any = {
      photo_name: "logo-",
      folder_name: "trans-societies/logo/"
    }

    for (var key in profile) {
      formData.append(key, profile[key]);
    }

    await this.teamsService.uploadPhoto(formData)
      .then((res: any) => {
        // console.log("resssssssss : ")
        // console.log(res)
        if (res) {
          if (res.response)
            this.trans_society.logo = res.data
          else
            alert(res?.msg)
        } else
          alert("Probléme occured")
      });
  }
  /** END HANDLE AND UPLOAD PHOTO */

  /** BEGIN FUNCTIONS LOCATION ADRESS */
  addLocation() {
    this.locations.push(this.fb.group({
      placement: [],
      label_target: [], //label_target
      country: [],
      lat: [],
      lng: [],
    }))
  }
  deleteLocation(locationIndex: number) {
    if (this.form.value.locations.length > 1) {
      this.locations.removeAt(locationIndex);
    }
  }
  mappingLocationsGroup(data: any) {

    this.locations.clear()

    data.forEach((item: any) => {
      this.locations.push(this.fb.group({
        placement: item?.placement,
        label_target: item?.label_target,
        country: item?.country,
        lat: item?.lat,
        lng: item?.lng
      }))
    });
  }
  /** END FUNCTIONS LOCATION ADRESS */


  /** BEGIN FUNCTIONS CONTACTS */
  addNewContactGroup() {
    this.contacts.push(this.fb.group({
      firstName: [],
      lastName: [],
      labelPost: [],
      post: [],
      email: [],
      numTel: [],
      numTel2: []
    }))
  }
  deleteContactGroup(index: number) {
    // if (this.formContact.value.contacts.length > 1) {
    this.contacts.removeAt(index);
    // }
  }
  mappingContactGroup(data: any) {

    this.contacts.clear()

    data.forEach((item: any) => {
      this.contacts.push(this.fb.group({
        firstName: item?.firstName,
        lastName: item?.lastName,

        labelPost: item?.labelPost,
        post: item?.post,

        email: item?.email,
        numTel: item?.numTel,
        numTel2: item?.numTel2
      }))
    });
  }
  /** END FUNCTIONS CONTACTS */

  /** BEGIN CKECK VALUES */
  checkPost() {
    if (!this.isOther(this.trans_society.labelPost))
      return true
    else {
      if (this.trans_society.post != null && this.trans_society.post != "")
        return true
      else
        return false
    }
  }
  checkField() {
    // console.log(this.trans_society.numTelSociety)
    if (this.trans_society.nameSociety != null && this.trans_society.nameSociety != "" &&
      this.trans_society.emailSociety != null && this.trans_society.emailSociety != "" && this.trans_society.emailSociety.includes("@") &&
      this.trans_society.numTelSociety != null && this.trans_society.numTelSociety != "" &&
      this.trans_society.logo != null && this.trans_society.logo != "" &&

      this.trans_society.firstName != null && this.trans_society.firstName != "" &&
      this.trans_society.lastName != null && this.trans_society.lastName != "" &&
      this.trans_society.labelPost != null && this.trans_society.labelPost != "" && this.checkPost() &&

      this.trans_society.email != null && this.trans_society.email != "" && this.trans_society.email.includes("@") &&
      this.trans_society.numTel != null && this.trans_society.numTel != "" &&
      this.locationsNotEmpty(this.form.value.locations))
      return true
    else
      return false
  }
  locationsNotEmpty(locations: any) {
    if (locations.length == 0)
      return false

    for (var i = 0; i < locations.length; i++) {
      if (locations[i].label_target == null || locations[i].label_target == ""

        // || freightRates[i].minimum == null
        // || freightRates[i].minimum.max == null || freightRates[i].minimum.max == ""
        // || freightRates[i].minimum.value == null || freightRates[i].minimum.value == ""

        // || freightRates[i].maximum == null || freightRates[i].maximum == ""
      )
        return false
    }
    return true
  }
  /** END CKECK VALUES */

  /** BEGIN FETCH PLACES */
  async onChange(event: any) {
    // console.log(event?.target?.value)
    this.places = await this._fetchAllPlace(event?.target?.value)
  }
  async onSelectionChange(event: any, i: number) {
    const add = this.form.get('locations') as FormArray;

    // console.log(event.option.value)
    const selectedValue = event.option.value;
    add.at(i).patchValue({
      label_target: selectedValue.description,
    })
    //this.getFilteredOptions($event);
    // this.input.nativeElement.value = selectedValue.description
    var detailsPlaceFrom = await this._fetchDetailsPlace(selectedValue.place_id)
    // console.log(detailsPlaceFrom)
    // this.trans_society.adress = {
    //   lat: detailsPlaceFrom.geometry.location.lat,
    //   lng: detailsPlaceFrom.geometry.location.lng,
    //   label_target: selectedValue.description
    // }

    add.at(i).patchValue({
      // type_prod: selectedValue._id,
      lat: detailsPlaceFrom.geometry.location.lat,
      lng: detailsPlaceFrom.geometry.location.lng,
      placement: "Entrepot",
      country: this.getCountry(detailsPlaceFrom)
    });

    this.places = []
  }
  getCountry(detailsPlaceFrom: any) {
    if (detailsPlaceFrom && detailsPlaceFrom?.address_components
      && detailsPlaceFrom?.address_components?.length > 0) {
      const countryComponent = detailsPlaceFrom?.address_components.find((component: any) => {
        return component.types.includes("country");
      });

      return countryComponent ? countryComponent.long_name : null;
    }
    return null
  }

  _fetchAllPlace(place: string) {
    let promise = new Promise<any>(async (resolve, reject) => {
      await this.clientsService.searchPlace(place).then((res: any) => {
        if (res) {
          // console.log("resssssssss : ")
          // console.log(res)
          // this.listPlacesFrom = 
          resolve(res.predictions)
        }
      }).catch((e: any) => {
        resolve([])
      });
    });
    return promise
  }
  
  _fetchDetailsPlace(placeId: string) {
    let promise = new Promise<any>(async (resolve, reject) => {
      await this.clientsService.detailsPlace(placeId).then((res: any) => {
        if (res) {
          // console.log("resssssssss : ")
          // console.log(res)
          // this.listPlacesFrom = 
          resolve(res)
        }
      }).catch((e: any) => {
        resolve(null)
      });
    });
    return promise
  }
  /** END FETCH PLACES */

}
