import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientsService } from 'src/app/services/clients/clients.service';

@Component({
  selector: 'app-edit-frais-client',
  templateUrl: './edit-frais-client.component.html',
  styleUrls: ['./edit-frais-client.component.css']
})
export class EditFraisClientComponent implements OnInit {

  idClient: string = ""
  client: any = {}

  constructor(
    private route: ActivatedRoute,
    private clientsService: ClientsService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.idClient = params.client;
      this.getClient()
    });
  }

  async getClient() {
    await this.clientsService.getClient(this.idClient).then((res: any) => {
      // console.log(res)
      if (res) {
        this.client = res;
      }
    });
  }

  // async getFraisClient() {
  //     await this.clientsService.getFraisClient(this.idClient)
      
  //       .then((res: any) => {
  //         // console.log(res)
  //         if (res) {
  //           this.frais = res
  //           //location.href = '/clients';
  //           //this.sacsList = res;
  //         }
  //       });
  // }

  async updateFraisClient() {
    // await this.clientsService.addFrais(this.idClient, fraisInterne.value, 
    //   fraisExterne.value, fraisRetour.value)
    //   .then((res: any) => {
    //     console.log("resssssssss : ")
    //     console.log(res)
    //     if (res) {
    //       //location.href = '/clients';
    //       //this.sacsList = res;
    //     }
    //   });
  }

}
