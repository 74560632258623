<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-box2 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Enlèvement
                <div class="page-title-subheading">Modifier l'enlèvement.
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isLoading; else elseEditCmd">
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-body text-center">
                    <div class="spinner-container blue-spinner">
                        <mat-progress-spinner mode="indeterminate" [diameter]="40"
                            [strokeWidth]="5"></mat-progress-spinner>
                    </div>
                </div>
                <!-- <div style="height: 70px;" [nbSpinner]="loading" nbSpinnerStatus="warning" nbSpinnerSize="giant">
            </div> -->
            </div>
        </div>
    </div>
</div>
<ng-template #elseEditCmd>

    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-header-tab card-header">
                    <div class="card-header-title">
                        <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
                        L'expéditeur
                    </div>
                </div>
                <div class="card-body">
                    <div class="position-relative row form-group">
                        <div class="col-sm-1"></div>
                        <div class="col-sm-5">
                            <input name="nameCompany" id="nameCompany" type="text" disabled class="form-control"
                                [value]="client?.prenom_client">
                        </div>

                        <div class="col-sm-5">
                            <input name="nameExp" id="nameExp" type="text" disabled class="form-control"
                                [value]="client?.nom_client">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-header-tab card-header">
                    <div class="card-header-title">
                        <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
                        Détails d'enlèvement
                    </div>
                </div>
                <div class="card-body">
                    <form>
                        <div class="row form-group">
                            <div class="col-md-4" *ngIf="idSociety==null">
                                <div class="position-relative form-group">
                                    <legend for="society" class="">Client</legend>
                                    <select class="form-control" [(ngModel)]="order.society" name="society"
                                        id="society">
                                        <option [ngValue]="null">Sélectionnez la société</option>
                                        <option [value]="soc?._id" *ngFor="let soc of allSocieties">
                                            {{soc?.name}}
                                        </option>
                                    </select>
                                    <!-- <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="order.society=='null' || order.society==null">
                                        Sélectionnez la société
                                    </mat-error> -->
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <legend for="refFacture" class="">Référence d'enlèvement</legend>
                                    <input name="refFacture" id="refFacture" type="text" class="form-control"
                                        [(ngModel)]="order.ref_facture_cmd"
                                        [class]="(!isSaved && (order.ref_facture_cmd=='' || order.ref_facture_cmd==null)) ? 'input-border-danger' : ''">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <legend for="society" class="">Date d'enlèvement</legend>
                                    <!-- <input name="pickup" id="pickup" type="datetime" class="form-control"
                                    [(ngModel)]="order.pickup"> -->

                                    <div class="row">
                                        <div class="col-12 col-md-8">
                                            <input name="pickupDate" id="pickupDate" type="date" class="form-control"
                                                [(ngModel)]="order.pickupDate"
                                                [class]="(!isSaved && (order.ref_facture_cmd=='' || order.ref_facture_cmd==null)) ? 'input-border-danger' : ''">
                                        </div>
                                        <div class="col-12 col-md-4">
                                            <input name="pickupTime" id="pickupTime" type="time" class="form-control"
                                                [(ngModel)]="order.pickupTime"
                                                [class]="(!isSaved && (!order.hasOwnProperty('pickupTime') || order.pickupTime==null)) ? 'input-border-danger' : ''">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <legend for="society" class="">Priorité de livraison</legend>
                                    <select class="form-control" [(ngModel)]="order.priority" name="priority"
                                        id="priority">
                                        <!-- <option [value]="null">Sélectionnez la poste du contact</option> -->
                                        <option [ngValue]="item" *ngFor="let item of typesPriority">
                                            {{item.label}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-header-tab card-header">
                    <div class="card-header-title">
                        <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
                        Upload des fichiers
                    </div>
                    <div class="btn-actions-pane-right">
                        <!-- <input type="file" (change)="onFileSelected($event)" multiple> -->

                        <input hidden type="file" (change)="onFileSelected($event)" multiple #file>
                        <button type="button" (click)="file.click()" data-toggle="tooltip" title="Example Tooltip"
                            data-placement="bottom" class="btn-shadow mr-3 btn btn-info">
                            <i class="fa fa-star"> Importer fichiers</i>
                        </button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-md-4" *ngFor="let file of allFiles">
                                {{file}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-header-tab card-header">
                    <div class="card-header-title">
                        <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
                        Boxes
                    </div>
                    <div class="btn-actions-pane-right">
                        <!-- <button type="button" (click)="calculatePallets()" class="mr-3 btn btn-danger"><i
                                class="pe-7s-shuffle"></i> Calculer</button> -->
                        <div class="mr-3 dropright btn-group" ngbDropdown placement="left-top">
                            <button class="btn-wide btn btn-danger">Calculer</button>
                            <button type="button" ngbDropdownToggle
                                class="dropdown-toggle-split dropdown-toggle btn btn-danger"><span
                                    class="sr-only">Actions</span></button>
                            <div ngbDropdownMenu class="dropdown-menu">
                                <button *ngFor="let pal of allPalettes; let x = index" type="button"
                                    (click)="calculatePallets(pal)" tabindex="0" class="dropdown-item">
                                    <i class="metismenu-icon pe-7s-news-paper"
                                        style="margin-right: 10px;"></i>{{pal?.name}} -
                                    <b>{{pal?.longueur + "X" + pal?.largeur + "X" +
                                        pal?.hauteur}}</b>
                                </button>
                                <!-- <button type="button"
                                (click)="calculatePallets()"
                                    tabindex="0"
                                    class="dropdown-item">
                                    <i class="metismenu-icon pe-7s-news-paper"
                                        style="margin-right: 10px;"></i>Palette standard
                                </button> -->
                            </div>
                        </div>

                        <button type="button" (click)="addNewBoxGroup()" class="mt-1 btn btn-warning"><i
                                class="pe-7s-plus"></i> Ajouter Box</button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="container">
                        <div [formGroup]="boxform">
                            <div formArrayName="boxes">
                                <div *ngFor="let box of boxes.controls; let i = index">
                                    <div class="position-relative row form-group" [formGroupName]="i"
                                        style="margin-bottom: 25px;">

                                        <div class="col-12" style="margin-bottom: 8px;">
                                            <div class="row form-group">
                                                <div class="col-12 col-md-3">
                                                    <div class="d-flex align-items-center">
                                                        <select class="form-control mr-2"
                                                            placeholder="Référence produit" formControlName="nom_prod"
                                                            (change)="selectBoxProductRef($event, i)">
                                                            <option>Référence produit</option>
                                                            <option *ngFor="let box of client.boxes_products"
                                                                [value]="box?.ref">
                                                                {{ box.ref }}
                                                            </option>
                                                        </select>

                                                        <button type="button" data-toggle="tooltip"
                                                            title="Example Tooltip" (click)="newBoxProduct(i)"
                                                            data-placement="bottom"
                                                            class="btn-shadow mr-3 btn btn-dark">
                                                            <i class="fa fa-plus"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-3">
                                                    <input type="text" class="form-control"
                                                        placeholder="Désignation du produit" formControlName="des_prod">
                                                </div>
                                                <div class="col-12 col-md-5">
                                                    <div class="row">
                                                        <div class="col-12 col-md-4">
                                                            <input type="number" class="form-control"
                                                                placeholder="Nombre de piéce" formControlName="nbrPcs"
                                                                (input)="updateQuantity($event, box, i)">
                                                        </div>

                                                        <div class="col-12 col-md-4">
                                                            <input type="number" class="form-control"
                                                                placeholder="Quantité" formControlName="quantite"
                                                                readonly>
                                                        </div>

                                                        <div class="col-12 col-md-4">
                                                            <input type="number" class="form-control"
                                                                placeholder="Poids du carton" formControlName="weight">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-12 col-md-1">
                                                    <button type="button" (click)="deleteBoxGroup(i)"
                                                        class="mr-2 btn-icon btn-icon-only btn btn-danger float-right"><i
                                                            class="pe-7s-trash btn-icon-wrapper"> </i></button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12">
                                            <div class="row form-group">
                                                <div class="col-12 col-md-3">
                                                    <input type="number" class="form-control" placeholder="Longueur"
                                                        formControlName="length" (input)="setVolumeCmd(i)">
                                                    <span class="cm">m</span>
                                                </div>

                                                <div class="col-12 col-md-3">
                                                    <input type="number" class="form-control" placeholder="Largeur"
                                                        formControlName="width" (input)="setVolumeCmd(i)">
                                                    <span class="cm">m</span>
                                                </div>

                                                <div class="col-12 col-md-3">
                                                    <input type="number" class="form-control" placeholder="Hauteur"
                                                        formControlName="height" (input)="setVolumeCmd(i)">
                                                    <span class="cm">m</span>
                                                </div>

                                                <div class="col-12 col-md-3">
                                                    <input type="number" class="form-control" placeholder="Volume"
                                                        formControlName="volume" readonly>
                                                    <span class="cm">m³</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container" *ngIf="palletsData?.length > 0">
                        <div class="row">
                            <div class="col-12 col-md-4" *ngFor="let pallet of palletsData">
                                <div class="pallet" style="margin: 5px;">
                                    <div class="pallet-info">
                                        <h3>Palette {{pallet?.palletNumber}}:</h3>
                                        <p>Ratio de la palette (L x P x H): {{pallet?.dimensions?.width | number:'0.1'}}
                                            X
                                            {{pallet?.dimensions?.depth | number:'0.1'}} X {{pallet?.dimensions?.height
                                            |
                                            number:'0.1'}} m</p>
                                        <p>Total des boîtes: {{pallet?.countBoxes}}</p>
                                        <div>
                                            <button class="expand-btn">▼</button>
                                            <p>Total des couches: {{pallet?.layers?.length}}</p>
                                            <ul class="product-id-list">
                                                <li *ngFor="let layer of pallet?.layers; let i = index">
                                                    Couche {{layer?.layerNumber}}: (H) {{layer?.layerHeight}}
                                                    <div class="row">
                                                        <div class="col-12 col-md-6" *ngFor="let item of layer?.boxes">
                                                            {{ '(' + item?.count + ') - ' + item?.box?.nom_prod }}</div>
                                                    </div>
                                                </li>
                                                <!-- <li>Layer 1: (11, 12, 13, 14)</li> -->
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row">
                            <mat-error style="color: red;font-weight: bold;"
                                *ngIf="boxform.value.boxes.length == 0 || (boxform.value.boxes.length > 0 && !boxesNotEmpty())">
                                Les produits sont invalid
                            </mat-error><br>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-header-tab card-header">
                    <div class="card-header-title">
                        <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
                        Palettes
                    </div>
                    <div class="btn-actions-pane-right">
                        <button type="button" (click)="addNewPalletGroup()" class="mt-1 btn btn-warning"><i
                                class="pe-7s-plus"></i> Ajouter une Palette</button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="container">
                        <div [formGroup]="palletForm">
                            <div formArrayName="pallets">
                                <div *ngFor="let pallet of pallets.controls; let i = index">
                                    <div class="position-relative row form-group" [formGroupName]="i"
                                        style="margin-bottom: 25px;">

                                        <div class="col-12" style="margin-bottom: 12px;">
                                            <div class="row form-group">
                                                <div class="col-12 col-md-5">
                                                    <div class="custom-radio custom-control"
                                                        *ngFor="let pal of allPalettes; let x = index">
                                                        <input type="radio" [id]="pal._id + '-' + i"
                                                            [name]="'group-' + i" class="custom-control-input"
                                                            [checked]="pallet?.value?.id_pallet === pal._id"
                                                            (click)="setTypePalette(pal._id, i)">
                                                        <label class="custom-control-label" [for]="pal._id + '-' + i">
                                                            {{pal.name}} -
                                                            <b>{{pal?.longueur + "X" + pal?.largeur + "X" +
                                                                pal?.hauteur}}</b>
                                                        </label>
                                                    </div>
                                                    <div class="custom-radio custom-control">
                                                        <input type="radio" [id]="'other-' + i" [name]="'group-' + i"
                                                            class="custom-control-input"
                                                            (click)="setTypePalette('other', i)">
                                                        <label class="custom-control-label" [for]="'other-' + i">
                                                            Autre
                                                        </label>
                                                    </div>

                                                </div>
                                                <!-- <div class="col-12 col-md-2">
                                                    
                                                </div> -->
                                                <div class="col-12 col-md-3">
                                                    <input type="number" class="form-control" placeholder="Quantité"
                                                        formControlName="quantity">
                                                </div>

                                                <!-- <div class="col-12 col-md-3">
                                                    <input type="number" class="form-control" placeholder="Prix unitaire"
                                                        formControlName="prix_unitaire" (input)="setTotalCmd()">
                                                </div> -->

                                                <div class="col-12 col-md-3">
                                                    <input type="number" class="form-control"
                                                        placeholder="Poids palette" formControlName="weight">
                                                </div>

                                                <div class="col-12 col-md-1">
                                                    <button type="button" (click)="deletePalletGroup(i)"
                                                        class="mr-2 btn-icon btn-icon-only btn btn-danger float-right"><i
                                                            class="pe-7s-trash btn-icon-wrapper"> </i></button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12" style="margin-bottom: 30px; overflow: hidden;"
                                            *ngIf="getTypePalette(i)=='other'">
                                            <div class="row form-group">
                                                <div class="col-12 col-md-3">
                                                    <input type="number" class="form-control" placeholder="Longueur"
                                                        formControlName="length" (input)="setVolumePallet(i)">
                                                    <span class="cm">m</span>
                                                </div>

                                                <div class="col-12 col-md-3">
                                                    <input type="number" class="form-control" placeholder="Largeur"
                                                        formControlName="width" (input)="setVolumePallet(i)">
                                                    <span class="cm">m</span>
                                                </div>

                                                <div class="col-12 col-md-3">
                                                    <input type="number" class="form-control" placeholder="Hauteur"
                                                        formControlName="height" (input)="setVolumePallet(i)">
                                                    <span class="cm">m</span>
                                                </div>

                                                <div class="col-12 col-md-3">
                                                    <input type="number" class="form-control" placeholder="Volume"
                                                        formControlName="volume" readonly>
                                                    <span class="cm">m³</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row" style="width: 105%;">
                                            <div class="col-12 col-md-12">
                                                <div class="main-card mb-3 card" style="border: 1px solid red;">
                                                    <div class="card-header-tab card-header">
                                                        <div class="card-header-title">
                                                            <i
                                                                class="header-icon lnr-rocket icon-gradient bg-tempting-azure">
                                                            </i>
                                                            Box {{i+1}} :
                                                        </div>
                                                        <div class="btn-actions-pane-right">
                                                            <button type="button" (click)="addContent(i)"
                                                                class="mt-1 btn btn-warning"><i class="pe-7s-plus"></i>
                                                                Ajouter Box</button>
                                                        </div>
                                                    </div>
                                                    <div class="card-body"
                                                        *ngIf="pallet.get('boxes').controls?.length > 0">
                                                        <div formArrayName="boxes">
                                                            <div
                                                                *ngFor="let content of pallet.get('boxes').controls; let j = index">
                                                                <div class="position-relative row form-group"
                                                                    [formGroupName]="j" style="margin-bottom: 25px;">
                                                                    <div class="col-12" style="margin-bottom: 8px;">
                                                                        <div class="row form-group">

                                                                            <div class="col-12 col-md-8">
                                                                                <div class="row form-group">
                                                                                    <div class="col-12 col-md-3">
                                                                                        <input type="number"
                                                                                            class="form-control"
                                                                                            placeholder="Longueur"
                                                                                            formControlName="length"
                                                                                            (input)="setVolumeBoxPallet(i, j)">
                                                                                        <span class="cm">m</span>
                                                                                    </div>

                                                                                    <div class="col-12 col-md-3">
                                                                                        <input type="number"
                                                                                            class="form-control"
                                                                                            placeholder="Largeur"
                                                                                            formControlName="width"
                                                                                            (input)="setVolumeBoxPallet(i, j)">
                                                                                        <span class="cm">m</span>
                                                                                    </div>

                                                                                    <div class="col-12 col-md-3">
                                                                                        <input type="number"
                                                                                            class="form-control"
                                                                                            placeholder="Hauteur"
                                                                                            formControlName="height"
                                                                                            (input)="setVolumeBoxPallet(i, j)">
                                                                                        <span class="cm">m</span>
                                                                                    </div>

                                                                                    <div class="col-12 col-md-3">
                                                                                        <input type="number"
                                                                                            class="form-control"
                                                                                            placeholder="Volume"
                                                                                            formControlName="volume"
                                                                                            readonly>
                                                                                        <span class="cm">m³</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-12 col-md-4">
                                                                                <div class="row">
                                                                                    <div class="col-12 col-md-5">
                                                                                        <input type="number"
                                                                                            class="form-control"
                                                                                            placeholder="Quantité"
                                                                                            formControlName="quantite">
                                                                                    </div>

                                                                                    <div class="col-12 col-md-5">
                                                                                        <input type="number"
                                                                                            class="form-control"
                                                                                            placeholder="Poids du carton"
                                                                                            formControlName="weight">
                                                                                    </div>

                                                                                    <div class="col-12 col-md-2">
                                                                                        <button type="button"
                                                                                            (click)="deleteBoxPalletGroup(i, j)"
                                                                                            class="mr-2 btn-icon btn-icon-only btn btn-danger float-right"><i
                                                                                                class="pe-7s-trash btn-icon-wrapper">
                                                                                            </i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <!-- <div class="col-12 col-md-3">
                                                                                <select class="form-control"
                                                                                    placeholder="Référence produit"
                                                                                    formControlName="nom_prod"
                                                                                    (change)="selectPalletBoxProductRef($event, i, j)">
                                                                                    <option>Référence produit</option>
                                                                                    <option
                                                                                        *ngFor="let box of client.boxes_products"
                                                                                        [value]="box?.ref">
                                                                                        {{ box.ref }}
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                            <div class="col-12 col-md-3">
                                                                                <input type="text" class="form-control"
                                                                                    placeholder="Désignation du produit"
                                                                                    formControlName="des_prod">
                                                                            </div>
                                                                            <div class="col-12 col-md-5">
                                                                                <div class="row">
                                                                                    <div class="col-12 col-md-4">
                                                                                        <input type="number"
                                                                                            class="form-control"
                                                                                            placeholder="Nombre de piéce"
                                                                                            formControlName="nbrPcs"
                                                                                            (input)="updatePalletQuantity($event, content, i, j)">
                                                                                    </div>

                                                                                    <div class="col-12 col-md-4">
                                                                                        <input type="number"
                                                                                            class="form-control"
                                                                                            placeholder="Quantité"
                                                                                            formControlName="quantite"
                                                                                            readonly>
                                                                                    </div>

                                                                                    <div class="col-12 col-md-4">
                                                                                        <input type="number"
                                                                                            class="form-control"
                                                                                            placeholder="Poids du carton"
                                                                                            formControlName="weight">
                                                                                    </div>
                                                                                </div>
                                                                            </div> -->


                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <!-- <input formControlName="name" placeholder="Content Name">
                                                <input formControlName="quantity" placeholder="Content Quantity"> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="row">
                            <mat-error style="color: red;font-weight: bold;"
                                *ngIf="!palletsNotEmpty(palletForm.value.pallets)">
                                Les produits sont invalid
                            </mat-error><br>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="tab-content" id="tab-content">
                    <div class="main-card mb-12 card">
                        <div class="card-body">
                            <div class="row form-group">
                                <div class="col-md-12">
                                    <legend for="firstName" class="">Notes et Commentaires</legend>
                                    <textarea name="text" id="exampleText" class="form-control" style="height: 132px;"
                                        [(ngModel)]="order.comment"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="tab-content" id="tab-content">
                    <div class="main-card mb-12 card">
                        <div class="d-block text-center card-footer">
                            <div *ngIf="isClicked; else elseIsUpdated">
                                <div class="spinner-container blue-spinner">
                                    <mat-progress-spinner mode="indeterminate" [diameter]="40"
                                        [strokeWidth]="5"></mat-progress-spinner>
                                </div>
                            </div>
                            <ng-template #elseIsUpdated>
                                <button type="submit" (click)="updateCommande()"
                                    class="btn btn-success btn-lg btn-block">
                                    Modifier l'enlèvement</button>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-template>