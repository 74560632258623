import { Component, OnInit } from '@angular/core';
import { TransportorsService } from 'src/app/services/transportors/transportors.service';

@Component({
  selector: 'app-commercial-transportors',
  templateUrl: './commercial-transportors.component.html',
  styleUrls: ['./commercial-transportors.component.css']
})
export class CommercialTransportorsComponent implements OnInit {

  allComm:any = []

  constructor(private transServices: TransportorsService) { }

  ngOnInit(): void {
    this.allComms()
  }

  async allComms() {
    await this.transServices.allCommercials().then((res: any) => {
      if (res) {
        // console.log("resssssssss : ")
        // console.log(res)
        this.allComm = res;
      }
    });
  }

}
