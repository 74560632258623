<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-box2 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Détail
                <div class="page-title-subheading">Détail du sac.
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                class="btn-shadow mr-3 btn btn-danger" [routerLink]="['/bon-achat-sac']"
                [queryParams]="{ sac: sac._id }">
                <i class="fa fa-star"> Bon d'achat</i>
            </button>
        </div>
    </div>
</div>
<div class="tab-content">
    <div class="main-card mb-12 card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-7">
                    <div class="text space">
                        <h4><strong>Ref Sac : {{sac.ref_sac}}</strong></h4>
                    </div>
                </div>
                <div class="col-md-5 text-md-right" id="qrcode" #qrcode>
                    <div class="text-center space">
                        <h2><strong>{{sac.destination_sac}}</strong></h2>
                        <qrcode print [width]="400" usesvg="true" [elementType]="'svg'"
                            [qrdata]="sac._id" [margin]=0>
                        </qrcode>
                    </div>
                </div>
                <div class="col-md-12 text-md-right">
                    <button (click)="generatePDF()" class="btn btn-warning btn-icon icon-left"><i
                            class="fas fa-print"></i>
                        Imprimer
                    </button>
                </div>

            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="main-card mb-3 card">
                        <div class="card-header" *ngIf="sac?.commandes?.length > 0">List des commandes
                            <!-- <div class="btn-actions-pane-right">
                                <div role="group" class="btn-group-sm btn-group">
                                    <button class="active btn btn-focus">Last Week</button>
                                    <button class="btn btn-focus">All Month</button>
                                </div>
                            </div> -->
                        </div>

                        <div class="table-responsive">
                            <div class="budget-price justify-content-center"
                                *ngIf="sac?.commandes?.length === 0">
                                <h5 class="text-center">Aucune commande affecté dans le sac</h5>
                            </div>
                            <table
                                class="align-middle mb-0 table table-borderless table-striped table-hover"
                                *ngIf="sac?.commandes?.length > 0">
                                <thead>
                                    <tr>
                                        <th class="text-center">#</th>
                                        <th>Fournisseur</th>
                                        <th class="text-left">Référence</th>
                                        <th class="text-center">Date Création</th>
                                        <th class="text-center">Nom Client & Téléphone</th>
                                        <th class="text-center">Ville</th>
                                        <th class="text-center">Total Commande</th>
                                        <th class="text-center">Status</th>
                                        <th class="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let cmd of sac.commandes, let index = index">
                                        <td class="text-center text-muted">#{{index+1}}</td>
                                        <td>
                                            <div class="widget-content p-0">
                                                <div class="widget-content-wrapper">
                                                    <div class="widget-content-left mr-3">
                                                        <div class="widget-content-left">
                                                            <ngx-avatar class="rounded-circle" name="{{cmd.client_cmd.prenom_client}}
                                                            {{cmd.client_cmd.nom_client}}">
                                                            </ngx-avatar>
                                                        </div>
                                                    </div>
                                                    <div class="widget-content-left flex2">
                                                        <div class="widget-heading">
                                                            {{cmd.client_cmd.providerName}}
                                                        </div>
                                                        <div class="widget-subheading opacity-7">
                                                            {{cmd.client_cmd.prenom_client}}
                                                            {{cmd.client_cmd.nom_client}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text">{{cmd.ref_facture_cmd}}</td>
                                        <td class="text-center">{{cmd.date_creation_cmd | date:'d MMM
                                            y'}}</td>
                                        <td class="text-center">
                                            <div class="widget-content p-0">
                                                <div class="widget-content-wrapper">
                                                    <div class="widget-content-left flex2">
                                                        <div class="widget-heading">
                                                            {{cmd.user_destination.nom_destination}}
                                                        </div>
                                                        <div class="widget-subheading opacity-7">
                                                            {{cmd.user_destination.numtel_destination}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            {{cmd.user_destination.adresse_destination}}
                                            <strong>{{cmd.destination_cmd}}</strong>
                                        </td>
                                        <td class="text-center">{{cmd.total_ca_cmd+cmd.frais_livraison |
                                            number:'0.3'}} DT</td>
                                        <td class="text-center">
                                            <div class="badge badge-{{statusCmds[cmd.etat_cmd].color}}">
                                                {{statusCmds[cmd.etat_cmd].label}}</div>
                                        </td>
                                        <td class="text-center">
                                            <button [routerLink]="['/detail-cmd']"
                                                [queryParams]="{ cmd: cmd._id }" type="button"
                                                id="PopoverCustomT-1"
                                                class="btn btn-primary btn-sm">Details</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>