<div class="limiter">
  <div class="container-login100" style="background-image: url('assets/images/bg-01.jpg');">
    <div class="wrap-login100">
      <form class="login100-form validate-form" action="" method="POST" id="login-form" onsubmit="return false;"
        (onsubmit)="authentification(username,password);">
        <span class="login100-form-logo">
          <i class="zmdi zmdi-landscape"></i>
        </span>

        <span class="login100-form-title p-b-34 p-t-27">
          Log in
        </span>

        <div class="wrap-input100 validate-input" data-validate="Enter username">
          <input class="input-login input100" type="text" name="username" placeholder="Username" #username ngModel>
          <span class="focus-input100" data-placeholder="&#xf207;"></span>
        </div>
        <div class="msg-error p-t-10">
          <label class="txt1 msg-error-color" *ngIf="msgErrorUsername!=null">
            {{msgErrorUsername}}
          </label>
        </div>

        <div class="wrap-input100 validate-input" data-validate="Enter password">
          <input class="input-login input100" type="password" name="pass" placeholder="Password" #password ngModel>
          <span class="focus-input100" data-placeholder="&#xf191;"></span>
        </div>
        <div class="msg-error p-t-10">
          <label class="txt1 msg-error-color" *ngIf="msgErrorPassword!=null">
            {{msgErrorPassword}}
          </label>
        </div>

        <div class="contact100-form-checkbox">
          <input class="input-checkbox100" id="ckb1" type="checkbox" name="remember-me">
          <label class="label-checkbox100" for="ckb1">
            Remember me
          </label>
        </div>

        <div class="container-login100-form-btn">
          <button type="submit" (click)="authentification(username,password)" class="button-login login100-form-btn">
            Login
          </button>
        </div>

        <div class="msg-error text-center p-t-20">
          <label class="txt1 msg-error-color " *ngIf="msgErrorLogin!=null">
            {{msgErrorLogin}}
          </label>
        </div>

        <div class="text-center p-t-90">
          <div class="txt1">
            Forgot Password?
          </div>
        </div>
      </form>
    </div>
  </div>
</div>


<div id="dropDownSelect1"></div>