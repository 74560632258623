<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <!-- <div class="page-title-icon">
                <i class="pe-7s-users icon-gradient bg-mean-fruit">
                </i>
            </div> -->
            <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                    <div class="widget-content-left mr-3">
                        <div class="widget-content-left">
                            <img width="60" class="rounded-circle" [src]="trip.userLouage?.photoProfile"
                                alt="">
                            <!-- <ngx-avatar class="rounded-circle" name="{{element.prenom_user}}
                            {{element.nom_user}}">
                            </ngx-avatar> -->
                        </div>
                    </div>
                    <div class="widget-content-left flex2">
                        <div class="widget-heading">{{trip.userLouage?.nom_prenom_tracking}}
                            {{trip.userLouage?.lastName}}</div>
                        <div class="widget-subheading opacity-7">
                            {{trip.userLouage?.numTel_tracking}}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="page-title-actions">
            <div class="d-inline-block dropdown">
                <div>
                    <form class="form-inline" action="" method="post" onsubmit="return false;"
                    (onsubmit)="getClient(keyword);">
                        <div class="mb-2 mr-sm-2 mb-sm-0 position-relative form-group">
                            <input name="keyword" id="keyword" placeholder="Client ?"
                                type="keyword" class="form-control"  #keyword ngModel>
                        </div>
                        <button type="submit" class="btn-shadow mr-3 btn btn-dark"
                            (click)="getClient(keyword)">
                            <i class="pe-7s-search"></i>
                        </button>
                    </form>
                </div>
            </div>
        </div> -->
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">
                <div class="col-5">
                    Date du voyage : {{trip.dateTrip | date:'d MMM y HH:mm'}}
                </div>
                <div class="col-4">
                    <h3><strong>{{trip.startingLouage}}</strong> ->
                        <strong>{{trip.arrivedLouage}}</strong></h3>
                </div>
                <div class="btn-actions-pane-right">
                    <div class="badge badge-warning">{{trip.status}}</div>
                    <!-- <div role="group" class="btn-group-sm btn-group">
                        <button class="active btn btn-focus">Last Week</button>
                        <button class="btn btn-focus">All Month</button>
                    </div> -->
                </div>
            </div>
            <!-- <div class="d-block text-center card-footer">
                <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                        class="pe-7s-trash btn-icon-wrapper"> </i></button>
                <button class="btn-wide btn btn-success">Save</button>
            </div> -->
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Active Users
                <!-- <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button class="active btn btn-focus">Last Week</button>
                        <button class="btn btn-focus">All Month</button>
                    </div>
                </div> -->
            </div>
            <div class="table-responsive">
                <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th>Voyageur</th>
                            <th class="text-center">Numéro <br> de téléphone</th>
                            <th class="text-center">Date <br> réservation</th>
                            <th class="text-center">Nombre <br> réservation</th>
                            <th class="text-center">Status</th>
                            <!-- <th class="text-center">Actions</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let book of trip.infoBooking, let index = index">
                            <td class="text-center text-muted">#{{index+1}}</td>
                            <td>
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left mr-3">
                                            <!-- <img width="42" class="rounded-circle"
                                                src="assets/images/avatars/9.jpg" alt=""> -->
                                            <ngx-avatar class="rounded-circle"
                                                name="{{book.travelerUser.prenom_user}} {{book.travelerUser.nom_user}}">
                                            </ngx-avatar>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">
                                                {{book.travelerUser.prenom_user}}
                                                {{book.travelerUser.nom_user}}
                                            </div>
                                            <div class="widget-subheading">
                                                {{book.travelerUser.email_user}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center"><strong>{{book.travelerUser.tel_user}}</strong></td>
                            <td class="text-center">{{book.date_action | date:'d MMM y HH:mm'}}</td>
                            <td class="text-center">
                                {{book.number_person}}
                            </td>
                            <td class="text-center">
                                <div class="badge badge-warning">{{book.etat_booking}}</div>
                            </td>
                            <!-- <td class="text-center">
                                <button [routerLink]="['/one-trip']" [queryParams]="{ trip: element._id }" type="button" id="PopoverCustomT-1"
                                    class="btn btn-primary btn-sm">Details</button>

                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div class="d-block text-center card-footer">
                <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                        class="pe-7s-trash btn-icon-wrapper"> </i></button>
                <button class="btn-wide btn btn-success">Save</button>
            </div> -->
        </div>
    </div>
</div>