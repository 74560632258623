import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocietiesService {

  constructor(private http: HttpClient,) { }

  async allBusinessAccounts() {
    return await new Promise((slv) => {
      this.http.post(environment.apiAllBusinessAccounts, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  addBusinessAccount(idSociety) {
    return new Promise((slv) => {
      this.http.post(environment.apiAddBusinessAccount, {
        "idSociety": idSociety
      }).subscribe(
        (res: any) => {
          //console.log(JSON.stringify(data));
          if (res) {
            if (res.response) {
              slv(res.response);
            } else {
              slv(res);
            }
          }
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  subscribe(society) {
    return new Promise((slv) => {
      this.http.post(environment.apiAddSociety, society)
        .subscribe(
          (data: any) => {
            console.log("data service")
            console.log(data)
            // if (data && data.response) {
            slv(data);
            // } else slv(false);
          },
          (error) => {
            console.log("society error")
            console.log(error)
            slv(null);
          }
        );
    });
  }

  allSocieties() {
    return new Promise((slv) => {
      this.http.post(environment.apiAllSocieties, {
      }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          //console.log("society error")
          //console.log(error)
          slv(false);
        }
      );
    });
  }

  deleteSociety(idSociety) {
    return new Promise((slv) => {
      this.http.post(environment.apiDeleteSociety, {
        idSociety
      }).subscribe(
        (res: any) => {
          slv(res);
        },
        (error) => {
          slv(null);
        }
      );
    });
  }
}
