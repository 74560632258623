import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';
import { UsersTrackingService } from 'src/app/services/users-tracking/users-tracking.service';

@Component({
  selector: 'app-detail-virements',
  templateUrl: './detail-virements.component.html',
  styleUrls: ['./detail-virements.component.css']
})
export class DetailVirementsComponent implements OnInit {

  frais: any = {}

  client: any = {}
  statusCmds: any = []
  cmdsNotPaied: any = []
  cmdsNotReturned: any = []

  // listCmdsLivredReturned: any = []

  totalCmdsLivred: any = 0
  fraisLivred: any = 0
  fraisLivredExterned: any = 0
  fraisReturned: any = 0

  nbrCmdsExterne: any = 0
  nbrCmdsInterne: any = 0

  idClient: string = ""

  // tagCmds: string = "LIVREE";
  listCmds: any = []

  // userId: String = null
  clientsList: any = []

  constructor(private cmdsService: CommandesService,
    private clientService: ClientsService,
    private route: ActivatedRoute,
    private trakingServices: UsersTrackingService) { }

  async ngOnInit() {
    this.cmdsService.getLabeledStatus().then(
      (lst) => {
        this.statusCmds = lst
      }
    )
    this.listClients()
    // this.route.queryParams.subscribe(async (params) => {
    //   this.idClient = params.client;
    //   await this.getFraisClient()
    //   // this.getClient()
    //   // this.listCmdsClient()
    //   await this.detailsCmdsNotPaiedAndNotReturned()
    // });
    // await this.getClient("")
  }
  async getDetailVirrement() {
    if (this.idClient != null) {
      await this.getFraisClient()
      // this.getClient()
      // this.listCmdsClient()
      await this.detailsCmdsNotPaiedAndNotReturned()
    } else {
      this.listCmds = []
      this.cmdsNotReturned = []
      this.frais = {}
      this.totalCmdsLivred = 0
      this.fraisLivred = 0
      this.fraisLivredExterned = 0
      this.fraisReturned = 0
      this.nbrCmdsExterne = 0
      this.nbrCmdsInterne = 0
    }
  }

  goToNewTab(idCmd, route) {
    this.cmdsService.goToNewTab(idCmd, route)
  }
  
  selectClient(event: any) {
    // console.log(event.target.value)
    if (event.target.value == "Selectionnez le client") {
      this.idClient = null;
    } else {
      this.idClient = event.target.value;
    }
  }
  async listClients() {
    await this.clientService.getClients().then((res: any) => {
      // console.log(res)
      if (res) {
        this.clientsList = res;
        // this.clientsList.splice(0, 0, "Selectionnez le client");
      }
    });
  }
  detailsCmdsNotPaiedAndNotReturned() {
    this.clientService.allCmdsNotPaiedNotReturned(this.idClient).then(async (res: any) => {
      // console.log("res Not P and Retu")
      // console.log(res)
      if (res) {
        this.cmdsNotPaied = res.cmdsNotPaied
        this.cmdsNotReturned = res.cmdsNotReturned
        this.listCmds = this.cmdsNotPaied.concat(this.cmdsNotReturned)
        // this.listCmdsLivredReturned = this.cmdsNotPaied.concat(this.cmdsNotReturned)
        this.totalCmdsLivred = await this.totalCmdsByStatus(this.cmdsNotPaied)

        this.nbrCmdsInterne = await this.cmdsNotPaied.filter(cmd => (cmd.destination_cmd == "Tunis"
          || cmd.destination_cmd == "Ariana"
          || cmd.destination_cmd == "Ben Arous" || cmd.destination_cmd == "Manouba")).length;
        this.nbrCmdsExterne = await this.cmdsNotPaied.filter(cmd => !(cmd.destination_cmd == "Tunis"
          || cmd.destination_cmd == "Ariana"
          || cmd.destination_cmd == "Ben Arous" || cmd.destination_cmd == "Manouba")).length;

        this.fraisLivred = this.nbrCmdsInterne * this.frais.frais_interne
        this.fraisLivredExterned = this.nbrCmdsExterne * this.frais.frais_externe
        this.fraisReturned = this.cmdsNotReturned.length * this.frais.frais_retour
      }
    });
  }

  async totalCmdsByStatus(array) {
    let total = 0
    array.forEach(element => {
      total += element.total_ca_cmd + element.frais_livraison
    });

    return total
  }

  async getFraisClient() {
    await this.clientService.getFraisClient(this.idClient)
      .then((res: any) => {
        // console.log("resssssssss :")
        // console.log(res)
        if (res) {
          this.frais = res
          //location.href = '/clients';
          //this.sacsList = res;
        }
      });
  }


  // async listCmdsLivred(tagCmds) {
  //   this.tagCmds = tagCmds
  //   this.listCmds = this.cmdsNotPaied //await this.clientService.allCmdsNotPaied(this.client._id)
  //   // this.cmdsDelivered = this.listCmds.length
  // }
  // async listCmdsReturned(tagCmds) {
  //   this.tagCmds = tagCmds
  //   this.listCmds = this.cmdsNotReturned //await this.clientService.allCmdsNotReturned(this.client._id)
  // }

  // async validateReturnedCmd() {
  //   if (confirm('Etes vous sûre?'))
  //     await this.listCmds.forEach(async (cmd) => {
  //       await this.cmdsService.validateReturnedCmdsByTransporter(cmd.qr_code, "returned_recu")
  //     });
  //   await this.detailsCmdsNotPaiedAndNotReturned()
  // }

  // async validateLivredCmds() {
  //   if (confirm('Etes vous sûre?')) {
  //     // await this.listCmds.forEach(async (element) => {
  //     //   await this.cmdsService.updateCmdStatus(element.qr_code, "paied")
  //     // })
  //     await this.addAccountClient()
  //   }

  //   await this.detailsCmdsNotPaiedAndNotReturned()
  // }

  // async addAccountClient() {
  //   let accountExp = this.totalCmdsLivred - (this.fraisLivred + this.fraisLivredExterned + this.fraisReturned)
  //   let accoutLogistic = this.fraisLivred + this.fraisLivredExterned + this.fraisReturned
  //   let cmds = this.listCmds.map(cmd => {return cmd._id;});
  //   console.log("cmdsssss : ")
  //   console.log(cmds)
  //   this.clientService.addAccountClient(this.idClient,
  //     this.totalCmdsLivred, accountExp, accoutLogistic, cmds).then(async (res: any) => {
  //       console.log("res service : ")
  //       console.log(res)
  //       if (res) {
  //         await this.detailsCmdsNotPaiedAndNotReturned()
  //       }
  //     });
  // }

}
