<div id="body">
    <header class="clearfix">
        <div id="logo">
            <img src="../../../../assets/images/logo-facture.png">
        </div>
        <div id="company">
            <h2 class="name">Bon de paiement livreur</h2>
            <div><b>Date : </b>{{nowDate | date:'d MMM y'}}</div>
            <div><b>Devise : €</b></div>
            <!-- <div><a href="mailto:company@example.com">company@example.com</a></div> -->
        </div>

    </header>
    <main>
        <div id="details" class="clearfix">
            <div id="client">
                <h2 class="name">WASSALNI EXPRESS</h2>
                <table style="margin-bottom: 2px;">
                    <tbody>
                        <tr>
                            <td class="header header-bold">Adresse : </td>
                            <td class="header">28, Rue de l'Energie Atomique, Charguia 1<br>
                            1000, Tunis, Tunisia</td>
                        </tr>
                        <tr>
                            <td class="header header-bold">Tél : </td>
                            <td class="header">21 733 418</td>
                        </tr>
                        <tr>
                            <td class="header header-bold">Mail : </td>
                            <td class="header">wassali.express@gmail.com</td>
                        </tr>
                        <tr>
                            <td class="header header-bold">M.F. : </td>
                            <td class="header">1738199M/A/M/000</td>
                        </tr>
                    </tbody>
                </table>
                <!-- <div class="to">INVOICE TO:</div>
                <div class="address">796 Silver Harbour, TX 79273, US</div>
                <div class="email"><a href="mailto:john@example.com">john@example.com</a></div> -->
            </div>
            <div id="invoice">
                <table style="margin-bottom: 2px;">
                    <tbody>
                        <tr>
                            <td colspan="2" style="border: none;"></td>
                        </tr>
                        <tr>
                            <td class="header header-bold">A : </td>
                            <td class="header">{{userTracking?.nom_prenom_tracking}}</td>
                        </tr>
                        <tr>
                            <td class="header header-bold">Matricule : </td>
                            <td class="header">Matricule de livreur</td>
                        </tr>
                        <tr>
                            <td class="header header-bold">Régime : </td>
                            <td class="header">Indépendant / salarié</td>
                        </tr>
                        <tr>
                            <td class="header header-bold">Zone : </td>
                            <td class="header">{{userTracking?.cities}}</td>
                        </tr>
                    </tbody>
                </table>
                <!-- <h1>INVOICE 3-2-1</h1>
                <div class="date">Date of Invoice: 01/06/2014</div>
                <div class="date">Due Date: 30/06/2014</div> -->
            </div>
        </div>
        <table border="0" cellspacing="0" cellpadding="0">
            <thead>
                <tr style="border: 1px solid;">
                    <th class="title-table">Référence</th>
                    <th class="title-table">Description</th>
                    <th class="title-table">Date création</th>
                    <th class="title-table">Nom client</th>
                    <th class="title-table">Total<br>commande</th>
                    <th class="title-table">Frais <br>de livraison</th>
                    <th class="title-table">Frais<br>livreur</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let cmd of listCmds, let index = index">
                    <td class="ref">{{cmd?.ref_facture_cmd}}</td>
                    <td class="desc">
                        <h3 *ngFor="let prd of cmd.products">{{prd.nom_prod}}</h3>
                        <div style="float: right;" *ngIf="cmd.etat_cmd== 'returned'"><b>(Retour)</b></div>
                    </td>
                    <td class="unit"> {{cmd?.date_creation_cmd | date:'d MMM y'}}<br>
                        <b>{{cmd?.date_creation_cmd | date:'HH:mm'}}</b></td>
                    <td class="ref"><b>{{cmd?.client_cmd?.providerName}}</b></td>
                    <td class="qty">{{cmd?.total_ca_cmd + cmd.frais_livraison |
                        number:'0.3'}} DT</td>
                    <td class="total">{{cmd?.client_cmd?.fraisDelivery?.frais_interne |
                        number:'0.3'}} DT</td>
                    <td class="total">{{userTracking.frais_livraison}} DT</td>
                </tr>
                <!-- <tr>
                    <td class="ref">W02</td>
                    <td class="desc">
                        <h3>Retour</h3>
                    </td>
                    <td class="unit">2.000</td>
                    <td class="unit">5.000</td>
                    <td class="qty"></td>
                    <td class="total">0.0</td>
                    <td class="total">19%</td>
                </tr>
                <tr>
                    <td class="ref">W03</td>
                    <td class="desc">
                        <h3>Échange</h3>
                    </td>
                    <td class="unit">2.000</td>
                    <td class="unit">5.000</td>
                    <td class="qty"></td>
                    <td class="total">0.0</td>
                    <td class="total">19%</td>
                </tr> -->

                
                <tr>
                    <td colspan="6" style="border: none; height: 30px;"></td>
                </tr>
                <tr class="tfooter">
                    <td colspan="2" style="border: none;"></td>
                    <td colspan="1" class="center-td" style="font-weight: bold;">TOTAL</td>
                    <td colspan="2" class="center-td" style="font-weight: bold;">Nombre retour</td>
                    <td>{{cmdsReturned.length}}</td>
                </tr>
                <tr>
                    <td colspan="6" style="border: none; height: 20px;"></td>
                </tr>
                <tr class="tfooter">
                    <td colspan="2" style="border: none;"></td>
                    <td rowspan="3" colspan="1" class="center-td" style="font-weight: bold;">TOTAL</td>
                    <td colspan="2" class="center-td" style="font-weight: bold;">Total commande</td>
                    <td>{{totalCmdsLivred|
                        number:'0.3'}}</td>
                </tr>
                <tr class="tfooter">
                    <td colspan="3" style="border: none;"></td>
                    <td colspan="2" class="center-td" style="font-weight: bold;">Frais de livraison</td>
                    <td>{{totalCmdsLivred - fraisLivred|
                        number:'0.3'}}</td>
                </tr>
                <tr class="tfooter">
                    <td colspan="3" style="border: none;"></td>
                    <td colspan="2" class="center-td" style="font-weight: bold;">Frais livreur</td>
                    <td>{{fraisLivred|
                        number:'0.3'}}</td>
                </tr>
            </tbody>
            <!-- <tfoot>
                <tr>
                    <td colspan="6" style="border: none;"></td>
                </tr>
                <tr>
                    <td colspan="2" style="border: none;"></td>
                    <td colspan="1" class="center-td" style="font-weight: bold;">TOTAL</td>
                    <td colspan="2" class="center-td" style="font-weight: bold;">Nombre retour</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td colspan="6" style="border: none;"></td>
                </tr>
                <tr>
                    <td colspan="6" style="border: none;"></td>
                </tr>
                <tr>
                    <td colspan="2" style="border: none;"></td>
                    <td rowspan="3" colspan="1" class="center-td" style="font-weight: bold;">TOTAL</td>
                    <td colspan="2" class="center-td" style="font-weight: bold;">Total commande</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td colspan="3" style="border: none;"></td>
                    <td colspan="2" class="center-td" style="font-weight: bold;">Frais de livraison</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td colspan="3" style="border: none;"></td>
                    <td colspan="2" class="center-td" style="font-weight: bold;">Frais livreur</td>
                    <td>-</td>
                </tr>
            </tfoot> -->
        </table>
        <!-- <div id="thanks"><b>Réglement : </b>en espéces</div>
        <div id="notices">
            <div><b>Arab Tunisian Bank : </b>(Adresse de la banque)</div>
            <div class="notice"><b>RIB : </b> 123456789</div>
        </div> -->

        <table border="0" cellspacing="0" cellpadding="0">
            <tbody>
                <tr>
                    <td class="signature">Signature de livreur</td>
                    <td class="signature">Service financier</td>
                </tr>
            </tbody>
        </table>
    </main>
    <!-- <footer>
        <table border="0" cellspacing="0" cellpadding="0">
            <tbody>
                <tr>
                    <td class="signature">Signature de livreur</td>
                    <td class="signature">Service financier</td>
                </tr>
            </tbody>
        </table> -->
        <!-- Invoice was created on a computer and is valid without the signature and seal. -->
    <!-- </footer> -->
</div>