import { Component, OnInit, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { SettingsService } from 'src/app/services/settings/settings.service';

@Component({
  selector: 'app-add-provider-products',
  templateUrl: './add-provider-products.component.html',
  styleUrls: ['./add-provider-products.component.css']
})
export class AddProviderProductsComponent implements OnInit {

  @Output() result = new EventEmitter<any>();
  @Input() provider: any;

  @ViewChild('autoInput') input: any;

  boxes: any[] = []
  selectedBoxes: any[] = []

  constructor(private activeModal: NgbActiveModal,
    private clientsService: ClientsService,
    private service: SettingsService,) { }

  ngOnInit(): void {
    // this.selectedBoxes.push(...this.provider.boxes_products)
    // console.log(this.provider)
  }

  _searchBoxes(keyword: string) {
    let promise = new Promise<any>(async (resolve, reject) => {
      await this.service.searchBoxes(keyword).then((res: any) => {
        // console.log(res)
        if (res) {
          resolve(res)
        }
      }).catch((e: any) => {
        resolve([])
      });
    });
    return promise
  }
  async onChange() {
    // console.log(this.input.nativeElement.value)
    if (this.input.nativeElement.value !== "") {
      this.boxes = await this._searchBoxes(this.input.nativeElement.value)
      
      // console.log(this.boxes)

      // this.places = await this._fetchAllPlace(this.input.nativeElement.value)
      // delete this.data.idDriver

      // this._fetchRoomDrivers()
    }
  }
  onSelectionChange(event: any) {
    const selectedValue = event.option.value;
    // console.log(selectedValue)

    this.input.nativeElement.value = "" //selectedValue.ref + " - " + selectedValue.t_seak
    this.boxes = []

    this.selectedBoxes.push(selectedValue)
  }

  addBoxesToProvider() {
    this.service.addBoxesProductsToProvider({
      idProvider: this.provider?._id,
      boxes: this.selectedBoxes
    }).then((res: any) => {
      // console.log("addBoxesToProvider : ");
      // console.log(res);
      if (res) {
        this.result.emit(true);
        this.activeModal.close();
      } else {
        this.closeModal()
      }
    });
  }

  deleteSelectedBoxProduct(index) {
    this.selectedBoxes.splice(index, 1)
  }
  deleteBoxProduct(idBox: any) {
    this.service.deleteBoxeProductsFromProvider({
      idProvider: this.provider?._id,
      idBox: idBox
    }).then((res: any) => {
      // console.log("addBoxesToProvider : ");
      // console.log(res);
      if (res && res.response) {
        this.provider = res?.data
        // this.result.emit(true);
        // this.activeModal.close();
      } else {
        // this.closeModal()
        alert(res?.msg)
      }
    });
  }

  closeModal() {
    this.result.emit(null);
    this.activeModal.dismiss();
  }

}
