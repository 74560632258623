<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-car icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Villes
                <div class="page-title-subheading">This is an example dashboard created using build-in
                    elements and components.
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <div class="d-inline-block dropdown">
                <div class="search-wrapper">
                    <div class="input-holder">
                        <select class="search-input" placeholder="Type to search..."
                            (change)="selectChangeVille($event)">
                            <option [value]="ville" *ngFor="let ville of villes">
                                {{ville}}</option>
                        </select>
                        <button class="search-icon"><span></span></button>
                    </div>
                    <button class="close"></button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-4" *ngFor="let sacsList of sacsListByVille">
        <div class="card mb-3 widget-chart text-white card-border"
            [style.background-color]="sacsList?.sacs[0]?.color_sac">
            <div class="icon-wrapper rounded-circle">
                <div class="icon-wrapper-bg bg-white opacity-1"></div><i class="pe-7s-car text-white"></i>
            </div>
            <div class="widget-numbers text-warning">{{sacsList.ville?.city_name}}</div>
            <div class="widget-heading">Transporteur: {{sacsList.ville?.responsable_city?.nom_prenom_tracking}} </div>
            <div class="widget-subheading space">Total commandes:
                <span class="badge badge-pill badge-light">{{getCmdsBySacs(sacsList?.sacs)}}</span>
                <a class="mb-2 mr-2 btn btn-warning space-right" [routerLink]="['/bon-achat']"
                    [queryParams]="{ ville: sacsList.ville.city_name }">
                    <i class="pe-7s-print"> imprimer</i>
                </a>
            </div>
            <div class="widget-subheading space">Total sacs: <span
                    class="badge badge-pill badge-light">{{sacsList?.sacs?.length}}</span> </div>
            <div class="widget-description text-success">
                <!-- <svg aria-hidden="true" focusable="false"
                                    data-prefix="fas" data-icon="angle-up" class="svg-inline--fa fa-angle-up fa-w-10 "
                                    role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                    <path fill="currentColor"
                                        d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z">
                                    </path>
                                </svg>
                                <span class="pl-1">175.5%</span> -->

                <button class="mb-2 mr-2 btn btn-info">Préte<span class="badge badge-pill badge-light">6</span></button>
                <button class="mb-2 mr-2 btn btn-secondary">En cours<span
                        class="badge badge-pill badge-light">6</span></button>
                <button class="mb-2 mr-2 btn btn-success">Livré<span
                        class="badge badge-pill badge-light">6</span></button>
                <button class="mb-2 mr-2 btn btn-danger">Retour<span
                        class="badge badge-pill badge-light">6</span></button>
            </div>
        </div>
    </div>
    <!-- <div class="col-md-4">
                        <div class="card mb-3 bg-premium-dark widget-chart text-white card-border">
                            <div class="icon-wrapper rounded-circle">
                                <div class="icon-wrapper-bg bg-white opacity-1"></div><i
                                    class="pe-7s-car text-white"></i>
                            </div>
                            <div class="widget-numbers text-warning">Ariana</div>
                            <div class="widget-heading">Transporteur: Houssem </div>
                            <div class="widget-subheading space">Total commandes: <span
                                    class="badge badge-pill badge-light">65</span> </div>
                            <div class="widget-subheading space">Total sacs: <span
                                    class="badge badge-pill badge-light">25</span> </div>
                            <div class="widget-description text-success">
                                
                                <button class="mb-2 mr-2 btn btn-info">Préte
                                    <span class="badge badge-pill badge-light">6</span></button>
                                <button class="mb-2 mr-2 btn btn-secondary">En cours<span
                                        class="badge badge-pill badge-light">6</span></button>
                                <button class="mb-2 mr-2 btn btn-success">Livré<span
                                        class="badge badge-pill badge-light">6</span></button>
                                <button class="mb-2 mr-2 btn btn-danger">Retour<span
                                        class="badge badge-pill badge-light">6</span></button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card mb-3 bg-arielle-smile widget-chart text-white card-border">
                            <div class="icon-wrapper rounded-circle">
                                <div class="icon-wrapper-bg bg-white opacity-1"></div><i
                                    class="pe-7s-car text-white"></i>
                            </div>
                            <div class="widget-numbers text-warning">Ben Arous</div>
                            <div class="widget-heading">Transporteur: Nader </div>
                            <div class="widget-subheading space">Total commandes: <span
                                    class="badge badge-pill badge-light">45</span> </div>
                            <div class="widget-subheading space">Total sacs: <span
                                    class="badge badge-pill badge-light">25</span> </div>
                            <div class="widget-description text-success">
                                
                                <button class="mb-2 mr-2 btn btn-info">Préte<span
                                        class="badge badge-pill badge-light">6</span></button>
                                <button class="mb-2 mr-2 btn btn-secondary">En cours<span
                                        class="badge badge-pill badge-light">6</span></button>
                                <button class="mb-2 mr-2 btn btn-success">Livré<span
                                        class="badge badge-pill badge-light">6</span></button>
                                <button class="mb-2 mr-2 btn btn-danger">Retour<span
                                        class="badge badge-pill badge-light">6</span></button>
                            </div>
                        </div>
                    </div> -->
</div>