import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { TeamsService } from 'src/app/services/teams-cofat/teams.service';

@Component({
  selector: 'app-import-boxes-products',
  templateUrl: './import-boxes-products.component.html',
  styleUrls: ['./import-boxes-products.component.css']
})

export class ImportBoxesProductsComponent implements OnInit {

  @Output() result = new EventEmitter<any>();

  @ViewChild('file')
  myfileImport: ElementRef;

  // msgError: string = null
  isLoading: boolean
  itemsError: any = []

  isSaved: Boolean = false
  boxes: any[] = []
  idSociety: string;

  constructor(public activeModal: NgbActiveModal,
    private service: SettingsService,
    private teamsServices: TeamsService) {
    this.idSociety = this.teamsServices.getIdSociety();
  }

  ngOnInit(): void {
  }

  docIsValid(array) {
    // console.log("array")
    // console.log(array)
    if (array.includes("Ref")
      && array.includes('t_seak')
      && array.includes('t_hght')
      && array.includes('t_wdth')
      && array.includes('t_dpth')
      && array.includes('UCS')
      && array.includes('Volume')
      && array.includes('Code FNR')) {
      return true
    } else {
      if (!array.includes("Ref")) {
        this.itemsError.push("Ref")
      }
      if (!array.includes("t_seak")) {
        this.itemsError.push("t_seak")
      }
      if (!array.includes("t_hght")) {
        this.itemsError.push("t_hght")
      }
      if (!array.includes("t_wdth")) {
        this.itemsError.push("t_wdth")
      }
      if (!array.includes("t_dpth")) {
        this.itemsError.push("t_dpth")
      }
      if (!array.includes("UCS")) {
        this.itemsError.push("UCS")
      }
      if (!array.includes("Volume")) {
        this.itemsError.push("Volume")
      }
      if (!array.includes("Code FNR")) {
        this.itemsError.push("Code FNR")
      }

      // console.log(this.itemsError)
      return false
    }
  }
  readExcel(event) {
    this.isLoading = true

    const workbook = new Excel.Workbook();
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }

    /**
     * Final Solution For Importing the Excel FILE
     */
    // this.msgError = null
    // this.labelType = ""
    // this.isFedex = false
    // this.isTNT = false
    this.boxes = []
    const header = [] //["nameDest", "adressDest", "numTelDest", "destination", "nom_prod", "quantite", "prix_unitaire"]

    const arryBuffer = new Response(target.files[0]).arrayBuffer();
    arryBuffer.then((data) => {
      workbook.xlsx.load(data)
        .then(async () => {

          // play with workbook and worksheet now
          // console.log(workbook);
          if (workbook.worksheets.length === 0) {
            // Afficher un message d'erreur ou le gérer selon les besoins de votre application
            alert("Erreur : Le classeur ne contient aucune feuille de calcul !!!")
            return;
          }

          const worksheet = workbook.worksheets[0];

          // console.log('rowCount: ', worksheet.getRow(1).values);
          if (!this.docIsValid(worksheet.getRow(1).values.map(Function.prototype.call, String.prototype.trim))
                /*&& !this.docIsTNT(worksheet.getRow(1).values.map(Function.prototype.call, String.prototype.trim))*/) {
            // console.log("file incorrecte !!!")
            alert("Impossible d'importer le fichier excel à cause d'un champ manquant")
            return;
          }

          const promises = [];
          // worksheet.
          worksheet.eachRow((row, rowNumber) => {
            // console.log('Row: ' + rowNumber + ' Value: ' + row.values);
            // console.log('Row ' + rowNumber + ' = ' + JSON.stringify(row.values));
            let box = {}
            if (rowNumber == 1) {
              row.eachCell((cell, colNumber) => {
                // console.log('Cell ' + colNumber + ' , ' + header[colNumber - 1] + ' = ' + cell.value);
                header.push(cell.value.toString().trim())
              });

            } else {

              // const promise = db.insert(row); // <-- whatever async operation you have here
              row.eachCell((cell, colNumber) => {
                // console.log('Cell ' + colNumber + ' , ' + header[colNumber - 1] + ' = ' + cell.value);
                box[header[colNumber - 1]] = this.getValue(this.trimIfString(cell.value))
              });
              promises.push({ box: box, msg: null });
            }
          });
          this.boxes = await Promise.all(promises)
          // console.log("all boxes : ")
          // console.log(this.boxes)

          this.isLoading = false
        });
    });

    this.myfileImport.nativeElement.value = "";
  }
  trimIfString(value: any): any {
    if (typeof value === 'string') {
      return value.trim();
    }
    return value;
  }
  getValue(item) {
    // console.log("itemmmmmmm")
    // console.log(item)
    if (item != null && item.hasOwnProperty("result")) {
      return item.result
    } else {
      return item
    }
  }
  deleteBox(index) {
    this.boxes.splice(index, 1)
  }

  // chunkArray(array, chunkSize) {
  //   const chunks = [];
  //   for (let i = 0; i < array.length; i += chunkSize) {
  //     chunks.push(array.slice(i, i + chunkSize));
  //   }
  //   return chunks;
  // }
  // saveChunkBox(boxes) {
  //   return new Promise(async (slv) => {
  //     let promises: Promise<any>[] = []
  //     let promise: Promise<any>
  //     boxes.forEach(item => {
  //       promise = this.addBox(item).then((value: any) => {
  //         return { box: item.box, msg: value };
  //       });

  //       promises.push(promise);
  //     });

  //     var results = await Promise.all(promises)
  //     slv(results.filter(item => item.msg != null && item.msg.response == false))
  //   })
  // }
  // async saveBoxes() {
  //   let newBoxes: any[] = []

  //   this.isSaved = true

  //   const chunkSize = 100;
  //   const boxChunks = this.chunkArray(this.boxes, chunkSize);

  //   const promises = boxChunks.map(async (boxes) => {
  //     return await this.saveChunkBox(boxes)
  //   });
  //   var allCmds = await Promise.all(promises);

  // }

  async saveBoxes() {
    let promises: Promise<any>[] = [];
    this.boxes.forEach(item => {
      let promise: Promise<any>
      // if(this.isFedex) {
      promise = this.addBox(item).then((value: any) => {
        return { box: item.box, msg: value };
      });

      promises.push(promise);
    });

    Promise.all(promises)
      .then((results) => {
        // console.log(results)
        this.boxes = results.filter(item => item.msg != null && !item.msg.response);
        if (this.boxes?.length == 0)
          this.closeModal()
      })
  }

  addBox(item) {
 
    let promise = new Promise((resolve, reject) => {
      this.service.addBoxProduct({
        ref: item.box['Ref'],
        t_seak: item.box['t_seak'],
        t_hght: item.box['t_hght'],
        t_wdth: item.box['t_wdth'],
        t_dpth: item.box['t_dpth'],
        ucs: item.box['UCS'],
        volume: item.box['Volume'],
        providerCode: item.box['Code FNR'],
        society: this.idSociety
      })
        .then((res: any) => {
          if (res) {
            if (!res?.response) {
              console.log("resssssssss : ")
              console.log(res)
            }
            resolve(res);
          }
        });
    });
    return promise
  }

  closeModal() {
    this.result.emit(true);
    this.activeModal.dismiss();
  }

}
