import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { SweetalertService } from 'src/app/services/sweetalert/sweetalert.service';
import Swal from 'sweetalert2';
import { AddBoxByProviderComponent } from '../../settings/products/windows/add-box-by-provider/add-box-by-provider.component';
import { TeamsService } from 'src/app/services/teams-cofat/teams.service';

@Component({
  selector: 'app-edit-commande',
  templateUrl: './edit-commande.component.html',
  styleUrls: ['./edit-commande.component.css']
})
export class EditCommandeComponent implements OnInit {

  isLoading: boolean = true
  isClicked: Boolean = false
  isSaved: Boolean = true

  idClient: string = "";
  cmdID: string = '';
  order: any = {}

  client: any = null

  allFiles: any = []

  allSocieties: any[] = []
  allPalettes: any[] = []
  selectedPalette: any = null

  typesPriority: any = [
    { code: 1, label: "Opération standard", description: "Délai de livraison normal, sans urgence particulière" },
    { code: 2, label: "Opération accélérée", description: "Délai de livraison plus court que la livraison standard, généralement pour les commandes qui ont une certaine priorité" },
    { code: 3, label: "Opération express", description: "Livraison très rapide, souvent pour les commandes qui doivent être livrées dans un délai très court" },
    { code: 4, label: "Opération urgente", description: "Délai de livraison le plus court possible, généralement pour les situations d'urgence ou les commandes critiques" },
  ]

  boxform: FormGroup;
  palletForm: FormGroup;
  palletsData: any[] = []

  idSociety: string;

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private service: SettingsService,
    private modalService: NgbModal,
    private sweetalert: SweetalertService,
    private clientService: ClientsService,
    private cmdService: CommandesService,
    private teamsServices: TeamsService) {
    this.boxform = fb.group({
      boxes: fb.array([])
    })
    this.palletForm = fb.group({
      pallets: fb.array([])
    })
    this.idSociety = this.teamsServices.getIdSociety();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(async (params) => {
      this.cmdID = params.cmd;
      this.idClient = params.client;
      await this.getInfosUser()
      await this._fetchAllSocieties()
      this.allTypePalettes()
      await this._fetchCmd();
    });
  }
  async getInfosUser() {
    await this.clientService.getClient(this.idClient).then((res: any) => {
      // console.log("getUserInfos");
      // console.log(res);
      if (res) {
        this.client = res;
      }
    });
  }

  get boxes(): FormArray {
    return this.boxform.get("boxes") as FormArray;
  }
  get pallets(): FormArray {
    return this.palletForm.get("pallets") as FormArray;
  }
  checkFields() {
    // console.log(this.order)

    if (this.order.society != null && this.order.society != ""
      && this.order.ref_facture_cmd != null && this.order.ref_facture_cmd != ""
      && this.order.priority != null && this.order.priority != ""
      && this.order.pickupDate != null && this.order.pickupDate != ""
      && this.order.pickupTime != null && this.order.pickupTime != ""
      && (this.boxesNotEmpty(this.boxform.value.boxes) || this.palletsNotEmpty(this.palletForm.value.pallets))
    ) {
      return true
    } else
      return false
  }

  checkFieldSociety() {
    if (this.idSociety != null)
      return true
    else return this.order.society != null && this.order.society != "null" && this.order.society != ""
  }

  async updateCommande() {
    var isValid = this.checkFields()
    if (!isValid) {
      this.isSaved = false
      alert("Commande est invalide")
    } else {
      this.isClicked = true

      if (this.boxesNotEmpty(this.boxform.value.boxes) && this.palletsData?.length == 0) {
        let optionsHtml = this.allPalettes.map(pal => `<option value="${pal?._id}">${pal?.name}</option>`).join('');

        Swal.fire({
          title: 'Sélectionnez le type du palette : ',
          html: `<select id="swal-select" class="swal2-input">${optionsHtml}</select>`,
          focusConfirm: false,
          preConfirm: () => {
            const select = document.getElementById('swal-select') as HTMLSelectElement;
            return select.value;
          }
        }).then((result) => {
          this.isClicked = false
          if (result.isConfirmed) {
            // console.log('Vous avez sélectionné :', result.value);

            var pal = this.allPalettes.find(item => item?._id === result.value)
            this.calculatePallets(pal)
          }
        });
      } else {
        this.order.boxes = (this.boxesNotEmpty(this.boxform.value.boxes)) ? this.boxform.value.boxes : []
        this.order.pallets = (this.palletsNotEmpty(this.palletForm.value.pallets)) ? this.palletForm.value.pallets : []

        this.order.idExp = this.idClient
        this.order.genPallets = this.palletsData
        this.order.uploadDocs = this.allFiles

        if (this.idSociety != null)
          this.order.society = this.idSociety


        await this.cmdService.updateCmd(this.cmdID, this.order).then((res: any) => {
          if (res) {

            this.isSaved = true
            this.isClicked = false

            alert(res?.msg);
          } else {
            alert("Une erreur s'est produite !!!")
          }
        });
      }
    }
  }
  async _fetchCmd() {
    // console.log(cid);
    await this.cmdService.getOneCMD(this.cmdID).then((data: any) => {
      // console.log("dddddddddddddd")
      // console.log(data)

      if (data) {
        this.isLoading = false
        this.order = data;
        this.order.society = this.order.society?._id
        this.order.pickupTime = this.setPickupTime(this.order.pickupDate)
        this.order.pickupDate = this.setPickupDate(this.order.pickupDate)
        this.order.priority = this.findPriority(this.order.priority)
        this.palletsData = this.order.genPallets
        this.allFiles = this.order?.uploadDocs
        this.initBoxesGroup()
        this.initPalletsGroup()
      } else {
        alert("Aucune commande valide")
        location.href = '/commandes';
      }
    });
  }
  setPickupDate(date: any) {
    let pickupDate = new Date(date);

    // Formatage de la date au format YYYY-MM-DD
    let formattedDate = pickupDate.toISOString().split('T')[0];

    // Mettre à jour la valeur pour l'affichage dans l'input de type date
    return formattedDate;
  }
  setPickupTime(date: any) {
    let pickupTime = new Date(date);

    // Formatage de la date au format YYYY-MM-DD
    let formattedTime = pickupTime.toISOString().split('T')[1].substr(0, 5); // pour HH:MM

    // Mettre à jour la valeur pour l'affichage dans l'input de type date
    return formattedTime;
  }
  findPriority(priority: any) {
    return this.typesPriority.find((element: any) => element.code === priority.code);
  }

  async allTypePalettes() {
    // console.log("alllll")
    await this.service.allPallets().then((res: any) => {
      // console.log("resssssssss services : ")
      // console.log(res)
      if (res) {
        this.allPalettes = res.data;
      }
    });
  }

  /** BEGIN BOX FORM GROUP */
  updatePalletQuantity(event, box, i, j) {
    const palletGroup = this.pallets.at(i) as FormGroup;

    const add = palletGroup.get('boxes') as FormArray;

    add.at(j).patchValue({
      quantite: Math.ceil(event.target.value / box.value.ucs)
    });

  }
  updateQuantity(event: any, box: any, index: any) {
    this.initPalletsData()

    const add = this.boxform.get('boxes') as FormArray;

    add.at(index).patchValue({
      quantite: Math.ceil(event.target.value / box.value.ucs)
    });
  }
  selectBoxProductRef(event: any, index: any) {
    this.initPalletsData()

    var valueProduct = this.client.boxes_products
      .find((element: any) => element.ref === event.target.value);

    // var valueProduct = this.client.boxes_products[event.target.value]
    // console.log(valueProduct)

    const add = this.boxform.get('boxes') as FormArray;

    this.mappingSelectedProductValue(valueProduct, index, add)
  }
  mappingSelectedProductValue(valuePost: any, index: number, contentsArray: any) {
    contentsArray.at(index).patchValue({
      id: valuePost?._id,
      nom_prod: valuePost?.ref,
      des_prod: valuePost?.t_seak,
      ucs: valuePost?.ucs,

      length: valuePost?.t_dpth,
      width: valuePost?.t_wdth,
      height: valuePost?.t_hght,
      volume: valuePost?.volume,
    });
  }

  // pallet?.id_pallet === pal._id
  isChecked(pallet) {
    // console.log("event.target.value")
    // console.log(pallet.value)
  }
  getTypePalette(i) {
    return this.pallets.controls[i].get('id_pallet')?.value
  }
  setTypePalette(event: any, i: number) {
    // console.log(event)
    var typePallet = event //.target.value
    var pallet: any = {
      id_pallet: typePallet
    }
    if (typePallet != "other") {
      this.selectedPalette = this.allPalettes.find((pal) => pal._id === event)
      // console.log(this.selectedPalette)
      pallet.name_pallet = this.selectedPalette.name
      pallet.length = this.selectedPalette.longueur
      pallet.width = this.selectedPalette.largeur
      pallet.height = this.selectedPalette.hauteur
      pallet.volume = this.cmdService.formatNumber(
        this.selectedPalette.longueur * this.selectedPalette.largeur * this.selectedPalette.hauteur, 3)
    } else {
      pallet.name_pallet = "Autre"
      pallet.length = null
      pallet.width = null
      pallet.height = null
      pallet.volume = null
    }

    this.pallets.at(i).patchValue(pallet);
  }
  selectPalletBoxProductRef(event: any, i: number, index: number) {
    var valueProduct = this.client.boxes_products
      .find((element: any) => element.ref === event.target.value);

    const palletGroup = this.pallets.at(i) as FormGroup;

    const add = palletGroup.get('boxes') as FormArray;

    this.mappingSelectedProductValue(valueProduct, index, add)
  }

  /** BEGIN PALLETS GROUP FUNCTION */
  initPalletsGroup() {
    if (this.order?.pallets?.length > 0) {
      this.mappingPalletsGourp()
    } else {
      this.addNewPalletGroup()
    }
  }
  mappingPalletsGourp() {
    this.pallets.clear()

    this.order?.pallets?.forEach((item: any) => {
      this.pallets.push(this.fb.group({
        id_pallet: item?.id_pallet,
        name_pallet: item?.name_pallet,
        quantity: item?.quantity,

        weight: item?.weight,

        length: item?.length,
        width: item?.width,
        height: item?.height,
        volume: item?.volume,

        boxes: this.mappingBoxesPalletGourp(item?.boxes)
      }))
    });
  }
  mappingBoxesPalletGourp(boxes) {
    var theBoxes = this.fb.array([])

    boxes?.forEach((item: any) => {
      theBoxes.push(this.fb.group({
        id: item?._id,
        nom_prod: item?.nom_prod,
        des_prod: item?.des_prod,
        ucs: item?.ucs,

        nbrPcs: item?.nbrPcs,
        quantite: item?.quantite,
        weight: item?.weight,

        length: item?.length,
        width: item?.width,
        height: item?.height,
        volume: this.cmdService.formatNumber(item?.volume, 3)
      }))
    });

    return theBoxes
  }
  addNewPalletGroup() {
    this.pallets.push(this.fb.group({
      id_pallet: [],
      name_pallet: [],
      quantity: [],
      // prix_unitaire: [],
      weight: [],

      length: [],
      width: [],
      height: [],
      volume: 0,

      boxes: this.fb.array([
      ])
    }))
  }
  deletePalletGroup(index: number) {
    this.pallets.removeAt(index);

    if (this.palletForm.value.pallets.length == 0)
      this.addNewPalletGroup()
  }
  setVolumePallet(i: number) {
    var length = this.pallets.controls[i].get('length')?.value
    var width = this.pallets.controls[i].get('width')?.value
    var height = this.pallets.controls[i].get('height')?.value
    var volume
    if (length && width && height)
      volume = this.cmdService.formatNumber(length * width * height, 3)
    else
      volume = 0

    this.pallets.at(i).patchValue({
      volume: volume
    });
  }

  createBoxesPallet(): FormGroup {
    return this.fb.group({
      // id: [],
      // nom_prod: [],
      // des_prod: [],
      // ucs: [],

      // nbrPcs: [],
      quantite: [],
      weight: [],

      length: [],
      width: [],
      height: [],
      volume: 0
    });
  }
  addContent(boxIndex: number): void {
    const palletGroup = this.pallets.at(boxIndex) as FormGroup;
    const contentsArray = palletGroup.get('boxes') as FormArray;
    contentsArray.push(this.createBoxesPallet());
  }
  deleteBoxPalletGroup(boxIndex, contentIndex: number) {
    const boxGroup = this.pallets.at(boxIndex) as FormGroup;
    const contentsArray = boxGroup.get('boxes') as FormArray;
    // if (contentsArray.length > 1)
    contentsArray.removeAt(contentIndex);
  }
  setVolumeBoxPallet(boxIndex, contentIndex: number) {
    const boxGroup = this.pallets.at(boxIndex) as FormGroup;
    // const contentsArray = boxGroup.get('boxes') as FormArray;

    var contents = this.pallets.controls[boxIndex].get('boxes')?.value
    // console.log(contents)

    var length = contents[contentIndex].length
    var width = contents[contentIndex].width
    var height = contents[contentIndex].height
    var volume
    if (length && width && height)
      volume = this.cmdService.formatNumber(length * width * height, 3)
    else
      volume = 0

    const add = boxGroup.get('boxes') as FormArray;
    add.at(contentIndex).patchValue({
      volume: volume
    });
  }
  palletsNotEmpty(pallets) {
    if (pallets.length == 0)
      return false

    for (var i = 0; i < pallets.length; i++) {
      if (pallets[i].id_pallet == null || pallets[i].id_pallet == ""
        || pallets[i].name_pallet == null || pallets[i].name_pallet == ""
        || pallets[i].quantity == null || pallets[i].quantity < 0
        // || pallets[i].prix_unitaire == null || pallets[i].prix_unitaire < 0
        || pallets[i].weight == null || pallets[i].weight < 0

        || pallets[i].length == null || pallets[i].length < 0
        || pallets[i].width == null || pallets[i].width < 0
        || pallets[i].height == null || pallets[i].height < 0
        // || !this.boxesNotEmpty(pallets[i].boxes)
      ) {
        return false
      }
    }
    return true
  }
  /** END PALLETS GROUP FUNCTION */

  /** BEGIN BOXES GROUP FUNCTION */
  initBoxesGroup() {
    if (this.order?.boxes?.length > 0) {
      this.mappingBoxesGourp()
    } else {
      this.addNewBoxGroup()
    }
  }
  mappingBoxesGourp() {
    this.boxes.clear()

    this.order?.boxes?.forEach((item: any) => {
      this.boxes.push(this.fb.group({
        id: item?._id,
        nom_prod: item?.nom_prod,
        des_prod: item?.des_prod,
        ucs: item?.ucs,

        nbrPcs: item?.nbrPcs,
        quantite: item?.quantite,
        weight: item?.weight,

        length: item?.length,
        width: item?.width,
        height: item?.height,
        volume: item?.volume,
      }))
    });
  }
  addNewBoxGroup() {
    this.initPalletsData()

    this.boxes.push(this.fb.group({
      id: [],
      nom_prod: [],
      des_prod: [],
      ucs: [],

      nbrPcs: [],
      quantite: [],
      // prix_unitaire: [],
      weight: [],

      length: [],
      width: [],
      height: [],
      volume: 0
    }))
  }
  deleteBoxGroup(index: number) {
    this.boxes.removeAt(index);
    this.initPalletsData()

    if (this.boxform.value.boxes.length == 0)
      this.addNewBoxGroup()
  }
  boxesNotEmpty(boxes) {
    if (boxes.length == 0)
      return false

    for (var i = 0; i < boxes.length; i++) {
      if (boxes[i].nom_prod == null || boxes[i].nom_prod == ""
        || boxes[i].des_prod == null || boxes[i].des_prod == ""
        || boxes[i].quantite == null || boxes[i].quantite < 0
        // || boxes[i].prix_unitaire == null || boxes[i].prix_unitaire < 0
        || boxes[i].weight == null || boxes[i].weight < 0

        || boxes[i].length == null || boxes[i].length < 0
        || boxes[i].width == null || boxes[i].width < 0
        || boxes[i].height == null || boxes[i].height < 0) {
        return false
      }
    }
    return true
  }
  /** END BOXES GROUP FUNCTION */

  /** BEGIN FUNCTION SOCIETY */
  async _fetchAllSocieties() {
    await this.cmdService.allSocieties().then((res: any) => {
      // console.log("resssssssss services : ")
      // console.log(res)
      if (res) {
        this.allSocieties = res;
      }
    });
  }
  /** END FUNCTION SOCIETY */

  newBoxProduct(index) {
    this.initPalletsData()

    // console.log(box)
    const modalRef = this.modalService.open(AddBoxByProviderComponent, { size: 'lg' });
    modalRef.componentInstance.box = {
      "ref": "",
      "t_seak": "",
      "t_hght": null,
      "t_wdth": null,
      "t_dpth": null,
      "ucs": null,
    }

    modalRef.componentInstance.result.subscribe((receivedEntry) => {
      // console.log(receivedEntry)
      if (receivedEntry) {
        // console.log("receivedEntry")
        // console.log(receivedEntry)
        this.client.boxes_products.push(receivedEntry)
        // console.log("Result from modal:", receivedEntry);
        // this._fetchBoxes()

        const add = this.boxform.get('boxes') as FormArray;

        this.mappingSelectedProductValue(receivedEntry, index, add)
      }
    });
  }

  /** BEGIN UPLOAD FILES */
  onFileSelected(event) {
    const files = event.target.files;
    // console.log(files.length)
    if (files.length > 0) {
      this.cmdService.uploadFiles(files).then((res: any) => {
        this.allFiles.push(...res?.data)
      });
    }
  }
  /** END UPLOAD FILES */

  async calculatePallets(pallet) {
    // console.log(this.boxform.value.boxes)
    var isValidate = this.boxesNotEmpty(this.boxform.value.boxes)
    if (isValidate) {
      await this.cmdService.calculatePallets({
        PALLET_LENGTH: pallet?.longueur,
        PALLET_WIDTH: pallet?.largeur,
        PALLET_HEIGHT: pallet?.hauteur,

        pallet_generated: pallet?._id,

        productList: this.boxform.value.boxes
      })
        .then((res: any) => {
          // console.log("resssssssss : ")
          // console.log(res)
          if (res) {
            if (res?.response) {
              this.palletsData = res?.data
            } else {
              alert(res?.msg);
            }
          } else {
            alert("Une erreur s'est produite !!!")
          }
        });
    } else {
      alert("Merci d'ajouter les boxes de la commande !!!")
    }
  }

  initPalletsData() {
    if (this.palletsData?.length > 0)
      this.palletsData = []
  }

}
