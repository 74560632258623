<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-user icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Frais Client
                <div class="page-title-subheading">Ajouter le frais de client.
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                class="btn-shadow mr-3 btn btn-dark">
                <i class="fa fa-star"></i>
            </button>
            <div class="d-inline-block dropdown">
                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    class="btn-shadow dropdown-toggle btn btn-info">
                    <span class="btn-icon-wrapper pr-2 opacity-7">
                        <i class="fa fa-business-time fa-w-20"></i>
                    </span>
                    Buttons
                </button>
                <div tabindex="-1" role="menu" aria-hidden="true"
                    class="dropdown-menu dropdown-menu-right">
                    <ul class="nav flex-column">
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-inbox"></i>
                                <span>
                                    Inbox
                                </span>
                                <div class="ml-auto badge badge-pill badge-secondary">86</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-book"></i>
                                <span>
                                    Book
                                </span>
                                <div class="ml-auto badge badge-pill badge-danger">5</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-picture"></i>
                                <span>
                                    Picture
                                </span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a disabled href="javascript:void(0);" class="nav-link disabled">
                                <i class="nav-link-icon lnr-file-empty"></i>
                                <span>
                                    File Disabled
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="tab-content">
    <div class="main-card mb-12 card">
        <div class="card-body">
            <form class="" action="" method="post" onsubmit="return false;"
                (onsubmit)="addFraisClient(fraisInterne, fraisExterne, fraisRetour);">
                <div class="form-row">
                    <div class="col-md-4">
                        <div class="position-relative form-group">
                            <legend for="fraisInterne" class="">Frais Interne</legend>
                            <input name="fraisInterne" id="fraisInterne" type="number" class="form-control"
                                #fraisInterne ngModel>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="position-relative form-group">
                            <legend for="fraisExterne" class="">Frais Externe</legend>
                            <input name="fraisExterne" id="fraisExterne" type="number" class="form-control"
                                #fraisExterne ngModel>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="position-relative form-group">
                            <legend for="fraisRetour" class="">Frais de Retour</legend>
                            <input name="fraisRetour" id="fraisRetour" type="number" class="form-control"
                                #fraisRetour ngModel>
                        </div>
                    </div>
                </div>

                <div class="position-relative row form-check">
                    <div class="col-sm-10 offset-sm-10">
                        <button type="submit"
                            (click)="addFraisClient(fraisInterne, fraisExterne, fraisRetour)"
                            class="btn btn-success">Ajouter Frais Client</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>