import { Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { TeamsService } from 'src/app/services/teams-cofat/teams.service';

@Component({
  selector: 'app-add-box-by-provider',
  templateUrl: './add-box-by-provider.component.html',
  styleUrls: ['./add-box-by-provider.component.css']
})
export class AddBoxByProviderComponent implements OnInit {

  isSaved: boolean = true
  idSociety: string;
  @Output() result = new EventEmitter<any>();
  @Input() box: any;


  constructor(private activeModal: NgbActiveModal,
    private serviceClient: ClientsService,
    private cmdService: CommandesService,
    private service: SettingsService,
    private teamsServices: TeamsService) {
      this.idSociety = this.teamsServices.getIdSociety();
  }

  ngOnInit(): void {
    // console.log("this.box")
    // console.log(this.box)
    // if (this.box?.provider) {
    //   this.provider = this.box?.provider
    // }
  }

  // ngAfterViewInit() {
  //   if (this.provider)
  //     this.input.nativeElement.value = this.provider?.providerCode + ' - '
  //       + this.provider?.providerName
  // }

  saveBox() {
    var isValidate = this.checkField()
  
    if (!isValidate) {
      this.isSaved = isValidate
      alert("Merci de remplir les champs obligatoire !!!")
    } else {
      if (this.idSociety != null) {
        this.box.society = this.idSociety
      }
      this.box.volume = this.cmdService.formatNumber(
        this.box.t_wdth * this.box.t_dpth * this.box.t_hght, 3)
      this.service.saveBoxProduct(this.box)
        .then((res: any) => {
          if (res) {
            // console.log("resssssssss : ")
            // console.log(res)
            if (res?.response) {
              this.result.emit(res?.data);
              this.activeModal.dismiss();
            }
          }
        });
    }
  }

  checkField() {
    if (this.box.ref != null && this.box.ref != "" &&
      this.box.t_seak != null && this.box.t_seak != "" &&
      this.box.t_hght != null && this.box.t_hght.toString() != "" &&
      this.box.t_wdth != null && this.box.t_wdth.toString() != "" &&
      this.box.t_dpth != null && this.box.t_dpth.toString() != "" &&
      this.box.ucs != null && this.box.ucs.toString() != "" //&&
      /*this.box.provider != null && this.box.provider != ""*/)
      return true
    else
      return false
  }

  closeModal() {
    this.result.emit(null);
    this.activeModal.dismiss();
  }

}
