<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-car icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>
                {{statusCmd[statusCmds[0]]?.label}}
                <div class="page-title-subheading">Liste des commandes
                    <div class="badge badge-{{statusCmd[statusCmds[0]]?.color}}">
                        {{statusCmd[statusCmds[0]]?.label}}</div>
                </div>
            </div>
        </div>
        <!-- <div class="page-title-actions">
            <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                class="btn-shadow mr-3 btn btn-dark">
                <i class="fa fa-star"></i>
            </button>
            <div class="d-inline-block dropdown">
                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    class="btn-shadow dropdown-toggle btn btn-info">
                    <span class="btn-icon-wrapper pr-2 opacity-7">
                        <i class="fa fa-business-time fa-w-20"></i>
                    </span>
                    Buttons
                </button>
                <div tabindex="-1" role="menu" aria-hidden="true"
                    class="dropdown-menu dropdown-menu-right">
                    <ul class="nav flex-column">
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-inbox"></i>
                                <span>
                                    Inbox
                                </span>
                                <div class="ml-auto badge badge-pill badge-secondary">86</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-book"></i>
                                <span>
                                    Book
                                </span>
                                <div class="ml-auto badge badge-pill badge-danger">5</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-picture"></i>
                                <span>
                                    Picture
                                </span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a disabled href="javascript:void(0);" class="nav-link disabled">
                                <i class="nav-link-icon lnr-file-empty"></i>
                                <span>
                                    File Disabled
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div> -->
    </div>
</div>

<div *ngIf="isLoading; else elseAllProviders">
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-body text-center">
                    <div class="spinner-container blue-spinner">
                        <mat-progress-spinner mode="indeterminate" [diameter]="40"
                            [strokeWidth]="5"></mat-progress-spinner>
                    </div>
                </div>
                <!-- <div style="height: 70px;" [nbSpinner]="loading" nbSpinnerStatus="warning" nbSpinnerSize="giant">
            </div> -->
            </div>
        </div>
    </div>
</div>
<ng-template #elseAllProviders>
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-header">Commandes
                    <div class="btn-actions-pane-right">
                        <div role="group" class="btn-group-sm btn-group" *ngIf="cmdsListToAffect?.length > 0">
                            <button (click)="validateCmds(cmdsListToAffect)"
                                class="mr-2 btn-icon btn-icon-only btn btn-outline-success"><i
                                    class="pe-7s-download btn-icon-wrapper"> Valider les commandes</i></button>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <div class="budget-price justify-content-center" *ngIf="listCmds?.length === 0">
                        <h5 class="text-center">Aucune commande {{statusCmds}} </h5>
                    </div>
                    <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                        *ngIf="listCmds?.length > 0">
                        <thead>
                            <tr>
                                <th class="text-center">
                                    <div *ngIf="!(statusCmds.includes('invalid')); else loggedOut">
                                        #
                                    </div>

                                    <ng-template #loggedOut>
                                        <div class="contact100-form-checkbox" style="margin-top: auto;">
                                            <input class="form-check-input" id="cmd_all" type="checkbox" name="cmd_all"
                                                (change)="addALLCmdToAffect($event)" [checked]="isAllCmdsChecked()">
                                            <label class="label-checkbox100" for="cmd_all">

                                            </label>
                                        </div>
                                    </ng-template>
                                </th>
                                <!-- <th class="text-center">#</th> -->
                                <th>Fournisseur</th>
                                <th>Société</th>
                                <th>Référence</th>
                                <th>Date Création</th>
                                <!-- <th class="text-center">Ville</th> -->
                                <th class="text-center">Produits<br>
                                    Quantité - Nom produit</th>
                                <th class="text-center">Volume<br>total (m³)</th>
                                <th class="text-center">Poids<br>total (Kg)</th>
                                <th class="text-center">Status</th>
                                <th class="text-center">Total<br>Commande</th>
                                <th class="text-center"
                                    *ngIf="!includes(['invalid', 'validated', 'transporting_depot', 'prepared', 'paied'])">
                                    Affecté à</th>
                                <th class="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let cmd of listCmds, let index = index">
                                <td class="text-center text-muted">
                                    <div *ngIf="!(statusCmds.includes('invalid')); else loggedOut">
                                        #{{index+1}}
                                    </div>

                                    <ng-template #loggedOut>
                                        <div class="contact100-form-checkbox">
                                            <input class="form-check-input" id="cmd_{{index}}" type="checkbox"
                                                name="cmd_{{index}}" (change)="addCmdToAffect($event, cmd)"
                                                [checked]="itemIsAffected(cmd._id)">
                                            <label class="label-checkbox100" for="cmd_{{index}}"></label>
                                        </div>
                                    </ng-template>
                                </td>
                                <!-- <td class="text-center text-muted">#{{index+1}}</td> -->
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-3">
                                                <div class="widget-content-left">
                                                    <!-- <img width="40" class="rounded-circle"
                                                    [src]="cmd?.society?.logo" alt="Logo"
                                                    style="border: 1px solid #000;"> -->
                                                    <ngx-avatar class="rounded-circle" name="{{cmd?.client_cmd?.prenom_client}}
                                                {{cmd?.client_cmd?.nom_client}}">
                                                    </ngx-avatar>
                                                </div>
                                            </div>
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading">
                                                    {{cmd?.client_cmd?.providerName}}
                                                </div>
                                                <div class="widget-subheading opacity-7">
                                                    {{cmd?.client_cmd?.shippingAdress?.label_target}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <!-- <div class="widget-content-left mr-3">
                                                <div class="widget-content-left">
                                                    <img width="40" class="rounded-circle"
                                                        [src]="cmd?.society?.logo" alt="Logo"
                                                        style="border: 1px solid #000;">
                                                </div>
                                            </div> -->
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading">
                                                    {{cmd?.society?.name}}
                                                </div>
                                                <div class="widget-subheading opacity-7">
                                                    {{cmd?.society?.pays}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <!-- <td>
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left mr-6">
                                            <div class="widget-content-left">
                                                <ngx-avatar class="rounded-circle" name="{{cmd?.client_cmd?.prenom_client}}
                                                {{cmd?.client_cmd?.nom_client}}">
                                                </ngx-avatar>
                                            </div>
                                        </div>
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading">{{cmd?.client_cmd?.prenom_client}}
                                                {{cmd?.client_cmd?.nom_client}}</div>
                                            <div class="widget-subheading opacity-7">
                                                {{cmd?.client_cmd?.company_name}}</div>
                                        </div>
                                    </div>
                                </div>
                            </td> -->
                                <td>{{cmd?.ref_facture_cmd}}</td>
                                <td>
                                    {{cmd?.date_creation_cmd | date:'d MMM y'}}<br>
                                    <b>{{cmd?.date_creation_cmd | date:'HH:mm'}}</b>
                                </td>
                                <!-- <td class="text-center">
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading">
                                                {{cmd?.user_destination?.nom_destination}}</div>
                                            <div class="widget-subheading opacity-7">
                                                {{cmd?.user_destination?.numtel_destination}}</div>
                                        </div>
                                    </div>
                                </div>
                            </td> -->
                                <!-- <td class="text-center">{{cmd?.user_destination?.adresse_destination}}
                                <strong>{{cmd?.destination_cmd}}</strong>
                            </td> -->
                                <td>
                                    <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush"
                                        *ngIf="cmd?.totalBoxes > 0">
                                        <li class="list-group-item">
                                            <div class="widget-content p-0">
                                                <div class="widget-content-wrapper">
                                                    <div class="widget-content-left mr-3 widget-pallet">
                                                        <img width="100%"
                                                            src="https://assets.marketbey.tn/WTM/products/box.png"
                                                            alt="">
                                                    </div>
                                                    <div class="widget-content-right">
                                                        <div class="font-size-lg text-muted">
                                                            <span>
                                                                {{ cmd?.totalBoxes }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush"
                                        *ngIf="cmd?.totalPallets > 0">
                                        <li class="list-group-item">
                                            <div class="widget-content p-0">
                                                <div class="widget-content-wrapper">
                                                    <div class="widget-content-left mr-3 widget-pallet">
                                                        <img width="100%"
                                                            src="https://assets.marketbey.tn/WTM/products/pallet.png"
                                                            alt="">
                                                    </div>
                                                    <div class="widget-content-right">
                                                        <div class="font-size-lg text-muted">
                                                            <span>
                                                                {{ cmd?.totalPallets }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </td>
                                <td class="text-center">
                                    {{ cmd?.totalPalletVolume | number:'0.1' }}
                                </td>
                                <td class="text-center">
                                    {{ cmd?.totalPoids | number:'0.1' }}
                                </td>
                                <td class="text-center">
                                    <div class="badge badge-{{statusCmd[cmd?.etat_cmd]?.color}}">
                                        {{statusCmd[cmd?.etat_cmd]?.label}}</div>
                                </td>
                                <td class="text-center">{{cmd?.total_ca_cmd+cmd?.frais_livraison |
                                    number:'0.3'}} EUR</td>
                                <td class="text-center" style="color: red;"
                                    *ngIf="!includes(['invalid', 'validated', 'transporting_depot', 'prepared', 'paied'])">
                                    <strong>{{cmd?.transport_affect[cmd?.transport_affect?.length-1]?.transporter_id?.nom_prenom_tracking}}</strong>
                                </td>
                                <td class="text-center">
                                    <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                        <button class="btn-wide btn btn-info">Actions</button>
                                        <button type="button" ngbDropdownToggle
                                            class="dropdown-toggle-split dropdown-toggle btn btn-info"><span
                                                class="sr-only">Actions</span></button>
                                        <div ngbDropdownMenu>
                                            <button type="button" *ngIf="cmd?.etat_cmd == 'invalid'"
                                                (click)="validateCmds([cmd._id])" tabindex="0" class="dropdown-item">
                                                <i class="metismenu-icon pe-7s-check text-warning"
                                                    style="margin-right: 10px;"></i>Valider la commande
                                            </button>
                                            <button type="button" *ngIf="cmd?.etat_cmd == 'validated'"
                                                (click)="sendMail(cmd)" tabindex="0" class="dropdown-item">
                                                <i class="metismenu-icon pe-7s-mail-open-file text-primary"
                                                    style="margin-right: 10px;"></i>Envoyer E-mail
                                            </button>
                                            <button type="button" *ngIf="cmd?.etat_cmd == 'validated'"
                                                (click)="validateTakeOff(cmd)" tabindex="0" class="dropdown-item">
                                                <i class="metismenu-icon pe-7s-check text-danger"
                                                    style="margin-right: 10px;"></i>Valider l'enlévement
                                            </button>
                                            <button type="button" (click)="goToNewTab(cmd._id, '/detail-cmd')"
                                                tabindex="0" class="dropdown-item">
                                                <i class="metismenu-icon pe-7s-news-paper"
                                                    style="margin-right: 10px;"></i>Details
                                            </button>
                                            <button type="button" tabindex="0"
                                                (click)="goToNewTab(cmd._id, '/facture-cmd')" class="dropdown-item"><i
                                                    class="metismenu-icon pe-7s-print"
                                                    style="margin-right: 10px;"></i>Facture</button>
                                            <button
                                                *ngIf="cmd?.etat_cmd=='validated' || cmd?.etat_cmd=='invalid' || cmd?.etat_cmd=='canceled'"
                                                type="button" tabindex="0" class="dropdown-item"
                                                (click)="deleteCmd(cmd?._id)">
                                                <i class="metismenu-icon pe-7s-trash text-danger"
                                                    style="margin-right: 10px;"> Supprimer l'enlèvement
                                                </i>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="d-block text-center card-footer" *ngIf="listCmds?.length > 0 && pages?.length > 1">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-center">
                            <li [ngClass]="(currentPage===0)?'page-item disabled':'page-item'">
                                <a class="page-link" tabindex="-1" (click)="previousPage()">
                                    <i class="nav-link-icon pe-7s-angle-left-circle"></i>
                                    Précédent</a>
                            </li>

                            <div *ngFor="let p of pagesDisplaied">
                                <li [ngClass]="(currentPage===p)?'page-item active':'page-item'">
                                    <a *ngIf="currentPage!==p" class="page-link" (click)="selectPage(p)">{{p+1}}</a>

                                    <span *ngIf="currentPage===p" class="page-link">
                                        {{p+1}}
                                        <span class="sr-only">(current)</span>
                                    </span>
                                </li>
                            </div>
                            <!-- <li class="page-item active">
                                <span class="page-link">
                                    2
                                    <span class="sr-only">(current)</span>
                                </span>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li> -->

                            <li [ngClass]="(currentPage===totalPages-1)?'page-item disabled':'page-item'">
                                <a class="page-link" tabindex="-1" (click)="nextPage()">Suivant <i
                                        class="nav-link-icon pe-7s-angle-right-circle"></i></a>
                            </li>
                        </ul>
                    </nav>

                    <!-- <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                            class="pe-7s-trash btn-icon-wrapper"> </i></button>
                    <button class="btn-wide btn btn-success">Save</button> -->
                </div>
            </div>
        </div>
    </div>
</ng-template>