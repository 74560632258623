import { Component, OnInit, ViewChild } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { TransportorsService } from 'src/app/services/transportors/transportors.service';

@Component({
  selector: 'app-pending-demandes',
  templateUrl: './pending-demandes.component.html',
  styleUrls: ['./pending-demandes.component.css']
})
export class PendingDemandesComponent implements OnInit {
  @ViewChild('element') element;
  public position = { X: 'Right', Y: 'Bottom' };

  statusDemandes: any = [];
  allDemandes: any = [];
  nbrDemande: number = 0

  // subscription: Subscription;
  intervalId: number = null;

  constructor(private transServices: TransportorsService) { }

  ngOnInit(): void {
    this.transServices.getLabeledStatusDemande().then(
      (lst) => {
        this.statusDemandes = lst
      }
    )
    this.listDemandes()

    // const source = interval(1000);
    // this.subscription = source.subscribe(val => {
    //   this.listDemandes()
    // });
  }

  goToNewTabDemande(idDemande, route) {
    this.transServices.goToNewTabDemande(idDemande, route)
  }
  //////////////// notif
  playSound() {
    var audio = new Audio();
      // audio.src = "http://www.schillmania.com/projects/soundmanager2/demo/mpc/audio/CHINA_1.mp3";
      audio.src = "https://drive.google.com/uc?export=download&id=1M95VOpto1cQ4FQHzNBaLf0WFQglrtWi7";
      audio.load();
      audio.play();
  }
  ////////////////////////////////////

  async listDemandes() {
    await this.transServices.demandesTransportors(0, "created",
      null, null, null, null, null, null).then((res: any) => {
        if (res) {
          // console.log("resssssssss : ")
          // console.log(res)
          this.allDemandes = res.items;
          // console.log(this.allDemandes.length != 0 && this.allDemandes.length!= this.nbrDemande)
          // console.log("this.allDemandes.length")
          // console.log(this.allDemandes.length)
          // console.log("this.nbrDemande")
          // console.log(this.nbrDemande)
          if(this.allDemandes.length != 0 && this.allDemandes.length!= this.nbrDemande) {
            // console.log("sound")
            this.playSound();
          }
          this.nbrDemande = this.allDemandes.length
        }
      });
  }

}
