import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { TeamsService } from 'src/app/services/teams-cofat/teams.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-add-team',
  templateUrl: './add-team.component.html',
  styleUrls: ['./add-team.component.css']
})
export class AddTeamComponent implements OnInit {

  profiles = ["admin", "simple_user", "tranport_cost"];
  societies: any[] = [];
  idSociety: string;
  isSaved: Boolean = true;
  idUser: string;
  user: any = {}

  constructor(private teamsServices: TeamsService) {
    this.idSociety = this.teamsServices.getIdSociety();
  }


  async ngOnInit() {
    await this._fetchAllSocieties()
  }

  checkField() {
    if (this.user.lastname != null && this.user.lastname != "" &&
      this.user.firstname != null && this.user.firstname != "" &&
      this.user.username != null && this.user.username != "" &&
      this.user.numtel != null && this.user.numtel != "" &&
      this.user.password != null && this.user.password != "" &&
      this.user.confirmPassword != null && this.user.confirmPassword != "" &&
      this.user.profil != null && this.user.profil != ""

    )
      return true
    else
      return false
  }
  async _fetchAllSocieties() {
    await this.teamsServices.allSocieties().then((res: any) => {
      // console.log("resssssssss services : ")
      // console.log(res)
      if (res) {
        this.societies = res;
      }
    });
  }

  async initUser() {
    this.user = {}
  }

  async addUser() {

    console.log("this.user")
    console.log(this.user)
    var isValid = this.checkField()
    if (!isValid) {
      this.isSaved = isValid
      alert("Merci de remplir les champs obligatoire !!!")
    } else {
      if (this.idSociety != null) {
        this.user.society = this.idSociety
      }
      console.log("this.user")
      console.log(this.user)
      await this.teamsServices.subscribe(this.user)
        .then((res: any) => {
          console.log("resssssssss : ")
          console.log(res)
          if (res) {
            console.log("success")
            location.href = '/all-teams'
            this.initUser()
            // if (this.selectedProfiles.includes("TRANSPORTEUR")) {
            //   location.href = '/tracking-user';
            // } else if (this.selectedProfiles.includes("PREPARATEUR")) {
            //   location.href = '/preparers';
            // }
            //this.sacsList = res;
          }
        });

    }
    // console.log("this.selectedProfiles : ")
    // console.log(this.selectedProfiles)
  }

}
