import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientsService } from 'src/app/services/clients/clients.service';

@Component({
  selector: 'app-frais-client',
  templateUrl: './frais-client.component.html',
  styleUrls: ['./frais-client.component.css']
})
export class FraisClientComponent implements OnInit {

  idClient: string = ""

  constructor(
    private route: ActivatedRoute,
    private clientsService: ClientsService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.idClient = params.client;
    });
  }

  async addFraisClient(fraisInterne, fraisExterne, fraisRetour) {
    await this.clientsService.addFrais(this.idClient, fraisInterne.value, 
      fraisExterne.value, fraisRetour.value)
      .then((res: any) => {
        // console.log("resssssssss : ")
        // console.log(res)
        if (res) {
          //location.href = '/clients';
          //this.sacsList = res;
        }
      });
  }

}
