import { Component, OnInit } from '@angular/core';
import { TransportorsService } from 'src/app/services/transportors/transportors.service';

@Component({
  selector: 'app-sponsores',
  templateUrl: './sponsores.component.html',
  styleUrls: ['./sponsores.component.css']
})
export class SponsoresComponent implements OnInit {

  allSponsors: any = []
  
  constructor(private transServices: TransportorsService) { }

  ngOnInit(): void {
    this.listSponsores()
  }

  async listSponsores() {
    await this.transServices.getSponsores().then((res: any) => {
      if (res) {
        // console.log("ressssssssssssss")
        // console.log(res)
        this.allSponsors = res;
      }
    });
  }

}
