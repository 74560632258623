import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TeamsService } from '../teams-cofat/teams.service';

@Injectable({
  providedIn: 'root'
})
export class TransportorSocietyService {

  idUser: string

  constructor(private http: HttpClient,
    private router: Router,
    private userService: TeamsService,) {
    this.idUser = this.userService.getIdUser()
  }

  async getTransSociety(idTransSociety) {
    return await new Promise((slv) => {
      this.http.post(environment.apiOneTransSociety, {
        idTransSociety
      }).subscribe(
        (data: any) => {
          if (data) {
            slv(data);
          } else slv(null);
        },
        (error) => {
          slv(null);
        }
      );
    });
  }

  async saveContractSociety(data: any) {
    data.idUser = this.idUser
    return await new Promise((slv) => {
      this.http.post(environment.apiSaveContractTransSociety, data).subscribe(
        (data: any) => {
          if (data) {
            slv(data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  addSociety(formData) {
    return new Promise((slv) => {
      this.http.post(environment.apiAddTransSociety, formData).subscribe(
        (res: any) => {
          //console.log(JSON.stringify(data));
          // if (res) {
          //   if (res.response) {
          slv(res);
          //   } else {
          //     slv(res);
          //   }
          // }
        },
        (error) => {
          slv(null);
        }
      );
    });
  }

  editSociety(idTransSociety, transSociety) {
    return new Promise((slv) => {
      this.http.post(environment.apiEditTransSociety, {
        idTransSociety,
        transSociety
      }).subscribe(
        (res: any) => {
          slv(res);
        },
        (error) => {
          slv(null);
        }
      );
    });
  }

  deleteSociety(idTransSociety: any) {
    return new Promise((slv) => {
      this.http.post(environment.apiDeleteTransSociety, {
        idTransSociety
      }).subscribe(
        (res: any) => {
          slv(res);
        },
        (error) => {
          slv(null);
        }
      );
    });
  }

  async getContractSociety(idSociety: any) {
    return await new Promise((slv) => {
      this.http.post(environment.apiGetContractTransSociety, {
        idSociety: idSociety
      }).subscribe(
        (data: any) => {
          if (data) {
            slv(data);
          } else slv(null);
        },
        (error) => {
          slv(null);
        }
      );
    });
  }

  async allSocieties() {
    return await new Promise((slv) => {
      this.http.post(environment.apiAllTransSocieties, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
}
