<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-culture icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Société de transports
                <div class="page-title-subheading">Ajouter une nouvelle société de transports.
                </div>
            </div>
        </div>
        <!-- <div class="page-title-actions">
            <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                class="mr-2 btn-icon btn-icon-only btn btn-primary">
                <i class="pe-7s-id"></i>
                Détails du contrat
            </button>

            <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                class="mr-2 btn-icon btn-icon-only btn btn-outline-success">
                <i class="pe-7s-download text-success"></i>
                Télécharger le contrat
            </button>
        </div> -->
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="tab-content" id="tab-content">
                <div class="main-card mb-12 card">
                    <div class="card-header">Informations Générales de la société de transports</div>
                    <div class="card-body">
                        <div class="row form-group">
                            <div class="col-4">
                                <div class="form-group">
                                    <div class="Neon Neon-theme-dragdropbox">
                                        <input style="z-index: 999; opacity: 0; width: 320px; height: 200px; position: absolute; right: 0px; 
                    left: 0px; margin-right: auto; margin-left: auto;" name="file" id="file" #file accept="image/*"
                                            (change)="handleFileInput($event)" type="file">
                                        <div class="Neon-input-dragDrop">
                                            <div class="Neon-input-inner">
                                                <div class="Neon-input-icon"
                                                    *ngIf="trans_society?.logo=='' || trans_society?.logo == null">
                                                    <i class="fa fa-file-image-o"></i>
                                                </div>
                                                <div class="Neon-input-icon" *ngIf="trans_society?.logo!=null">
                                                    <img [src]="env.logoTransportorSocietyAssetsBaseURL + trans_society?.logo"
                                                        style="width:100px">
                                                </div>
                                                <div class="Neon-input-text">
                                                    <h3>Drag&amp;Drop files here</h3> <span
                                                        style="display:inline-block; margin: 5px 0">or</span>
                                                </div><a class="Neon-input-choose-btn blue">Browse
                                                    Files</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!isSaved && (trans_society?.logo==null || trans_society?.logo=='')" class="text-danger">
                                        <b>Ajouter la photo de la société</b>
                                    </div>
                                    <!-- <mat-error style="color: red;font-weight: bold;" *ngIf="imageUrl==''">Ajouter
                                        la photo de profile
                                    </mat-error> -->
                                </div>
                            </div>
                            <div class="col-8">
                                <div class="row">
                                    <div class="col-md-6 position-relative form-group">
                                        <legend for="providerName" class="">Nom de la société *</legend>
                                        <input name="nameSociety" id="nameSociety" type="text" class="form-control"
                                            [(ngModel)]="trans_society.nameSociety"
                                            [class]="(!isSaved && trans_society.nameSociety=='') ? 'input-border-danger' : ''">
                                    </div>
                                    <div class="col-md-6 position-relative form-group">
                                        <legend for="siteWeb" class="">Site web</legend>
                                        <input name="siteWeb" id="siteWeb" type="text" class="form-control"
                                            [(ngModel)]="trans_society.siteWeb">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 position-relative form-group">
                                        <legend for="emailSociety" class="">Email *</legend>
                                        <input name="emailSociety" id="emailSociety" type="text" class="form-control"
                                            [(ngModel)]="trans_society.emailSociety"
                                            [class]="(!isSaved && (trans_society.emailSociety=='' || 
                                            (trans_society.emailSociety!='' && !trans_society.emailSociety.includes('@')))) ? 'input-border-danger' : 'input-border'">
                                    </div>
                                    <div class="col-md-6 position-relative form-group">
                                        <legend for="numTelSociety" class="">Numéro Tél *</legend>
                                        <input name="numTelSociety" id="numTelSociety" type="text"
                                            class="form-control" [(ngModel)]="trans_society.numTelSociety"
                                            [class]="(!isSaved && trans_society.numTelSociety == '') ? 'input-border-danger' : 'input-border'">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="tab-content" id="tab-content">
                <div class="main-card mb-12 card">
                    <div class="card-header">Contact Principal
                    </div>
                    <div class="card-body">
                        <div class="row form-group">
                            <div class="col-md-3">
                                <div class="position-relative form-group">
                                    <legend for="firstName" class="">Prénom du contact *</legend>
                                    <input name="firstName" id="firstName" type="text" class="form-control"
                                        [(ngModel)]="trans_society.firstName"
                                        [class]="(!isSaved && trans_society.firstName=='') ? 'input-border-danger' : ''">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="position-relative form-group">
                                    <legend for="lastName" class="">Nom du contact *</legend>
                                    <input name="lastName" id="lastName" type="text" class="form-control"
                                        [(ngModel)]="trans_society.lastName"
                                        [class]="(!isSaved && trans_society.lastName=='') ? 'input-border-danger' : ''">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-12"
                                        [class]="!isOther(trans_society.labelPost) ? 'col-md-12' : 'col-md-6'">
                                        <legend for="labelPost" class="">Titre/Poste *</legend>
                                        <select class="form-control" [(ngModel)]="trans_society.labelPost"
                                            name="labelPost" id="labelPost" (change)="selectContactPost($event)"
                                            [class]="(!isSaved && !trans_society.labelPost) ? 'input-border-danger' : ''">
                                            <option>Sélectionnez la poste du contact</option>
                                            <option [value]="item" *ngFor="let item of posts">
                                                {{item}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-12 col-md-6" *ngIf="isOther(trans_society.labelPost)"
                                        style="margin-top: auto;">
                                        <input name="otherPost" id="otherPost" type="text" class="form-control"
                                            [(ngModel)]="trans_society.post"
                                            [class]="(!isSaved && isOther(trans_society.labelPost) && (trans_society.post=='' || trans_society.post==null)) ? 'input-border-danger' : ''">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <legend for="email" class="">Email du contact *</legend>
                                    <input name="email" id="email" type="text" class="form-control"
                                        [(ngModel)]="trans_society.email"
                                        [class]="(!isSaved && trans_society.email=='') ? 'input-border-danger' : ''">
                                </div>
                            </div>
                            
                            <div class="col-md-4 form-group">
                                <legend for="numTel" class="">Numéro de téléphone du contact *</legend>
                                <input name="numTel" id="numTel" type="text" class="form-control"
                                    [(ngModel)]="trans_society.numTel"
                                    [class]="(!isSaved && trans_society.numTel=='') ? 'input-border-danger' : ''">
                            </div>

                            <div class="col-md-4 form-group">
                                <legend for="numTel2" class="">Numéro de téléphone secondaire</legend>
                                <input name="numTel2" id="numTel2" type="text" class="form-control"
                                    [(ngModel)]="trans_society.numTel2">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header-tab card-header">
                <div class="card-header-title">
                    <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
                    Contacts
                </div>
                <div class="btn-actions-pane-right">
                    <button type="button" (click)="addNewContactGroup()" class="mt-1 btn btn-warning">
                        <i class="pe-7s-plus"></i> Ajouter
                    </button>
                </div>
            </div>
            <div class="card-body">
                <div [formGroup]="formContact">
                    <div formArrayName="contacts">
                        <div *ngFor="let contact of contacts.controls; let i = index">
                            <div class="position-relative row form-group" [formGroupName]="i" style="margin-top: 10px;">
                                <div class="col-12 div-contact">
                                    <div class="row">
                                        <div class="col-12 col-md-11">
                                            <div class="row form-group">
                                                <div class="col-md-3">
                                                    <div class="position-relative form-group">
                                                        <legend for="firstName" class="">Prénom du contact *</legend>
                                                        <input name="firstName" id="firstName" type="text"
                                                            class="form-control" formControlName="firstName">
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="position-relative form-group">
                                                        <legend for="lastName" class="">Nom du contact *</legend>
                                                        <input name="lastName" id="lastName" type="text"
                                                            class="form-control" formControlName="lastName">
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="row">
                                                        <div class="col-12"
                                                            [class]="!isOther(contact.value.labelPost) ? 'col-md-12' : 'col-md-6'">
                                                            <legend for="post" class="">Titre/Poste *</legend>
                                                            <select class="form-control" formControlName="labelPost"
                                                                name="post" id="post" (change)="selectPost($event, i)">
                                                                <option>Sélectionnez la poste du contact</option>
                                                                <option [value]="item" *ngFor="let item of posts">
                                                                    {{item}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class="col-12 col-md-6"
                                                            *ngIf="isOther(contact.value.labelPost)"
                                                            style="margin-top: auto;">
                                                            <input name="otherPost" id="otherPost" type="text"
                                                                class="form-control" formControlName="post">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row form-group">
                                                <div class="col-md-4">
                                                    <div class="position-relative form-group">
                                                        <legend for="email" class="">Email du contact *</legend>
                                                        <input name="email" id="email" type="text"
                                                            class="form-control" formControlName="email">
                                                    </div>
                                                </div>
                                                <div class="col-md-4 form-group">
                                                    <legend for="numTel" class="">Numéro de téléphone du contact *
                                                    </legend>
                                                    <input name="numTel" id="numTel" type="text" class="form-control"
                                                        formControlName="numTel">
                                                </div>

                                                <div class="col-md-4 form-group">
                                                    <legend for="numTel2" class="">Numéro de téléphone secondaire
                                                    </legend>
                                                    <input name="numTel2" id="numTel2" type="text"
                                                        class="form-control" formControlName="numTel2">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-1 div-center">
                                            <button type="button" (click)="deleteContactGroup(i)"
                                                class="mr-2 btn-icon btn-icon-only btn btn-danger float-right">
                                                <i class="pe-7s-trash btn-icon-wrapper"></i>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header-tab card-header">
                <div class="card-header-title">
                    <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
                    Adresses plate-forme
                </div>
                <div class="btn-actions-pane-right">
                    <button type="button" (click)="addLocation()" class="mt-1 btn btn-warning">
                        <i class="pe-7s-plus"></i> Ajouter
                    </button>
                </div>
            </div>
            <div class="card-body">
                <div class="row form-group">
                    <div class="col-12">
                        <form [formGroup]="form">
                            <div formArrayName="locations">
                                <div *ngFor="let location of locations.controls; let i = index">
                                    <div class="position-relative row form-group" [formGroupName]="i"
                                        style="margin-top: 10px;">
                                        <div class="col-12 div-contact">
                                            <div class="row">
                                                <div class="col-12 col-md-10 form-group">
                                                    <legend for="adress" class="">Adresse (Localisation
                                                        Google)*</legend>
                                                    <input type="text" name="adress" id="adress" class="form-control"
                                                        matInput [matAutocomplete]="auto" (input)="onChange($event)"
                                                        formControlName="label_target"
                                                        [class]="(!isSaved && checkValue(location.value.label_target)) ? 'input-border-danger' : 'input-border'">

                                                    <mat-autocomplete #auto="matAutocomplete"
                                                        (optionSelected)="onSelectionChange($event, i)">
                                                        <mat-option *ngFor="let place of places" [value]="place">
                                                            {{ place.description }}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </div>
                                                <div class="col-12 col-md-2 div-center">
                                                    <button type="button" (click)="deleteLocation(i)"
                                                        class="mr-2 btn-icon btn-icon-only btn btn-danger float-right">
                                                        <i class="pe-7s-trash btn-icon-wrapper"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="d-block text-center card-footer">
                <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger">
                    Annuler
                </button>
                <button class="btn-wide btn btn-primary" (click)="addSociety()">
                    Enregistrer
                </button>
            </div>
        </div>
    </div>
</div>