<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-box1 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Sacs
                <div class="page-title-subheading">This is an example dashboard created using build-in
                    elements and components.
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <div class="d-inline-block dropdown">
                <div>
                    <form class="form-inline" action="" method="post" onsubmit="return false;"
                        (onsubmit)="searchSacByVille()">
                        <div class="mb-5 mr-sm-5 mb-sm-0 position-relative form-group">
                            <!-- <input type="text" id="keyword" name="keyword" class="form-control"
                                placeholder="Type to search" #keyword ngModel> -->
                            <select name="ville" id="ville" class="form-control selectric"
                                (change)="selectChangeVille($event)">
                                <option *ngFor="let ville of villes" [ngValue]="ville" ngDefaultControl>
                                    {{ville}}
                                </option>
                            </select>
                        </div>
                        <button type="submit" class="btn-shadow mr-3 btn btn-dark"
                            (click)="searchSacByVille()">
                            <i class="pe-7s-search"></i>
                        </button>
                    </form>
                </div>
                <!-- <form class="form-inline" action="" method="post" onsubmit="return false;"
                    (onsubmit)="searchSacByVille()">
                    <div class="col-md-12">
                        <div class="position-relative form-group">
                            <input type="text" id="keyword" name="keyword" class="form-control"
                                placeholder="Type to search" #keyword ngModel>
                            <button type="button" data-toggle="tooltip" title="Example Tooltip"
                                data-placement="bottom" class="btn-shadow mr-3 btn btn-dark">
                                <i class="pe-7s-search"></i>
                            </button>
                        </div>
                    </div>

                </form> -->
            </div>
        </div>
    </div>
</div>
<div *ngIf="sacsListByVille.length>0">
    <div class="row" *ngFor="let sacsList of sacsListByVille">
        <div class="col-md-12">
            <div class="title-sacs-ville"><strong>► {{sacsList.ville?.city_name}} : </strong></div>
        </div>
        <div class="col-md-3" *ngFor="let sac of sacsList.sacs">
            <div class="card mb-3 widget-chart text-white card-border"
                [style.background-color]="sac.color_sac">
                <div class="icon-wrapper rounded-circle">
                    <div class="icon-wrapper-bg bg-white opacity-1"></div>
                    <i class="pe-7s-box1 text-warning"></i>
                </div>
                <div class="text-white space">
                    <h2><strong>Ref: {{sac.ref_sac}}</strong></h2>
                </div>
                <div class="text-white space">
                    <h4><strong>Destination {{sac.destination_sac}}</strong></h4>
                </div>
                <div class="widget-heading">Pris en charge par :
                    {{sac?.supportedBy?.supportedUser?.nom_prenom_tracking}}</div>
                <div class="widget-description text-white">
                    <!-- <svg aria-hidden="true" focusable="false"
                    data-prefix="fas" data-icon="angle-up" class="svg-inline--fa fa-angle-up fa-w-10 "
                    role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                    <path fill="currentColor"
                        d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z">
                    </path>
                </svg> -->
                    {{sac?.supportedBy?.supportedUser?.profil_tracking}}
                    <button [routerLink]="['/detail-sac']" [queryParams]="{ sac: sac._id }"
                        class="mb-2 mr-2 btn btn-danger">Détails sac<span
                            class="badge badge-pill badge-light">{{sac.commandes.length}}
                            commandes</span></button>
                    <button type="submit" class="mb-1 mr-1 btn btn-dark" (click)="viderSac(sac._id)">
                        <i class="pe-7s-trash"></i>
                    </button>
                    <button class="mb-2 mr-2 btn btn-primary" (click)="affectSacToLouagiste(sac._id, sac.destination_sac)">Affecte sac
                        <i class="pe-7s-download" ></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>