import { Component, OnInit } from '@angular/core';
import { SweetalertService } from 'src/app/services/sweetalert/sweetalert.service';
import { TeamsService } from 'src/app/services/teams-cofat/teams.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-teams-list',
  templateUrl: './teams-list.component.html',
  styleUrls: ['./teams-list.component.css']
})
export class TeamsListComponent implements OnInit {

  isLoading: any = true;
  teamsList: any[] = [];

  filter: any = {}
  constructor(private teamsServices: TeamsService,
    private sweetalert: SweetalertService,
  ) { }

  ngOnInit(): void {
    this.listTeams();
  }

  async filterTeams() {
    // console.log(this.filter)
    await this.teamsServices.searchTeam(this.filter).then((res: any) => {
      // console.log(res)
      if (res) {
        this.teamsList = res;
      }
    });
  }

  async listTeams() {
    await this.teamsServices.getTeams().then((res: any) => {
      console.log
      console.log(res)
      if (res) {
        this.isLoading = false
        this.teamsList = res;
      }
    });
  }

  deleteTeam(idUser: any) {
    this.sweetalert.confirmActionBtn("Vous ne pourrez pas revenir en arrière !").then((result: any) => {
      if (result.isConfirmed) {
        this.teamsServices.deleteTeam(idUser).then((res: any) => {
          if (res && res.response == true) {
            this.sweetalert.alertConfirmed(res?.msg).then((res: any) => {
              this.listTeams()
            })
          }
        });
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        this.sweetalert.alertCanceled().then((res: any) => {
        })
      }
    })
  }


}
