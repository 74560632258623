<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-users icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Fournisseurs
                <div class="page-title-subheading">La liste des fournisseurs enregistrés.
                </div>
            </div>
        </div>
        <!-- <div class="page-title-actions">
            <div class="d-inline-block dropdown">
                <div>
                    <form class="form-inline" action="" method="post" onsubmit="return false;"
                        (onsubmit)="getClient(keyword);">
                        <div class="mb-2 mr-sm-2 mb-sm-0 position-relative form-group">
                            <input name="keyword" id="keyword" placeholder="Client ?" type="keyword"
                                class="form-control" #keyword ngModel>
                        </div>
                        <button type="submit" class="btn-shadow mr-3 btn btn-dark"
                            (click)="getClient(keyword)">
                            <i class="pe-7s-search"></i>
                        </button>
                    </form>
                </div>
            </div>
        </div> -->
        <div class="page-title-actions">
            <!-- <input hidden type="file" (change)="readExcel($event)" multiple="false" #file>
            <button type="button" (click)="file.click()" data-toggle="tooltip" title="Example Tooltip"
                data-placement="bottom" class="btn-shadow mr-3 btn btn-info">
                <i class="fa fa-star"> Importer fichier excel</i>
            </button> -->
            <div class="d-inline-block dropdown">
                <button type="button" class="btn-shadow mr-3 btn btn-info" (click)="importProviders()">
                    <span class="btn-icon-wrapper pr-2 opacity-7">
                        <i class="fa fa-star"></i>
                    </span>
                    Importer fichier excel
                </button>
            </div>
            <div class="d-inline-block dropdown">
                <button type="button" class="btn btn-success" [routerLink]="['/add-provider']">
                    <span class="btn-icon-wrapper pr-2 opacity-7">
                        <i class="fa fa-business-time fa-w-20"></i>
                    </span>
                    Ajouter fournisseur
                </button>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Filtre
                <div class="btn-actions-pane-right">
                    <!-- <div role="group" class="btn-group-sm btn-group">
                                        <div class="badge badge-warning">{{statusCmds}}</div>
                                    </div> -->
                </div>
            </div>
            <div class="tab-content">
                <div class="tab-pane tabs-animation fade show active" id="tab-content-0" role="tabpanel">
                    <div class="card-body">
                        <form class="" action="" method="post" onsubmit="return false;">
                            <div class="form-row">
                                <div class="col-md-3">
                                    <div class="position-relative form-group">
                                        <legend for="ref" class="">Code</legend>
                                        <input name="ref" id="ref" type="text" class="form-control"
                                            [(ngModel)]="filter.code">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="position-relative form-group">
                                        <legend for="name" class="">Nom</legend>
                                        <input name="name" id="name" type="text" class="form-control"
                                            [(ngModel)]="filter.name">
                                    </div>
                                </div>
                                <!-- <div class="col-md-1">
                                    <div class="position-relative form-group">
                                        <legend for="stat" class="">Status</legend>
                                        <select class="form-control selectric" (change)="selectChangeStatus($event)">
                                            <option [value]="stat" *ngFor="let stat of status">
                                                {{stat}}</option>
                                        </select>
                                    </div>
                                </div> -->
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="numero" class="">Numéro de téléphone
                                        </legend>
                                        <input name="numero" id="numero" type="text" class="form-control"
                                            [(ngModel)]="filter.numTel">
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="ville" class="">Ville</legend>
                                        <select class="form-control selectric" [(ngModel)]="filter.country"
                                        name="country" id="country">
                                            <option [value]="ville" *ngFor="let ville of countries">
                                                {{ville}}</option>
                                        </select>
                                    </div>
                                </div>
                                <!-- <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="datein" class="">Date Début</legend>
                                        <input id="datein" type="date" class="form-control" name="datein" tabindex="1"
                                            [(ngModel)]="filter.startDate">
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="datefin" class="">Date Fin</legend>
                                        <input id="datefin" type="date" class="form-control" name="datefin" tabindex="1"
                                            [(ngModel)]="filter.endDate">
                                    </div>
                                </div> -->
                                <button type="submit" (click)="filterProviders()"
                                    class="btn btn-primary btn-lg btn-icon icon-righ btn-search" id="search"
                                    tabindex="4">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isLoading; else elseAllProviders">
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-body text-center">
                    <div class="spinner-container blue-spinner">
                        <mat-progress-spinner mode="indeterminate" [diameter]="40"
                            [strokeWidth]="5"></mat-progress-spinner>
                    </div>
                </div>
                <!-- <div style="height: 70px;" [nbSpinner]="loading" nbSpinnerStatus="warning" nbSpinnerSize="giant">
            </div> -->
            </div>
        </div>
    </div>
</div>
<ng-template #elseAllProviders>
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-header">Fournisseurs
                    <!-- <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button class="active btn btn-focus">Last Week</button>
                        <button class="btn btn-focus">All Month</button>
                    </div>
                </div> -->
                </div>
                <div class="table-responsive">
                    <div class="budget-price justify-content-center" *ngIf="clientsList?.length === 0">
                        <h2 class="text-center" style="color: red;">Aucun Fournisseur </h2>
                    </div>
                    <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                        *ngIf="clientsList?.length > 0">
                        <thead>
                            <tr>
                                <th class="text-center">#</th>
                                <th class="text-center"></th>
                                <th>Fournisseur</th>
                                <th>Gerbable</th>
                                <th>Adresse complète</th>
                                <th class="text-center">Pays</th>
                                <th class="text-center">Téléphone</th>
                                <th class="text-center">Collecteur</th>
                                <!-- <th class="text-center">Date<br>Pick UP</th> -->
                                <th class="text-center">Pick UP</th>
                                <th class="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let element of clientsList, let index = index">
                                <td class="text-center text-muted">#{{index+1}}</td>
                                <td>
                                    <div *ngIf="!element?.client?.isValid" class="text-center invalid-provider">
                                        <div style="margin-top: auto; margin-bottom: auto;">
                                            <i class="fa fa-close"></i>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-3">
                                                <div class="widget-content-left">
                                                    <!-- <img width="40" class="rounded-circle"
                                                    src="assets/images/avatars/2.jpg" alt=""> -->
                                                    <ngx-avatar class="rounded-circle" name="{{element.client.prenom_client}}
                                                {{element.client.nom_client}}">
                                                    </ngx-avatar>
                                                </div>
                                            </div>
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading text-primary">
                                                    {{element.client.providerCode}}
                                                </div>
                                                <div class="widget-heading">
                                                    {{element.client.providerName}}
                                                </div>
                                                <div class="widget-subheading opacity-7">
                                                    {{element.client.prenom_client}}
                                                    {{element.client.nom_client}}
                                                </div>
                                                <div class="widget-subheading opacity-7">
                                                    {{element.client.contactUsername}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <b *ngIf="element?.client?.stackable != null">
                                        {{ (element?.client?.stackable) ? 'Oui' : 'Non' }}
                                    </b>
                                </td>
                                <td>
                                    {{ element?.client?.fullAddress }}
                                </td>
                                <td class="text-center">
                                    <b>{{ element?.client?.country }}</b>
                                </td>
                                <td class="text-center"><strong>{{element.client.contactNumTel}}</strong>
                                </td>
                                <td class="text-center">{{element.client?.pickUpCollector?.name_collector}}
                                    <br><strong>{{element.client?.pickUpCollector?.numTel_collector}}</strong>
                                </td>
                                <!-- <td class="text-center">
                                <b>{{element.client?.datePickUp}}</b>
                            </td> -->
                                <td class="text-center">
                                    <div class="badge badge-warning">{{element.nbrPickUp}}</div>
                                </td>
                                <td class="text-center">
                                    <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                        <button class="btn-wide btn btn-info">Actions</button>
                                        <button type="button" ngbDropdownToggle
                                            class="dropdown-toggle-split dropdown-toggle btn btn-info"><span
                                                class="sr-only">Actions</span></button>
                                        <div ngbDropdownMenu>
                                            <button type="button" tabindex="0"
                                                (click)="addProviderProducts(element.client)" class="dropdown-item">
                                                <i class="metismenu-icon pe-7s-plus text-info"
                                                    style="margin-right: 10px;"></i>Ajouter des produits
                                            </button>
                                            <button type="button" [routerLink]="['/detail-cmds-client']"
                                                [queryParams]="{ client: element.client._id }" tabindex="0"
                                                class="dropdown-item">
                                                <i class="metismenu-icon pe-7s-news-paper"
                                                    style="margin-right: 10px;"></i>Details
                                            </button>
                                            <button type="button" tabindex="0"
                                                *ngIf="element.client.fraisDelivery == null"
                                                [routerLink]="['/frais-client']"
                                                [queryParams]="{ client: element.client._id }" class="dropdown-item"><i
                                                    class="metismenu-icon pe-7s-print"
                                                    style="margin-right: 10px;"></i>Ajouter frais</button>
                                            <button type="button" tabindex="0"
                                                *ngIf="element.client.fraisDelivery != null"
                                                [routerLink]="['/edit-frais-client']"
                                                [queryParams]="{ client: element.client._id }" class="dropdown-item"><i
                                                    class="metismenu-icon pe-7s-print"
                                                    style="margin-right: 10px;"></i>Modifier frais</button>
                                            <!-- <button type="button" tabindex="0" [routerLink]="['/account-provider']"
                                                [queryParams]="{ client: element.client._id }" class="dropdown-item"><i
                                                    class="metismenu-icon pe-7s-print"
                                                    style="margin-right: 10px;"></i>Compte fournisseur</button> -->
                                            <button type="button" tabindex="0"
                                                (click)="pickUpCollector(element.client._id)" class="dropdown-item"><i
                                                    class="metismenu-icon pe-7s-print"
                                                    style="margin-right: 10px;"></i>Affecter le pick-up</button>

                                            <button type="button" [routerLink]="['/edit-provider']"
                                                [queryParams]="{ provider: element.client._id }" tabindex="0"
                                                class="dropdown-item">
                                                <i class="metismenu-icon pe-7s-pen text-primary"
                                                    style="margin-right: 10px;"></i>Modifier fournisseur
                                            </button>
                                            <button type="button" (click)="deleteProvider(element.client._id)"
                                                tabindex="0" class="dropdown-item">
                                                <i class="metismenu-icon pe-7s-trash text-danger"
                                                    style="margin-right: 10px;"></i>Supprimer fournisseur
                                            </button>
                                        </div>
                                    </div>
                                    <!-- <button [routerLink]="['/detail-cmds-client']"
                                    [queryParams]="{ client: element.client._id }" type="button"
                                    id="PopoverCustomT-1"
                                    class="btn btn-primary btn-sm">Details</button> -->

                                    <!-- <button *ngIf="element.fraisClient == null"
                                    [routerLink]="['/frais-client']"
                                    [queryParams]="{ client: element.client._id }" type="button"
                                    id="PopoverCustomT-1" class="btn btn-info btn-sm">+</button> -->
                                    <!-- <button *ngIf="element.fraisClient != null"
                                    [routerLink]="['/edit-frais-client']"
                                    [queryParams]="{ client: element.client._id }" type="button"
                                    id="PopoverCustomT-1" class="btn btn-info btn-sm">-</button> -->

                                    <!-- <button [routerLink]="['/compte-client']"
                                    [queryParams]="{ client: element.client._id }" type="button"
                                    id="PopoverCustomT-1" class="btn btn-primary btn-sm">Compte</button> -->

                                    <!-- <button type="button" (click)="pickUpCollector(element.client._id)"
                                    id="PopoverCustomT-1"
                                    class="btn btn-warning btn-sm">Affecter</button> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- <div class="d-block text-center card-footer">
                <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                        class="pe-7s-trash btn-icon-wrapper"> </i></button>
                <button class="btn-wide btn btn-success">Save</button>
            </div> -->
            </div>
        </div>
    </div>
</ng-template>