import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TeamsService } from '../teams-cofat/teams.service';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  idSociety: string;
  constructor(private http: HttpClient,
    private router: Router,
    private teamsServices: TeamsService) {
    this.idSociety = this.teamsServices.getIdSociety();

  }

  goToNewTab(idVirement, route) {
    const queryParams = {
      virement: idVirement
    };
    const url = this.router.serializeUrl(
      this.router.createUrlTree([route], {
        queryParams: queryParams,
        // relativeTo: this.activeRoute,
        // skipLocationChange: true
      })
    );

    window.open(url, '_blank');
  }

  deleteClient(idClient: any) {
    return new Promise((slv) => {
      this.http.post(environment.apiDeleteClient, {
        idClient
      }).subscribe(
        (res: any) => {
          slv(res);
        },
        (error) => {
          slv(null);
        }
      );
    });
  }
  editClient(idProvider, provider) {
    return new Promise((slv) => {
      this.http.post(environment.apiEditClient, {
        idClient: idProvider,
        client: provider
      })
        .subscribe(
          (data: any) => {
            // console.log(data)
            // if (data && data.response) {
            slv(data);
            // } else slv(false);
          },
          (error) => {
            // console.log("Cmd error")
            // console.log(error)
            slv(null);
          }
        );
    });
  }
  subscribe(provider) {
    return new Promise((slv) => {
      this.http.post(environment.apiAddClient, provider)
        .subscribe(
          (data: any) => {
            // console.log(data)
            // if (data && data.response) {
            slv(data);
            // } else slv(false);
          },
          (error) => {
            // console.log("Cmd error")
            // console.log(error)
            slv(null);
          }
        );
    });
  }

  addFrais(isClient, fraisInterne, fraisExterne, fraisRetour) {
    return new Promise((slv) => {
      this.http.post(environment.apiAddFraisClient, {
        client: isClient,
        fraisInterne: fraisInterne,
        fraisExterne: fraisExterne,
        fraisRetour: fraisRetour
      }).subscribe(
        (data: any) => {
          if (data && data.response) {
            slv(true);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async getFraisClient(idClient) {
    return await new Promise((slv) => {
      this.http.post(environment.apiGetFraisClient + "/" + idClient, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async getClients() {
    return await new Promise((slv) => {
      this.http.post(environment.apiAllClients, { society: this.idSociety }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async addAccountClient(idClient, total, account_exp, account_logistic, cmds) {
    // console.log("addAccountClient : ")
    return await new Promise((slv) => {
      this.http.post(environment.apiAddAccountClient, {
        client: idClient,
        total: total,
        account_exp: account_exp,
        account_logistic: account_logistic,
        cmds: cmds
      }).subscribe(
        (data: any) => {
          // console.log("dataaaaaaaa : ")
          // console.log(data)
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(null);
        },
        (error) => {
          slv(null);
        }
      );
    });
  }

  async getClient(idClient) {
    return await new Promise((slv) => {
      this.http.post(environment.apiOneClient + "/" + idClient, { society: this.idSociety }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
  async getAccount(idVirement) {
    return await new Promise((slv) => {
      this.http.post(environment.apiOneAccountClient, { accountId: idVirement }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
  async getCmdsClient(idClient) {
    return await new Promise((slv) => {
      this.http.post(environment.apiAllCmdsByClient, { idClient: idClient }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async filterClient(keyword) {
    return await new Promise((slv) => {
      this.http.post(environment.apiFilterClient, { keyword, society: this.idSociety }).subscribe(
        (data: any) => {
          console.log("dataaaaaaaaaaaaaa : ")
          console.log(data)
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv([]);
        },
        (error) => {
          console.log("errooooooooooooooooooooor : ")
          console.log(error)
          slv([]);
        }
      );
    });
  }

  async searchClient(filter) {
    filter.society = this.idSociety
    return await new Promise((slv) => {
      this.http.post(environment.apiSearchClient, filter).subscribe(
        (data: any) => {
          console.log("dataaaaaaaaaaa : ")
          console.log(data)
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          console.log("dataaaaaaaaaaa : ")
          console.log(error)
          slv(false);
        }
      );
    });
  }

  async affecteCollecteur(idClient, idCollector/*, hourPickUp*/) {
    return await new Promise((slv) => {
      this.http.post(environment.apiAffectCollector, {
        client: idClient, collector: idCollector,/* hourPickUp*/
      }).subscribe(
        (data: any) => {
          // console.log("afffect collector : ")
          // console.log(data)
          if (data && data.response) {
            slv(data.response);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  // async allCmdsNotPaied(idClient) {
  //   return await new Promise((slv) => {
  //     this.http.post(environment.apiCmdsNotPaied, { client: idClient }).subscribe(
  //       (data: any) => {
  //         if (data && data.response && data.data) {
  //           slv(data.data);
  //         } else slv(false);
  //       },
  //       (error) => {
  //         slv(false);
  //       }
  //     );
  //   });
  // }

  // async allCmdsNotReturned(idClient) {
  //   return await new Promise((slv) => {
  //     this.http.post(environment.apiCmdsNotReturned, { client: idClient }).subscribe(
  //       (data: any) => {
  //         if (data && data.response && data.data) {
  //           slv(data.data);
  //         } else slv(false);
  //       },
  //       (error) => {
  //         slv(false);
  //       }
  //     );
  //   });
  // }

  async allCmdsNotPaiedNotReturned(idClient) {
    return await new Promise((slv) => {
      this.http.post(environment.apiCmdsNotPaiedAndNotReturned, { client: idClient }).subscribe(
        (data: any) => {
          // console.log("allCmdsNotPaiedNotReturned")
          // console.log(data)
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async demandesClient() {
    return await new Promise((slv) => {
      this.http.post(environment.apiDemandesClient, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async accountsClient(idClient) {
    return await new Promise((slv) => {
      this.http.post(environment.apiAccountsClient, { client: idClient }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  /** API GOOGLE */
  async searchPlace(input: string) {
    return await new Promise((slv) => {
      this.http.post(environment.apiSearchPlace, {
        "input": input,
      }).subscribe(
        (data: any) => {
          // console.log("data")
          // console.log(data)
          if (data && data.status != null && data.status == "OK") {
            slv(data);
          } else slv(null);
        },
        (error) => {
          slv(null);
        }
      );
    });
  }

  async detailsPlace(placeId: string) {
    return await new Promise((slv) => {
      this.http.post(environment.apiDetailsPlaceGoogle, {
        "placeId": placeId,
      }).subscribe(
        (data: any) => {
          // console.log("result['result']")
          // console.log(data)
          if (data && data.status != null && data.status == "OK") {
            slv(data['result']);
          } else slv(null);
        },
        (error) => {
          slv(null);
        }
      );
    });
  }
}
