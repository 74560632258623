<div class="modal-header">
    <h4 class="modal-title">Choisissez les boxes du fournisseur</h4>

    <button type="button" aria-label="Close" (click)="closeModal()">
        <i class="fa fa-close"></i>
    </button>
</div>
<div class="modal-body">

    <div class="scrollable-div row">
        <div class="col-12">
            <div class="main-card mb-3 card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="position-relative form-group">
                                <legend for="name" class="">Boxes</legend>
                                <input type="text" name="adress" id="adress" class="form-control" #autoInput matInput
                                    [matAutocomplete]="auto" (input)="onChange()">
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectionChange($event)">
                                    <mat-option *ngFor="let box of boxes" [value]="box">
                                        {{ box?.ref + ' - ' + box?.t_seak }}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12" *ngIf="selectedBoxes?.length > 0">
            <div class="main-card mb-3 card">
                <div class="card-header">Les boxes sélectionnées
                </div>
                <div class="table-responsive">
                    <!-- <div class="budget-price justify-content-center" *ngIf="selectedBoxes?.length === 0">
                        <h5 class="text-center text-danger">Aucun boxe</h5>
                    </div> -->
                    <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                        <thead>
                            <tr>
                                <th class="text-center">#</th>
                                <th>Référence</th>
                                <th>Description</th>
                                <th class="text-center">Longueur<br>(m)</th>
                                <th class="text-center">Largeur<br>(m)</th>
                                <th class="text-center">Hauteur<br>(m)</th>
                                <th class="text-center">UCS</th>
                                <th class="text-center">Volume<br>(m³)</th>
                                <th class="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let box of selectedBoxes, let index = index">
                                <td class="text-center text-muted">{{index+1}}</td>
                                <td><b>{{ box?.ref }}</b></td>
                                <td>{{ box?.t_seak }}</td>
                                <td class="text-center"><b>{{ box?.t_wdth | number:'0.3' }}</b></td>
                                <td class="text-center"><b>{{ box?.t_dpth | number:'0.3' }}</b></td>
                                <td class="text-center"><b>{{ box?.t_hght | number:'0.3' }}</b></td>
                                <td class="text-center"><b>{{ box?.ucs }}</b></td>
                                <td class="text-center"><b>{{ box?.volume | number:'0.3' }}</b></td>
                                <td class="text-center d-block ">
                                    <button type="button" (click)="deleteSelectedBoxProduct(index)"
                                        class="mr-3 btn-icon btn-icon-only btn btn-outline-danger">
                                        <i class="pe-7s-trash btn-icon-wrapper"> </i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="main-card mb-3 card">
                <div class="card-header">Les boxes du fournisseur
                </div>
                <div class="table-responsive">
                    <div class="budget-price justify-content-center" *ngIf="provider.boxes_products?.length === 0">
                        <h5 class="text-center text-danger">Aucun boxe</h5>
                    </div>
                    <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                        *ngIf="provider.boxes_products?.length > 0">
                        <thead>
                            <tr>
                                <th class="text-center">#</th>
                                <th>Référence</th>
                                <th>Description</th>
                                <th class="text-center">Longueur<br>(m)</th>
                                <th class="text-center">Largeur<br>(m)</th>
                                <th class="text-center">Hauteur<br>(m)</th>
                                <th class="text-center">UCS</th>
                                <th class="text-center">Volume<br>(m³)</th>
                                <th class="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let box of provider.boxes_products, let index = index">
                                <td class="text-center text-muted">{{index+1}}</td>
                                <td><b>{{ box?.ref }}</b></td>
                                <td>{{ box?.t_seak }}</td>
                                <td class="text-center"><b>{{ box?.t_wdth | number:'0.3' }}</b></td>
                                <td class="text-center"><b>{{ box?.t_dpth | number:'0.3' }}</b></td>
                                <td class="text-center"><b>{{ box?.t_hght | number:'0.3' }}</b></td>
                                <td class="text-center"><b>{{ box?.ucs }}</b></td>
                                <td class="text-center"><b>{{ box?.volume | number:'0.3' }}</b></td>
                                <td class="text-center d-block ">
                                    <button type="button" (click)="deleteBoxProduct(box?._id)"
                                        class="mr-3 btn-icon btn-icon-only btn btn-outline-danger">
                                        <i class="pe-7s-trash btn-icon-wrapper"> </i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="modal-footer">
    <!-- <div *ngIf="isSaved; else elseIsSaved">
        <div class="spinner-container blue-spinner">
            <mat-progress-spinner mode="indeterminate" [diameter]="40" [strokeWidth]="5"></mat-progress-spinner>
        </div>
    </div>
    <ng-template #elseIsSaved> -->
    <button type="button" class="btn btn-success" (click)="addBoxesToProvider()">Enregistrer</button>
    <!-- </ng-template> -->
    <button type="button" class="btn btn-secondary" (click)="closeModal()">Fermer</button>
</div>