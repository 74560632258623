<div class="modal-header">
    <h4 class="modal-title">Importer fichier excel</h4>
    <div class="page-title-actions">
        <input hidden type="file" (change)="readExcel($event)" multiple="false" #file>
        <button type="button" (click)="file.click()" data-toggle="tooltip" title="Example Tooltip"
            data-placement="bottom" class="btn-shadow mr-3 btn btn-info">
            <i class="fa fa-star"> Importer fichier excel</i>
        </button>
    </div>

    <button type="button" aria-label="Close" (click)="closeModal()">
        <i class="fa fa-close"></i>
    </button>
</div>
<div class="modal-body">

    <div *ngIf="isLoading; else elseAllProviders">
        <div class="row">
            <div class="col-md-12">
                <div class="main-card mb-3 card">
                    <div class="card-body text-center">
                        <div class="spinner-container blue-spinner">
                            <mat-progress-spinner mode="indeterminate" [diameter]="40"
                                [strokeWidth]="5"></mat-progress-spinner>
                        </div>
                    </div>
                    <!-- <div style="height: 70px;" [nbSpinner]="loading" nbSpinnerStatus="warning" nbSpinnerSize="giant">
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <ng-template #elseAllProviders>
        <div class="scrollable-div">
            <div class="row">
                <div class="col-12">
                    <ul class="list-group">
                        <li class="list-group-item" *ngFor="let item of providers, let index = index">
                            <div class="main-card mb-12 card">
                                <div class="card-header-tab card-header">

                                    <div class="card-header-title">
                                        <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
                                        Fournisseur {{index+1}} - {{ item?.provider['t_bpid'] }}
                                    </div>
                                    <div class="btn-actions-pane-right">
                                        <button type="button" (click)="deleteBox(index)"
                                            class="btn-shadow mr-3 btn btn-danger">
                                            <i class="fa fa-times"></i>
                                        </button>
                                    </div>

                                </div>
                                <div class="card-body">
                                    <div class="position-relative row form-group">
                                        <!-- <div class="col-sm-3">
                                            <legend for="qrCode" class="space-bottom">Code fournisseur
                                            </legend>
                                            <input name="qrCode" id="qrCode" type="text" disabled class="form-control"
                                                value="{{ item?.provider['t_bpid'] }}">
                                            <mat-error style="color: red;font-weight: bold;"
                                                *ngIf="!item?.provider?.hasOwnProperty('t_bpid') || item?.provider['t_bpid']==''">
                                                Ajoutez code fournisseur
                                            </mat-error>
                                        </div> -->

                                        <div class="col-sm-6">
                                            <legend for="client" class="space-bottom">Nom
                                            </legend>
                                            <input name="description_{{index}}" id="description_{{index}}" type="text"
                                                class="form-control" [(ngModel)]="item.provider.t_nama">
                                            <!-- <mat-error style="color: red;font-weight: bold;"
                                                *ngIf="!item?.provider?.hasOwnProperty('t_nama') || item?.provider['t_nama']==''">
                                                Ajoutez nom fournisseur
                                            </mat-error> -->
                                        </div>

                                        <div class="col-sm-6">
                                            <legend for="client" class="space-bottom">Description</legend>
                                            <input name="originCountryCode" id="originCountryCode" type="text"
                                                disabled class="form-control" value="{{item?.provider['t_namc']}}">
                                            <!-- <mat-error style="color: red;font-weight: bold;"
                                                *ngIf="!item?.provider?.hasOwnProperty('t_namc') || item?.provider['t_namc']==''">
                                                Ajoutez la description
                                            </mat-error> -->
                                        </div>

                                        <div class="col-sm-3">
                                            <legend for="client" class="space-bottom">Pays</legend>
                                            <input name="countryCode" id="countryCode" type="text" disabled
                                                class="form-control" value="{{item?.provider['t_ccit']}}">
                                            <!-- <mat-error style="color: red;font-weight: bold;"
                                                *ngIf="!item?.provider?.hasOwnProperty('t_ccit') || item?.provider['t_ccit']==''">
                                                Ajoutez pays
                                            </mat-error> -->
                                        </div>

                                        <div class="col-sm-3">
                                            <legend for="client" class="space-bottom">CSTE</legend>
                                            <input name="postalCode" id="postalCode" type="text" disabled
                                                class="form-control" value="{{item?.provider['t_cste']}}">
                                        </div>

                                        <div class="col-sm-3">
                                            <legend for="client" class="space-bottom">CCTY
                                            </legend>
                                            <input name="numTelDest" id="numTelDest" type="text" disabled
                                                class="form-control" value="{{item?.provider['t_ccty']}}">
                                        </div>

                                        <div class="col-sm-3">
                                            <legend for="client" class="space-bottom">Numéro téléphone</legend>
                                            <input name="nom_prod" id="nom_prod" type="text" disabled
                                                class="form-control" value="{{item?.provider['t_telp']}}">
                                            <!-- <mat-error style="color: red;font-weight: bold;"
                                                *ngIf="!item?.provider?.hasOwnProperty('t_telp') || item?.provider['t_telp']==''">
                                                Ajoutez numéro téléphone
                                            </mat-error> -->
                                        </div>

                                        <div class="col-sm-6">
                                            <legend for="client" class="space-bottom">Adresse complète</legend>
                                            <input name="nom_prod" id="nom_prod" type="text" disabled
                                                class="form-control" value="{{item?.provider['Adresse complète']}}">
                                            <!-- <mat-error style="color: red;font-weight: bold;"
                                                *ngIf="!item?.provider?.hasOwnProperty('Adresse complète') || item?.provider['Adresse complète']==''">
                                                Ajoutez l'adresse
                                            </mat-error> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="d-block text-center card-footer"
                                    *ngIf="item?.msg != null && item?.msg.msg!=''">
                                    <div style="color: red;"><b>{{item?.msg?.msg}}</b></div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </ng-template>

</div>
<div class="modal-footer">
    <div *ngIf="isSaved; else elseIsSaved">
        <div class="spinner-container blue-spinner">
            <mat-progress-spinner mode="indeterminate" [diameter]="40" [strokeWidth]="5"></mat-progress-spinner>
        </div>
    </div>
    <ng-template #elseIsSaved>
        <button type="button" class="btn btn-success" (click)="saveProviders()">Enregistrer</button>
    </ng-template>
    <button type="button" class="btn btn-secondary" (click)="closeModal()">Fermer</button>
</div>