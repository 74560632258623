import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { TeamsService } from 'src/app/services/teams-cofat/teams.service';
import { TransportorSocietyService } from 'src/app/services/transportor-society/transportor-society.service';
import { TransportorsService } from 'src/app/services/transportors/transportors.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-society',
  templateUrl: './add-society.component.html',
  styleUrls: ['./add-society.component.css']
})
export class AddSocietyComponent implements OnInit {

  places: any[] = [];
  isSaved: Boolean = true

  posts: any[] = [
    "Responsable Logistique",
    "Financière",
    "Exploitation",
    "Autre"
  ]

  trans_society: any = {
    // Informations Générales de la société de transports
    logo: null,
    nameSociety: '',
    siteWeb: '',
    emailSociety: '',
    numTelSociety: '',

    firstName: '',
    lastName: '',
    labelPost: "",
    post: "",
    email: '',
    numTel: '',
    numTel2: ''
  }

  env: any;
  form!: FormGroup;
  formContact!: FormGroup;

  constructor(private transServices: TransportorSocietyService,
    private teamsService: TeamsService,
    private fb: FormBuilder,
    private clientsService: ClientsService) {
    this.env = environment

    this.form = this.fb.group({
      locations: this.fb.array([])
    })
    this.formContact = this.fb.group({
      contacts: this.fb.array([])
    })
  }

  get locations(): FormArray {
    return this.form.get("locations") as FormArray;
  }
  get contacts(): FormArray {
    return this.formContact.get("contacts") as FormArray;
  }

  ngOnInit(): void {
    this.addLocation()
    // this.addNewContactGroup()
  }
  checkPost() {
    if (!this.isOther(this.trans_society.labelPost))
      return true
    else {
      if (this.trans_society.post != null && this.trans_society.post != "")
        return true
      else
        return false
    }
  }
  checkField() {
    if (this.trans_society.nameSociety != null && this.trans_society.nameSociety != "" &&
      this.trans_society.emailSociety != null && this.trans_society.emailSociety != "" && this.trans_society.emailSociety.includes("@") &&
      this.trans_society.numTelSociety != null && this.trans_society.numTelSociety != "" &&
      this.trans_society.logo != null && this.trans_society.logo != "" && 

      this.trans_society.firstName != null && this.trans_society.firstName != "" &&
      this.trans_society.lastName != null && this.trans_society.lastName != "" &&
      this.trans_society.labelPost != null && this.trans_society.labelPost != "" && this.checkPost() &&

      this.trans_society.email != null && this.trans_society.email != "" && this.trans_society.email.includes("@") &&
      this.trans_society.numTel != null && this.trans_society.numTel != "" && 
      this.locationsNotEmpty(this.form.value.locations))
      return true
    else
      return false
  }
  locationsNotEmpty(locations: any) {
    if (locations.length == 0)
      return false

    for (var i = 0; i < locations.length; i++) {
      if (locations[i].label_target == null || locations[i].label_target == ""

        // || freightRates[i].minimum == null
        // || freightRates[i].minimum.max == null || freightRates[i].minimum.max == ""
        // || freightRates[i].minimum.value == null || freightRates[i].minimum.value == ""

        // || freightRates[i].maximum == null || freightRates[i].maximum == ""
        )
        return false
    }
    return true
  }
  addSociety() {
    this.trans_society.adresses = this.form.value.locations
    this.trans_society.contacts = this.formContact.value.contacts
    // console.log(this.trans_society)

    var isValidate = this.checkField()
    if (!isValidate) {
      this.isSaved = isValidate
      alert("Merci de remplir les champs obligatoire !!!")
    } else {
      this.transServices.addSociety(this.trans_society).then((res: any) => {
        // console.log("ressssss add cat : ")
        // console.log(res)
        if (res) {
          if (res?.response) {
            location.href = '/society-transportors';
          } else
            alert(res.msg)
        } else {
          alert("Probléme reproduit !!!")
        }
      });
    }
  }

  handleFileInput(event: any) {
    const element = event.currentTarget as HTMLInputElement;
    const filelist: FileList | null = element.files;
    if (filelist) {
      const fileToUpload = filelist[0];
      var reader = new FileReader();
      reader.onload = (event: any) => {
        // this.imageUrl = event.target.result;
      }
      reader.readAsDataURL(fileToUpload);

      this.uploadPhoto(fileToUpload)
    }
  }
  async uploadPhoto(fileToUpload: any) {
    const formData: any = new FormData();

    if (fileToUpload != null) {
      formData.append("photo", fileToUpload, fileToUpload['name']);
    }

    var profile: any = {
      photo_name: "logo-",
      folder_name: "trans-societies/logo/"
    }

    for (var key in profile) {
      formData.append(key, profile[key]);
    }

    await this.teamsService.uploadPhoto(formData)
      .then((res: any) => {
        // console.log("resssssssss : ")
        // console.log(res)
        if (res) {
          if (res.response)
            this.trans_society.logo = res.data
          else
            alert(res?.msg)
        } else
          alert("Probléme occured")
      });
  }

  async onChange(event: any) {
    // console.log(event?.target?.value)
    this.places = await this._fetchAllPlace(event?.target?.value)
  }
  async onSelectionChange(event: any, i: number) {
    const add = this.form.get('locations') as FormArray;

    // console.log(event.option.value)
    const selectedValue = event.option.value;
    add.at(i).patchValue({
      label_target: selectedValue.description,
    })
    //this.getFilteredOptions($event);
    // this.input.nativeElement.value = selectedValue.description
    var detailsPlaceFrom = await this._fetchDetailsPlace(selectedValue.place_id)
    // console.log(detailsPlaceFrom)
    // this.trans_society.adress = {
    //   lat: detailsPlaceFrom.geometry.location.lat,
    //   lng: detailsPlaceFrom.geometry.location.lng,
    //   label_target: selectedValue.description
    // }

    add.at(i).patchValue({
      // type_prod: selectedValue._id,
      lat: detailsPlaceFrom.geometry.location.lat,
      lng: detailsPlaceFrom.geometry.location.lng,
      placement: "Entrepot",
      country: this.getCountry(detailsPlaceFrom)
    });

    this.places = []
  }
  getCountry(detailsPlaceFrom: any) {
    if (detailsPlaceFrom && detailsPlaceFrom?.address_components
      && detailsPlaceFrom?.address_components?.length > 0) {
      const countryComponent = detailsPlaceFrom?.address_components.find((component: any) => {
        return component.types.includes("country");
      });

      return countryComponent ? countryComponent.long_name : null;
    }
    return null
  }

  _fetchAllPlace(place: string) {
    let promise = new Promise<any>(async (resolve, reject) => {
      await this.clientsService.searchPlace(place).then((res: any) => {
        if (res) {
          // console.log("resssssssss : ")
          // console.log(res)
          // this.listPlacesFrom = 
          resolve(res.predictions)
        }
      }).catch((e: any) => {
        resolve([])
      });
    });
    return promise
  }
  _fetchDetailsPlace(placeId: string) {
    let promise = new Promise<any>(async (resolve, reject) => {
      await this.clientsService.detailsPlace(placeId).then((res: any) => {
        if (res) {
          // console.log("resssssssss : ")
          // console.log(res)
          // this.listPlacesFrom = 
          resolve(res)
        }
      }).catch((e: any) => {
        resolve(null)
      });
    });
    return promise
  }

  addLocation() {
    this.locations.push(this.fb.group({
      placement: [],
      label_target: [], //label_target
      country: [],
      lat: [],
      lng: [],
    }))
  }
  deleteLocation(locationIndex: number) {
    if (this.form.value.locations.length > 1) {
      this.locations.removeAt(locationIndex);
    }
  }

  addNewContactGroup() {
    this.contacts.push(this.fb.group({
      firstName: [],
      lastName: [],
      labelPost: [],
      post: [],
      email: [],
      numTel: [],
      numTel2: []
    }))
  }
  deleteContactGroup(index: number) {
    // if (this.formContact.value.contacts.length > 1) {
      this.contacts.removeAt(index);
    // }
  }
  isOther(item: any) {
    return item == "Autre"
  }
  selectPost(event: any, index: any) {
    var valuePost = event.target.value

    const add = this.formContact.get('contacts') as FormArray;
    add.at(index).patchValue({
      post: (valuePost != "Autre") ? valuePost : null
    });
  }
  selectContactPost(event: any) {
    var valuePost = event.target.value

    if (valuePost != "Autre") {
      this.trans_society.post = null
    }
  }
  checkValue(item: any) {
    if (item == null || (item != null && item == ""))
      return true
    else
      return false
  }

}
