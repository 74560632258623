import { Component, OnInit } from '@angular/core';
import { SocietiesService } from 'src/app/services/societies/societies.service';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SweetalertService } from 'src/app/services/sweetalert/sweetalert.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-societies-list-cof',
  templateUrl: './societies-list-cof.component.html',
  styleUrls: ['./societies-list-cof.component.css']
})
export class SocietiesListCofComponent implements OnInit {

  isLoading: any = true;
  societyList: any[] = [];

  // filter: any = {}
  constructor(private societyService: SocietiesService,
    private sweetalert: SweetalertService,
  ) { }

  ngOnInit(): void {
    this.listSocieties();
  }

  async listSocieties() {
    await this.societyService.allSocieties().then((res: any) => {
      console.log
      console.log(res)
      if (res) {
        this.isLoading = false
        this.societyList = res;
      }
    });
  }

  deleteTeam(idSociety: any) {
    this.sweetalert.confirmActionBtn("Vous ne pourrez pas revenir en arrière !").then((result: any) => {
      if (result.isConfirmed) {
        this.societyService.deleteSociety(idSociety).then((res: any) => {
          if (res && res.response == true) {
            this.sweetalert.alertConfirmed(res?.msg).then((res: any) => {
              this.listSocieties()
            })
          }
        });
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        this.sweetalert.alertCanceled().then((res: any) => {
        })
      }
    })
  }

}
