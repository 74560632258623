import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-facture-paiement-client',
  templateUrl: './facture-paiement-client.component.html',
  styleUrls: ['./facture-paiement-client.component.css']
})
export class FacturePaiementClientComponent implements OnInit {

  nowDate = new Date();
  account: any = {}
  frais: any = {}
  idVirement: string = ""
  client: any = {}

  cmdsNotPaied: any = []
  cmdsNotReturned: any = []

  listCmds: any = []
  totalCmdsLivred: any = 0
  fraisLivred: any = 0
  fraisLivredExterned: any = 0
  fraisReturned: any = 0

  // HT
  fraisInterneHT: any = 0
  fraisExterneHT: any = 0
  fraisReturnedHT: any = 0
  totalHT: any = 0

  // TVA
  fraisInterneTVA: any = 0
  fraisExterneTVA: any = 0
  fraisReturnedTVA: any = 0
  totalTVA: any = 0

  nbrCmdsExterne: any = 0
  nbrCmdsInterne: any = 0

  constructor(private cmdsService: CommandesService,
    private clientService: ClientsService,
    private route: ActivatedRoute,) { }

  async ngOnInit() {
    this.route.queryParams.subscribe(async (params) => {
      this.idVirement = params.virement;
      // await this.getFraisClient()
      await this.getAccountClient()
      // this.getClient()
      // this.listCmdsClient()
      await this.detailsCmdsNotPaiedAndNotReturned()
    });
  }
  // async getClient() {
  //   await this.clientService.getClient(this.idClient).then((res: any) => {
  //     console.log(res)
  //     if (res) {
  //       this.client = res;
  //       this.frais = res.fraisDelivery
  //     }
  //   });
  // }
  async getAccountClient() {
    await this.clientService.getAccount(this.idVirement).then((res: any) => {
      // console.log("res getAccountClient ++")
      // console.log(res)
      if (res) {
        this.account = res
        this.client = res.client;
        this.frais = res.client.fraisDelivery
      }
    });
  }

  async detailsCmdsNotPaiedAndNotReturned() {
    // this.clientService.allCmdsNotPaiedNotReturned(this.client._id).then(async (res: any) => {
    //   // console.log("res Not P and Retu")
    //   // console.log(res)
    //   if (res) {
    this.cmdsNotPaied = await this.account.commandes.filter(cmd => (cmd.etat_cmd == "payment_request"
      || cmd.etat_cmd == "paied"))

    this.cmdsNotReturned = await this.account.commandes.filter(cmd => (cmd.etat_cmd == "returned"))

    // this.cmdsNotPaied = res.cmdsNotPaied
    // this.cmdsNotReturned = res.cmdsNotReturned
    this.listCmds = this.cmdsNotPaied.concat(this.cmdsNotReturned)
    // this.listCmdsLivredReturned = this.cmdsNotPaied.concat(this.cmdsNotReturned)
    this.totalCmdsLivred = await this.totalCmdsByStatus(this.cmdsNotPaied)

    this.nbrCmdsInterne = await this.cmdsNotPaied.filter(cmd => (cmd.destination_cmd == "Tunis"
      || cmd.destination_cmd == "Ariana"
      || cmd.destination_cmd == "Ben Arous" || cmd.destination_cmd == "Manouba")).length;
    this.nbrCmdsExterne = await this.cmdsNotPaied.filter(cmd => !(cmd.destination_cmd == "Tunis"
      || cmd.destination_cmd == "Ariana"
      || cmd.destination_cmd == "Ben Arous" || cmd.destination_cmd == "Manouba")).length;


      this.fraisInterneTVA = (this.frais.frais_interne * 19) / 100
    this.fraisExterneTVA = (this.frais.frais_externe * 19) / 100
    this.fraisReturnedTVA = (this.frais.frais_retour * 19) / 100

    
    this.fraisInterneHT = this.frais.frais_interne - this.fraisInterneTVA
    this.fraisExterneHT = this.frais.frais_externe - this.fraisExterneTVA
    this.fraisReturnedHT = this.frais.frais_retour - this.fraisReturnedTVA
    this.totalHT = (this.fraisInterneHT * this.nbrCmdsInterne) + (this.fraisExterneHT * this.nbrCmdsExterne) +
      (this.fraisReturnedHT * this.cmdsNotReturned.length)


    
    this.totalTVA = (this.fraisInterneTVA * this.nbrCmdsInterne) + (this.fraisExterneTVA * this.nbrCmdsExterne) +
    (this.fraisReturnedTVA * this.cmdsNotReturned.length)


    this.fraisLivred = this.nbrCmdsInterne * this.frais.frais_interne
    this.fraisLivredExterned = this.nbrCmdsExterne * this.frais.frais_externe
    this.fraisReturned = this.cmdsNotReturned.length * this.frais.frais_retour
    //   }
    // });
  }

  async totalCmdsByStatus(array) {
    let total = 0
    array.forEach(element => {
      total += element.total_ca_cmd + element.frais_livraison
    });

    return total
  }
}
