import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TransportorSocietyService } from 'src/app/services/transportor-society/transportor-society.service';
import { TransportorsService } from 'src/app/services/transportors/transportors.service';

@Component({
  selector: 'app-details-society',
  templateUrl: './details-society.component.html',
  styleUrls: ['./details-society.component.css']
})
export class DetailsSocietyComponent implements OnInit {

  closeResult: string;

  idSociety: any
  contractSociety: any

  loading = true;
  isSaved: Boolean = true

  form: FormGroup;

  countries: any[] = [
    "Allemagne", "Autriche", "Belgique",
    "Bulgarie", "Chypre", "Croatie",
    "Danemark", "Espagne", "Estonie",
    "Finlande", "France", "Grèce",
    "Hongrie", "Irlande", "Italie",
    "Lettonie", "Lituanie", "Luxembourg",
    "Malte", "Pays-Bas", "Pologne",
    "Portugal", "République tchèque",
    "Roumanie", "Slovaquie", "Slovénie",
    "Suède"
  ]

  constructor(private route: ActivatedRoute,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private service: TransportorSocietyService) {
    this.form = fb.group({
      freightRates: fb.array([])
    })
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      // console.log(params)
      this.idSociety = params.society;
      // this._fetchTransportor();
      this.initContractSociety();
    });
  }

  initContractSociety() {
    this.freightRates.clear()

    this.getContractSociety()
  }

  getContractSociety() {
    this.service.getContractSociety(this.idSociety).then(async (res: any) => {
      // console.log("getContractSociety : ")
      // console.log(res)
      if (res && res.response && res.data) {
        this.mappingFreightRateGroup(res.data)
        this.loading = false
      } else {
        this.addNewFreightRateGroup()
      }
    })
  }

  get freightRates(): FormArray {
    return this.form.get("freightRates") as FormArray;
  }

  mappingFreightRateGroup(data: any) {
    this.freightRates.clear()

    data.freightRates.forEach((item: any) => {
      this.freightRates.push(this.fb.group({
        country: item.country,
        ranges: this.fb.array(this.mappingRangesGroup(item.ranges)),
        maximum: item.maximum
      }))
    });
  }
  mappingRangesGroup(ranges: any) {
    var theRanges = []

    ranges.forEach((range: any) => {
      theRanges.push(this.fb.group({
        min: range.min,
        max: range.max,
        value: range.value,
      }))
    });

    return theRanges
  }

  addNewFreightRateGroup() {
    this.freightRates.push(this.fb.group({
      country: [],
      ranges: this.fb.array([
        this.fb.group({
          min: "",
          max: "",
          value: ""
        })
      ]),
      maximum: []
    }))
  }
  deleteFreightRateGroup(index: number) {
    if (this.form.value.freightRates.length > 1) {
      this.freightRates.removeAt(index);
    }
  }
  getRanges(itemIndex: number) {
    return (this.freightRates.at(itemIndex) as FormGroup).get('ranges') as FormArray;
  }

  addRanges(itemIndex: number) {
    const range = this.fb.group({
      min: "",
      max: "",
      value: ""
    });

    this.getRanges(itemIndex).push(range);
  }

  deleteRangeGroup(i: number, j: number) {
    var ranges = this.getRanges(i)
    // console.log(ranges)

    if (ranges.value.length > 1) {
      ranges.removeAt(j)
    }
  }

  freightRateNotEmpty(freightRates: any) {
    if (freightRates.length == 0)
      return false

    for (var i = 0; i < freightRates.length; i++) {
      if (freightRates[i].country == null || freightRates[i].country == ""
        || !this.checkRanges(freightRates[i].ranges)
        || freightRates[i].maximum == null || freightRates[i].maximum == "")
        return false
    }
    return true
  }
  checkRanges(ranges: any[]) {
    for (var i = 0; i < ranges.length; i++) {
      if (!this.checkRange(ranges[i]))
        return false
    }
    return true
  }
  checkRange(range: any) {
    if (range?.min != null && range?.min?.toString() != ""
      && range?.max != null && range?.max?.toString() != ""
      && range?.value != null && range?.value?.toString() != "")
      return true
    else
      return false
  }
  checkValue(item: any) {
    if (item == null || (item != null && item == ""))
      return true
    else
      return false
  }

  addContractSociety() {
    // console.log(this.form.value.freightRates)
    var isValidate = this.freightRateNotEmpty(this.form.value.freightRates)
    if (!isValidate) {
      this.isSaved = isValidate
      alert("Les valeurs du contrat de la société est invalide");
    } else {
      var data = {
        idSociety: this.idSociety,
        freightRates: this.form.value.freightRates
      }
      this.service.saveContractSociety(data).then(async (res: any) => {
        // console.log("resssssssss : ")
        // console.log(res)
        if (res && res.response) {
          this.mappingFreightRateGroup(res.data)
        } else {
          alert(res.msg);
        }
      })
    }
  }

  open(content) {
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  openLarge(content) {
    this.modalService.open(content, {
      size: 'lg'
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
