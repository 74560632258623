import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TeamsService } from '../teams-cofat/teams.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  idSociety: string;
  constructor(private http: HttpClient,
    private router: Router,
    private userService: TeamsService,) {
    this.idSociety = this.userService.getIdSociety();
  }

  savePallet(data) {
    return new Promise((slv) => {
      this.http.post(environment.apiSavePallet, data).subscribe(
        (data: any) => {
          slv(data);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(null);
        }
      );
    });
  }
  deletePallet(idPallet) {
    return new Promise((slv) => {
      this.http.post(environment.apiDeletePallet, {
        idPallet
      }).subscribe(
        (data: any) => {
          slv(data);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(null);
        }
      );
    });
  }

  allPallets() {
    return new Promise((slv) => {
      this.http.post(environment.apiAllPallets, {}).subscribe(
        (data: any) => {
          if (data) {
            slv(data);
          } else slv(null);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(null);
        }
      );
    });
  }

  searchBoxes(keyword) {
    return new Promise((slv) => {
      this.http.post(environment.apiSearchBoxesProducts, {
        keyword
      }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(null);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(null);
        }
      );
    });
  }

  allBoxesProducts(data: any) {
    data.society= this.idSociety
    return new Promise((slv) => {
      this.http.post(environment.apiAllBoxesProducts, data).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(null);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(null);
        }
      );
    });
  }

  addBoxProduct(data) {
    return new Promise((slv) => {
      this.http.post(environment.apiAddBoxProduct, data).subscribe(
        (data: any) => {
          slv(data);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(null);
        }
      );
    });
  }

  deleteBoxeProductsFromProvider(data) {
    return new Promise((slv) => {
      this.http.post(environment.apiDeleteBoxeProductFromProvider, data).subscribe(
        (data: any) => {
          if (data) {
            slv(data);
          } else slv(null);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  addBoxesProductsToProvider(data) {
    return new Promise((slv) => {
      this.http.post(environment.apiAllBoxesProductsToProvider, data).subscribe(
        (data: any) => {
          if (data && data.response) {
            slv(true);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  saveBoxProduct(data) {
    return new Promise((slv) => {
      this.http.post(environment.apiSaveBoxProduct, data).subscribe(
        (data: any) => {
          slv(data);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(null);
        }
      );
    });
  }
  
  deleteBoxProduct(idBox) {
    return new Promise((slv) => {
      this.http.post(environment.apiDeleteBoxProduct, {
        idBox
      }).subscribe(
        (data: any) => {
          slv(data);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(null);
        }
      );
    });
  }

}
