<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                    <div class="widget-content-left mr-3">
                        <div class="widget-content-left">
                            <ngx-avatar class="rounded-circle"
                                name="{{userTracking?.nom_prenom_tracking}}">
                            </ngx-avatar>
                        </div>
                    </div>
                    <div class="widget-content-left flex2">
                        <div class="widget-heading">{{userTracking?.profil_tracking}}
                            {{userTracking?.nom_prenom_tracking}}</div>
                        <div class="widget-subheading opacity-7">{{userTracking?.numTel_tracking}}</div>
                    </div>
                </div>
            </div>

            <!-- <div class="page-title-icon">
                <i class="pe-7s-users icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Commandes
                <div class="page-title-subheading">La liste des commandes du client xxxx.
                </div>
            </div> -->
        </div>
        <div class="page-title-actions">
            <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                class="btn-shadow mr-3 btn btn-dark">
                <i class="fa fa-star"></i>
            </button>
            <div class="d-inline-block dropdown">
                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    class="btn-shadow dropdown-toggle btn btn-info">
                    <span class="btn-icon-wrapper pr-2 opacity-7">
                        <i class="fa fa-business-time fa-w-20"></i>
                    </span>
                    Buttons
                </button>
                <div tabindex="-1" role="menu" aria-hidden="true"
                    class="dropdown-menu dropdown-menu-right">
                    <ul class="nav flex-column">
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-inbox"></i>
                                <span>
                                    Inbox
                                </span>
                                <div class="ml-auto badge badge-pill badge-secondary">86</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-book"></i>
                                <span>
                                    Book
                                </span>
                                <div class="ml-auto badge badge-pill badge-danger">5</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-picture"></i>
                                <span>
                                    Picture
                                </span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a disabled href="javascript:void(0);" class="nav-link disabled">
                                <i class="nav-link-icon lnr-file-empty"></i>
                                <span>
                                    File Disabled
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
        <table class="mb-0 table table-dark">
            <thead>
                <tr>
                    <th>#</th>
                    <th>{{currentDate | date:'d MMM y'}}</th>
                    <th>Quantité & cout</th>
                    <th>Montant</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th scope="row">1</th>
                    <td>Frais de livraison</td>
                    <td class="text-center">{{userTracking?.frais_livraison}}</td>
                    <td class="text-right">{{fraisLivred}}</td>
                </tr>
                <!-- <tr>
                    <th scope="row">2</th>
                    <td>Frais de retour</td>
                    <td class="text-center">5</td>
                    <td class="text-right">{{fraisReturned}}</td>
                </tr> -->
                <tr>
                    <th scope="row"></th>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <th scope="row">2</th>
                    <td>Total de remboursement</td>
                    <td></td>
                    <td class="text-right">{{totalCmdsLivred|
                        number:'0.3'}}</td>
                </tr>
                <tr>
                    <th scope="row">3</th>
                    <td>Total montant à payer</td>
                    <td></td>
                    <td class="text-right">{{totalCmdsLivred - fraisLivred|
                        number:'0.3'}}</td>
                </tr>
                <tr>
                    <th scope="row">4</th>
                    <td>Total frais de livraison</td>
                    <td></td>
                    <td class="text-right">{{fraisLivred|
                        number:'0.3'}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-md-3"></div>
</div>
<br/><br/>
<div class="row">
    <div class="col-md-4">
        <div class="card widget-content bg-primary"
            (click)="listCmdsInProgressByTransporter('EN_COURS')">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-car text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">En cours</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{cmdsInProgress.length}}</span></div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="card widget-content bg-success" (click)="listCmdsLivredByTransporter('LIVREE')">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-like2 text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Livré</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{cmdsLivred.length}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="card widget-content bg-danger" (click)="listCmdsReturnedByTransporter('RETOURNEE')">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-more text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Retour</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{cmdsReturned.length}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br/><br/>
<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Commandes
                <div class="btn-actions-pane-right">
                    <button class="btn btn-warning float-right text-white"
                    (click)="goToNewTab('/bon-paiement-delivery')"><i
                                class="metismenu-icon pe-7s-print text-white"></i> Imprimer</button>
                </div>
            </div>
            <div class="table-responsive">
                <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th>Fournisseur</th>
                            <th class="text-left">Référence</th>
                            <th class="text-center">Date Création</th>
                            <th class="text-center">Nom Client & Téléphone</th>
                            <th class="text-center">Ville</th>
                            <th class="text-center">Commentaire</th>
                            <th class="text-center">Total Commande</th>
                            <th class="text-center" *ngIf="tagCmds=='LIVREE'">Date de livraison</th>
                            <th class="text-center" *ngIf="tagCmds=='RETOURNEE'">Date de retour</th>
                            <th class="text-center">Status</th>
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let cmd of listCmds, let index = index">
                            <td class="text-center text-muted">#{{index+1}}</td>
                            <td>
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left mr-3">
                                            <div class="widget-content-left">
                                                <ngx-avatar class="rounded-circle" name="{{cmd.client_cmd.prenom_client}}
                                                {{cmd.client_cmd.nom_client}}">
                                                </ngx-avatar>
                                            </div>
                                        </div>
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading">
                                                {{cmd.client_cmd.providerName}}
                                            </div>
                                            <div class="widget-subheading opacity-7">
                                                {{cmd.client_cmd.prenom_client}}
                                                {{cmd.client_cmd.nom_client}}</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text">{{cmd.ref_facture_cmd}}</td>
                            <td class="text-center">{{cmd.date_creation_cmd | date:'d MMM y, HH:mm'}}</td>
                            <td class="text-center">
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading">
                                                {{cmd.user_destination.nom_destination}}</div>
                                            <div class="widget-subheading opacity-7">
                                                {{cmd.user_destination.numtel_destination}}</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center">{{cmd.user_destination.adresse_destination}}
                                <strong>{{cmd.destination_cmd}}</strong></td>
                                <td class="text-center">
                                    <span
                                        class="badge badge-pill badge-danger" (click)="listComments(cmd)">{{cmd.comments?.length}}</span>
                                </td>
                            <td class="text-center">{{cmd.total_ca_cmd+cmd.frais_livraison |
                                number:'0.3'}} DT</td>
                            <td *ngIf="tagCmds=='LIVREE'" class="text-center">{{cmd.date_relivered_cmd | date:'d MMM y HH:mm'}}</td>
                            <td *ngIf="tagCmds=='RETOURNEE'" class="text-center">{{cmd.date_returned_cmd | date:'d MMM y HH:mm'}}</td>
                            <td class="text-center">
                                <div class="badge badge-{{statusCmds[cmd.etat_cmd].color}}">
                                    {{statusCmds[cmd.etat_cmd].label}}</div>
                            </td>
                            <td class="text-center">
                                <button [routerLink]="['/detail-cmd']" [queryParams]="{ cmd: cmd._id }"
                                    type="button" id="PopoverCustomT-1"
                                    class="btn btn-info btn-sm">Details</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-block text-center card-footer">
                <button *ngIf="listCmds.length>0 && tagCmds=='RETOURNEE'" type="button" (click)="validateReturnedCmd()" class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                        class="pe-7s-trash btn-icon-wrapper"> Valider le retour</i></button>
                <button *ngIf="listCmds.length>0 && tagCmds=='LIVREE'" (click)="validateLivredCmds()" class="btn-wide btn btn-success"> Valider le paiement </button>
            </div>
        </div>
    </div>
</div>