<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-display2 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Demandes Clients
                <div class="page-title-subheading">Liste des demandes clients.
                </div>
            </div>
        </div>
        <!-- <div class="page-title-actions">
            <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                class="btn-shadow mr-3 btn btn-dark">
                <i class="fa fa-star"></i>
            </button>
            <div class="d-inline-block dropdown">
                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    class="btn-shadow dropdown-toggle btn btn-info">
                    <span class="btn-icon-wrapper pr-2 opacity-7">
                        <i class="fa fa-business-time fa-w-20"></i>
                    </span>
                    Buttons
                </button>
                <div tabindex="-1" role="menu" aria-hidden="true"
                    class="dropdown-menu dropdown-menu-right">
                    <ul class="nav flex-column">
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-inbox"></i>
                                <span>
                                    Inbox
                                </span>
                                <div class="ml-auto badge badge-pill badge-secondary">86</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-book"></i>
                                <span>
                                    Book
                                </span>
                                <div class="ml-auto badge badge-pill badge-danger">5</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-picture"></i>
                                <span>
                                    Picture
                                </span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a disabled href="javascript:void(0);" class="nav-link disabled">
                                <i class="nav-link-icon lnr-file-empty"></i>
                                <span>
                                    File Disabled
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div> -->
    </div>
</div>

<div class="row space">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Demandes
                <div class="btn-actions-pane-right">
                </div>
            </div>
            <div class="table-responsive">
                <div class="budget-price justify-content-center" *ngIf="listDemandes?.length === 0">
                    <h5 class="text-center">Aucune demande du client {{client?.providerName}} </h5>
                </div>
                <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                    *ngIf="listDemandes?.length > 0">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th class="text-left">Expéditeur</th>
                            <th class="text-center">Total</th>
                            <th class="text-center">Compte expéditeur</th>
                            <th class="text-center">Compte logistic</th>
                            <th class="text-center">Date de virement</th>
                            <th class="text-center">Etat</th>
                            <th class="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let virment of listDemandes, let index = index"
                            [class]="(index==selectedVirement)?'row-selected':''">
                            <td class="text-center text-muted">#{{index+1}}</td>
                            <td>
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left mr-6">
                                            <div class="widget-content-left">
                                                <!-- <img width="40" class="rounded-circle"
                                                    src="assets/images/avatars/2.jpg" alt=""> -->
                                                <ngx-avatar class="rounded-circle" name="{{virment.client.prenom_client}}
                                                {{virment.client.nom_client}}">
                                                </ngx-avatar>
                                            </div>
                                        </div>
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading">{{virment.client.prenom_client}}
                                                {{virment.client.nom_client}}</div>
                                            <div class="widget-subheading opacity-7">
                                                {{virment.client.providerName}}</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <!-- <td class="text">{{virment.client.company_name}}</td> -->
                            <td class="text-center">{{virment.total | number:'0.3'}} DT</td>
                            <td class="text-center">{{virment.account_exp | number:'0.3'}} DT</td>
                            <td class="text-center">{{virment.account_logistic | number:'0.3'}}</td>
                            <td class="text-center">{{virment.date_action | date:'d MMM y'}}<br>
                                <b>{{virment.date_action | date:'HH:mm'}}</b>
                            </td>
                            <td class="text-center">
                                <div class="badge badge-warning">
                                    EN COURS</div>
                            </td>
                            <td class="text-center">
                                <button class="btn btn-warning text-white"
                                    (click)="goToNewTab(virment._id, '/facture-paiement-client', index)"><i
                                        class="metismenu-icon pe-7s-print text-white"></i>
                                    Imprimer</button>
                                <button class="btn-wide btn btn-primary"
                                    (click)="showVirement(virment, index)">Détail</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div class="d-block text-center card-footer" *ngIf="listVirements?.length > 0">
                <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                        class="pe-7s-trash btn-icon-wrapper"> </i></button>
                <button class="btn-wide btn btn-success">Save</button>
            </div> -->
        </div>
    </div>
</div>