import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UsersTrackingService } from 'src/app/services/users-tracking/users-tracking.service';

@Component({
  selector: 'app-add-tracking-user',
  templateUrl: './add-tracking-user.component.html',
  styleUrls: ['./add-tracking-user.component.css']
})
export class AddTrackingUserComponent implements OnInit {

  profiles = ["POINT_COLLECT", "TRANSPORTEUR", "PREPARATEUR", "LOUAGISTE", "COLLECTOR"];
  selectedProfiles: any = [];
  isResponsable: boolean = true;
  leadersList: Observable<Array<any>>;
  superieur: string = "";

  arrests: any = []
  lastName: string = ""
  part1: string = ""
  part2: string = ""
  startingLouage: string = ""
  arrivedLouage: string = ""

  constructor(private trackingServices: UsersTrackingService) { }

  ngOnInit(): void {
    this.listLeaders()
    this.getArrests()
  }

  // selectProfil(event: any) {
  //   this.selectedProfile = event.target.value;
  // }

  setResponsable(event: any) {
    this.isResponsable = (event.target.value.toLowerCase() == 'true')
  }

  selectSuperieur(event: any) {
    this.superieur = event.target.value;
  }

  isProfileIncludes(profile) {
    if(this.selectedProfiles.includes(profile)) {
      return true;
    }
    return false
  }

  async addUser(name, username, password, numTel, numTel2) {
    if (this.selectedProfiles.includes("LOUAGISTE")) {
      // console.log("last name : " + this.lastName)
      await this.trackingServices.subscribeLouage(name.value, 
        this.lastName, username.value,
        password.value, true, numTel.value, numTel2.value,
        this.startingLouage, this.arrivedLouage, this.part1, this.part2)
        .then((res: any) => {
          // console.log("resssssssss : ")
          // console.log(res)
          if (res) {
              location.href = '/louagistes';
            //this.sacsList = res;
          }
        });
    } else {
      await this.trackingServices.subscribe(name.value, username.value,
        password.value, this.selectedProfiles, numTel.value, numTel2.value, this.isResponsable, this.superieur)
        .then((res: any) => {
          // console.log("resssssssss : ")
          // console.log(res)
          if (res) {
            // if (this.selectedProfiles.includes("TRANSPORTEUR")) {
            //   location.href = '/tracking-user';
            // } else if (this.selectedProfiles.includes("PREPARATEUR")) {
            //   location.href = '/preparers';
            // }
            //this.sacsList = res;
          }
        });
    }

    // console.log("this.selectedProfiles : ")
    // console.log(this.selectedProfiles)
  }

  async getArrests() {
    await this.trackingServices.getArrest().then((res: any) => {
      if (res) {
        // console.log("leader res");
        // console.log(res);
        this.arrests = res;
      }

    });
  }

  async listLeaders() {
    await this.trackingServices.getResponsables().then((res: any) => {
      if (res) {
        // console.log("leader res");
        // console.log(res);
        this.leadersList = res;
      }

    });
  }

}
